import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
// eslint-disable-next-line import/no-cycle
import SelectedFilters from './SelectedFilters/SelectedFilters/SelectedFilters';
import AvailableFields from './AvailableFields/AvailableFields';
import Features from '../../../features';
import Constants from '../../../constants/constants';
import RelatedRecords from '../DataExtensions/RelatedRecords/RelatedRecords';

// eslint-disable-next-line import/prefer-default-export
const Filters = ({
  dataExtensions,
  selectedDataExtensions,
  selectedFilters,
  filtersRef,
  handleFiltersSave,
  handleSetSelectionState = () => { },
  isSubQuery,
  getDataExtensionOrDataViewFields,
  compareSelectedDataExtensions,
  handleFeatureMissing,
  manageSubscriberRelationship = () => { },
  DEBorderMouseOver,
  filterBorderMouseOver,
  showInResultsOption,
  hideCollectionAlias,
  handlePickListOptions,
  pickLists,
  dataSets,
  filterSets,
  showFilterSets,
  isFromSelection,
  loadingForDataExtensions,
  loadingSubQueryFields,
  handleRemoveFilterLine,
  subQueryModalFilterLineId,
  unionSelections,
  unionSelectionsIndex,
  handleSetAppState = () => { },
  filterType,
  handleUpdateSubQuery,
  inSelectionCriteria,
  predefinedRelations,
  isSubQueryRelation,
  subQueryDataExtensions,
  returnPredefinedRelationById,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
  isRegularFilter = false,
  isBasicMode,
  showEssentialsUpgradeModal,
  revertBehavioralFilterSet,
  collapseFilters,
  isFilterBoxExpanded,
  isFilterBoxExpandedForFirstTime,
  featuresInfo,
  areFilterSetsAvailable,
  isSelectedFiltersSection,
  showFiltersImmediately,
  showDataMultipleTabs,
  isCriteriaFilter,
  isFilterSet,
}) => {
  const featureInResults2IsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__IN_RESULTS_2);

  /**
   * Returns an array with selected DEs that contains predefined relations
   * @returns {array} array with selectedDataExtensions
   */
  const getRelatedDataExtensions = () => {
    const dataExtensionsForRelatedCard = [];

    // return related and selected Data Extensions
    selectedDataExtensions.forEach((dataExtension) => {
      // if predefined relations exists
      if (predefinedRelations?.length) {
        // find predefined relation for this data extension
        const predefinedRelationsForCustomerKey = predefinedRelations
          .find(predefined => predefined.fromDEObjectId === dataExtension.ObjectID);

        if (predefinedRelationsForCustomerKey) {
          // if relation exists, add this data extension to an array
          dataExtensionsForRelatedCard.push(dataExtension);
        } else {
          // check if DE has a 1-1 relation and appears as toDE
          const predefinedRelationsForToDE = predefinedRelations.find(
            predefined => predefined.toDEObjectId === dataExtension.ObjectID &&
            predefined.relation === Constants.RELATION__TYPE__ONE_TO_ONE,
          );

          if (predefinedRelationsForToDE) {
            // if relation exists, add this data extension to an array
            dataExtensionsForRelatedCard.push(dataExtension);
          }
        }
      }
    });

    // return empty array
    return dataExtensionsForRelatedCard;
  };

  const relatedDataExtensions = getRelatedDataExtensions();

  return (
    <div
      className={classNames(
        'filters_wrapper-v2',
        { 'priodedup-filters': filterType === Constants.FILTER_TYPE__PRIO_DEDUP },
        {
          'show-filters-in-selected-filters-section': isSelectedFiltersSection ?
            isFilterBoxExpanded && !showFiltersImmediately :
            false,
        },
        { 'show-regular-filters': !isSelectedFiltersSection },
        { 'hidden-filters': isSelectedFiltersSection && !isFilterBoxExpanded && !isFilterBoxExpandedForFirstTime },
        { 'hide-filters': isSelectedFiltersSection && !isFilterBoxExpanded },
        {
          'show-filters-in-selected-filters-section-immediately': isSelectedFiltersSection ?
            showFiltersImmediately :
            false,
        },
      )}
    >
      <div className="new-sticky_left_column">
        <AvailableFields
          hideCollectionAlias={!!hideCollectionAlias}
          selectedDataExtensions={selectedDataExtensions}
          handleSetSelectionState={handleSetSelectionState}
          dataSets={dataSets}
          filterSets={filterSets}
          showFilterSets={showFilterSets}
          loadingForDataExtensions={loadingForDataExtensions}
          manageSubscriberRelationship={manageSubscriberRelationship}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          isBasicMode={isBasicMode}
          showEssentialsUpgradeModal={showEssentialsUpgradeModal}
        />
        {inSelectionCriteria && isFilterBoxExpanded && featureInResults2IsEnabled && (
          <RelatedRecords
            predefinedRelations={predefinedRelations}
            selectedDataExtensions={selectedDataExtensions}
            handleSetSelectionState={handleSetSelectionState}
            DEBorderMouseOver={DEBorderMouseOver}
            returnPredefinedRelationById={returnPredefinedRelationById}
            relatedDataExtensions={relatedDataExtensions}
          />
        )}
      </div>
      <SelectedFilters
        selectDataExtensions={selectedDataExtensions}
        areFilterSetsAvailable={areFilterSetsAvailable}
        showInResultsOption={showInResultsOption}
        hideCollectionAlias={!!hideCollectionAlias}
        selectedFilters={selectedFilters}
        showFilterSets={showFilterSets}
        dataExtensions={dataExtensions}
        selectedDataExtensions={selectedDataExtensions}
        compareSelectedDataExtensions={compareSelectedDataExtensions}
        ref={filtersRef}
        filterSets={filterSets}
        handleFiltersSave={handleFiltersSave}
        isSelectedFiltersSection={isSelectedFiltersSection}
        handleSetSelectionState={handleSetSelectionState}
        isSubQuery={isSubQuery}
        getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
        handleFeatureMissing={handleFeatureMissing}
        manageSubscriberRelationship={manageSubscriberRelationship}
        DEBorderMouseOver={DEBorderMouseOver}
        filterBorderMouseOver={filterBorderMouseOver}
        handlePickListOptions={handlePickListOptions}
        pickLists={pickLists}
        loadingSubQueryFields={loadingSubQueryFields}
        handleRemoveFilterLine={handleRemoveFilterLine}
        subQueryModalFilterLineId={subQueryModalFilterLineId}
        unionSelections={unionSelections}
        unionSelectionsIndex={unionSelectionsIndex}
        handleSetAppState={handleSetAppState}
        filterType={filterType}
        handleUpdateSubQuery={handleUpdateSubQuery}
        isSubQueryRelation={isSubQueryRelation}
        subQueryDataExtensions={subQueryDataExtensions}
        returnPredefinedRelationById={returnPredefinedRelationById}
        applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
        timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
        handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
        isRegularFilter={isRegularFilter}
        isFromSelection={isFromSelection}
        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
        revertBehavioralFilterSet={revertBehavioralFilterSet}
        collapseFilters={collapseFilters}
        isFilterBoxExpanded={isFilterBoxExpanded}
        relatedDataExtensions={relatedDataExtensions}
        predefinedRelations={predefinedRelations}
        showDataMultipleTabs={showDataMultipleTabs}
        isCriteriaFilter={isCriteriaFilter}
        isFilterSet={isFilterSet}
      />
    </div>
  );
};

Filters.propTypes = {
  /**
   * prop passed to the FilterLine component and its used to
   * check where its needed to allow the user to select the options
   * 'In Results' and 'Not In Results'
   */
  showInResultsOption: PropTypes.bool.isRequired,
  /**
   * It determines if the collection alias should be hidden after the name in the filter line
   */
  hideCollectionAlias: PropTypes.bool.isRequired,
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to save the selected filters for the selection
   * it is passed from Selection.js/SubqueryModal.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It keeps the reference of the selected filters
   */
  filtersRef: PropTypes.instanceOf(Object),
  /**
   * It keeps the selected filters for a Selection
   * selectedFilters are stored as filters in database
   * It will be passed from Selection.js
   */
  selectedFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * It determines if the selected field for a filter is a subquery or not
   */
  isSubQuery: PropTypes.bool,
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Stores the loading state of filterlines with subQueries
   */
  loadingSubQueryFields: PropTypes.instanceOf(Object),
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * It keeps the filterline IDs of a subquery`s filters
   */
  subQueryModalFilterLineId: PropTypes.string,
  /**
   * It keeps the states of a Selection`s if appendDataExtension feature is enabled
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * This prop keeps the unionSelectionsIndex of Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  unionSelectionsIndex: PropTypes.number,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func,
  /**
   * Indicates the type/location of a filter
   */
  filterType: PropTypes.string,
  /**
   * Updates the subquery property of a filterline
   */
  handleUpdateSubQuery: PropTypes.func,
  /**
   * Defines whether the component is displayed in selectionCriteria
   */
  inSelectionCriteria: PropTypes.bool,
  /**
   * An array containing relations that were defined for Data Extensions
   */
  predefinedRelations: PropTypes.instanceOf(Array),
  /**
   * Indicates whether the parent of the subQuery is a relation
   */
  isSubQueryRelation: PropTypes.bool,
  /**
   * An array containing data extensions used in InResults and relations filters
   */
  subQueryDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * Indicates whether Filters component is the main filters component
   */
  isRegularFilter: PropTypes.bool,
  /**
   * An array containing data sets
   */
  dataSets: PropTypes.instanceOf(Array),
  /**
   * An array containing filter sets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * Define whether filter sets should be shown or not
   */
  showFilterSets: PropTypes.bool,
  /**
   * Define whether this component is used from selection or not
   */
  isFromSelection: PropTypes.bool,
  /**
   * Define whether data extensions are loading or not
   */
  loadingForDataExtensions: PropTypes.bool,
  /**
   * Indicates whether a selection is in basic mode
   */
  isBasicMode: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
  /**
   * Revert behavioral filter set
   */
  revertBehavioralFilterSet: PropTypes.func,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(Filters);
