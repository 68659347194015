import _ from 'lodash';

import Constants from '../../constants/constants';
import Util from '../../util';
import relationFormulas from '../../constants/relationFormulas';
import timeUtil from '../time/timeUtil';

const filtersUtil = {
  /**
   * This function helps to check if fieldType is Number or Decimal
   * @param {string} fieldType - value of fieldType
   * @returns {boolean} True if the field type is a NUMBER or a DECIMAL, false otherwise
   */
  isNumberOrDecimal: fieldType => (
    fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
    fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL
  ),

  /**
   * Get an array of the used DEs in the given filter set
   * @param {Object} filterSet - Filter set object
   * @returns {Array} - array of DEs
   */
  getUsedDEsPropsInFilterSet: (filterSet) => {
    const listOfDEs = filterSet?.filters?.filters?.reduce((listOfFilters, currentFilter) => {
      if (currentFilter.filters) {
        return [
          ...listOfFilters,
          ...filtersUtil.getUsedDEsPropsInFilterSet({ filters: { filters: currentFilter.filters } }),
        ];
      }

      return [...listOfFilters, {
        deObjectId: currentFilter.deId,
        deAlias: currentFilter.collectionAlias,
        filterId: currentFilter.id,
        fieldObjectID: currentFilter.fieldObjectID,
      }];
    }, []);

    // Remove duplicates and return the array
    return [...new Set(listOfDEs)];
  },

  /**
   * Get filter sets to display
   * @param {Array} filterSets - List of all filter sets
   * @param {Array} selectedDataExtensions - List of selected data extensions
   * @returns {Array} - List of filter sets of to display
   */
  getFilterSetsToDisplay: (filterSets, selectedDataExtensions) => {
    const selectedDataExtensionsObjectIDsAndAliases = selectedDataExtensions?.map(
      s => ({ objectID: s.ObjectID, alias: s.deAlias }),
    ) || [];

    return filterSets?.filter((filter) => {
      const usedDEsObjectIDsInFilterSet = filtersUtil.getUsedDEsPropsInFilterSet(filter);

      return usedDEsObjectIDsInFilterSet?.every(
        (elem) => {
          const relatedDE = selectedDataExtensions?.find(de => de?.ObjectID === elem?.deObjectId);
          const relatedField = relatedDE?.fields?.find(filter => filter?.ObjectID === elem?.fieldObjectID);

          return selectedDataExtensionsObjectIDsAndAliases?.some(
            selectedDE => selectedDE.objectID === elem.deObjectId &&
              (selectedDE.alias === elem.deAlias ||
                filter.sourceType === Constants.FILTER_SET__SOURCE_TYPE__DATA_EXTENSION),
          ) && relatedField;
        },
      );
    });
  },

  /**
   * Get filter sets to display
   * @param {Array} filterSets - List of all filter sets
   * @param {Array} selectedDataExtensions - List of selected data extensions
   * @returns {Array} - List of filter sets of to display
   */
  getGroupedFilterSetsToDisplay: (filterSets, selectedDataExtensions) => {
    const selectedDataExtensionsObjectIDsAndAliases = selectedDataExtensions?.map(
      s => ({ objectID: s.ObjectID, alias: s.deAlias }),
    ) || [];

    const response = filterSets?.filter((filter) => {
      const usedDEsObjectIDsInFilterSet = filtersUtil.getUsedDEsPropsInFilterSet(filter);

      return usedDEsObjectIDsInFilterSet?.every(
        (elem) => {
          const relatedDE = selectedDataExtensions?.find(de => de?.ObjectID === elem?.deObjectId);
          const relatedField = relatedDE?.fields?.find(filter => filter?.ObjectID === elem?.fieldObjectID);

          return selectedDataExtensionsObjectIDsAndAliases?.some(
            selectedDE => selectedDE.objectID === elem.deObjectId &&
              (selectedDE.alias === elem.deAlias ||
                filter.sourceType === Constants.FILTER_SET__SOURCE_TYPE__DATA_EXTENSION),
          ) && relatedField;
        },
      );
    });
    // Group filtersets based on category
    const groupedData = Object.values(response.reduce((acc, obj) => {
      const category = obj.category?.name || 'Uncategorized';

      if (!acc[category]) {
        acc[category] = { category: { name: category }, filters: [], showFilterSetFields: false };
      }
      acc[category].filters.push(obj);

      return acc;
    }, {}));

    // sort grouped data alphabetically
    const groupedDataSorted = groupedData.sort((a, b) => {
      const categoryA = a.category.name.toLowerCase();
      const categoryB = b.category.name.toLowerCase();

      if (categoryA < categoryB) {
        return -1;
      }
      if (categoryA > categoryB) {
        return 1;
      }

      return 0;
    });

    if (groupedDataSorted.length === 1) {
      groupedDataSorted[0].showFilterSetFields = true;
    }

    return groupedDataSorted;
  },

  /**
   * Update all DE aliases in all filters
   * @param {Object} filters - filters to update
   * @param {string} newAlias - The new alias to add into DEs
   * @returns {Object} Updated filters
   */
  updateFiltersDEAliases: (filters, newAlias) => {
    return filters?.filters?.reduce((filtersToReturn, currentFilter, idx) => {
      if (currentFilter.collectionAlias) {
        filtersToReturn.filters[idx].collectionAlias = newAlias;
      } else if (currentFilter.filters) {
        const updatedSubFilters = filtersUtil.updateFiltersDEAliases({ filters: currentFilter.filters }, newAlias);

        filtersToReturn.filters[idx].filters = updatedSubFilters.filters;
      }

      return filtersToReturn;
    }, filters);
  },

  /**
   * Create subQuery filter path
   * @param {String} filterLineId - filter line ID
   * @returns {Object} - returns the target filters path as array
   */
  createSubQueryFilterPath: filterLineId => ('filters,' + filterLineId.replace(',', ',filters,') +
    ',subQuery,filters').split(','),

  /**
   * Remove filter line
   * @param {object} selectedFilters - Selected filters
   * @param {object} properties - Required properties to delete filterline
   * @returns {Object} updated filters after removing filter line
   */
  removeFilterLine: (selectedFilters, properties) => {
    const {
      filterLineId, subQueryModalFilterLineId,
      groupedFilter, isSubQuery, isInResultsFormula,
    } = properties;

    const idSeparator = isInResultsFormula ? '-' : ',';

    const filtersState = JSON.parse(JSON.stringify(selectedFilters));

    let filtersArray = filtersState;

    // what filters we want to remove from, could be main filters or a subQuery filters

    if (isSubQuery && !groupedFilter) {
      // get sub query filters
      const subQueryFiltersPath = filtersUtil.createSubQueryFilterPath(subQueryModalFilterLineId);

      filtersArray = _.get(filtersState, subQueryFiltersPath);
    }

    const updatedFilters = filtersArray?.filters?.reduce((previousUpdatedFilters, currentFilter, currentIndex) => {
      if (currentFilter?.id === filterLineId) {
        previousUpdatedFilters.filters.splice(currentIndex, 1);

        // Update IDs after delete
        if (String(previousUpdatedFilters.filters[0]?.id).length === 1) {
          if (previousUpdatedFilters.filters.length === 1 && previousUpdatedFilters.filters[0].filters) {
            previousUpdatedFilters = {
              filters: previousUpdatedFilters.filters[0].filters,
              operator: previousUpdatedFilters.filters[0].operator,
            };
          }

          previousUpdatedFilters.filters = previousUpdatedFilters.filters.map((filter, index) => {
            filter.id = `${index}`;

            return filter;
          });

          return previousUpdatedFilters;
        }

        if (previousUpdatedFilters.filters.length === 1) {
          if (isInResultsFormula) {
            if (previousUpdatedFilters.id) {
              previousUpdatedFilters = {
                ...previousUpdatedFilters.filters[0],
                id: String(previousUpdatedFilters.id),
              };
            } else if (previousUpdatedFilters?.filters[0]?.filters) {
              previousUpdatedFilters = {
                filters: previousUpdatedFilters?.filters[0]?.filters,
                operator: previousUpdatedFilters?.filters[0]?.operator,
              };
              // Update Ids
              previousUpdatedFilters.filters = previousUpdatedFilters.filters.map((filter, index) => {
                const oldId = String(filter.id).split(idSeparator);

                filter.id = `${oldId[0]}${idSeparator}${index}`;

                return filter;
              });
            }
          } else {
            previousUpdatedFilters = {
              ...previousUpdatedFilters.filters[0],
              id: String(previousUpdatedFilters.id),
            };
          }
        } else {
          previousUpdatedFilters.filters = previousUpdatedFilters.filters.map((filter, index) => {
            const oldId = String(filter.id).split(idSeparator);

            oldId.pop();

            filter.id = `${oldId.join(idSeparator)}${idSeparator}${index}`;

            return filter;
          });
        }

        return previousUpdatedFilters;
      }

      if (currentFilter.filters) {
        const filtersStateFromChild = filtersUtil.removeFilterLine(
          currentFilter,
          { ...properties, groupedFilter: true },
        );

        previousUpdatedFilters.filters[currentIndex] = filtersStateFromChild;

        return previousUpdatedFilters;
      }

      if (isInResultsFormula && currentFilter?.subQuery?.formulas) {
        const filtersStateFromFormula = filtersUtil.removeFilterLine(
          currentFilter.subQuery.formulas,
          { ...properties, groupedFilter: true },
        );

        previousUpdatedFilters.filters[currentIndex].subQuery.formulas = filtersStateFromFormula;
      }

      return previousUpdatedFilters;
    }, filtersArray);

    if (isSubQuery && !groupedFilter) {
      // if we are removing a subQuery filter, then update the parent filter and return all parent filters.
      const subQueryFiltersPath = filtersUtil.createSubQueryFilterPath(subQueryModalFilterLineId);

      // update sub query filters
      _.set(filtersState, subQueryFiltersPath, updatedFilters);

      return filtersState;
    }

    return updatedFilters;
  },

  /**
   *
   * @param {Object} filters - Selected filters from filter set
   * @param {Array} selectedDataExtensions - selected data extensions from data set
   * @param {Array} retrievedSubqueryDEs - retrieved subquery data extensions
   * @returns {Array} - Array with Updated filters and a boolean to tell if update was done
   */
  updateFiltersFromFilterSet: (filters, selectedDataExtensions, retrievedSubqueryDEs) => {
    const selectedDataExtensionsObjectIDsAndAliases = selectedDataExtensions?.map(
      s => ({ objectID: s.ObjectID, alias: s.deAlias }),
    );

    // Update filters subquery collections if updated on SFMC
    filters?.filters.forEach((filter) => {
      filtersUtil.updateFilterSetSubqueryCollections(filter, retrievedSubqueryDEs);
    });

    // Update the deAlias of data extension used in filters if it is has been updated on SFMC
    selectedDataExtensions?.forEach((selectedDE) => {
      const filtersBelongingToDE = filters?.filters.filter(filter => filter.deId === selectedDE.ObjectID);
      const updatedFilters = filtersUtil.updateFiltersDEAliases({ filters: filtersBelongingToDE }, selectedDE.deAlias);

      // Replace original filters with updated filters
      filters.filters = filters?.filters.map((filter) => {
        const updatedFilter = updatedFilters.filters.find(updated => updated.id === filter.id);

        return updatedFilter || filter;
      });
    });

    const usedDEsObjectIDsInFilterSet =
      filtersUtil.getUsedDEsPropsInFilterSet({ filters });

    return usedDEsObjectIDsInFilterSet.reduce((result, currentElement, index) => {
      /*
       * We only use the first element otherwise we use the update one
       *  that takes into account the updated filterline id
       */
      const updatedCurrentElement = index === 0 ?
        currentElement :
        result.updatedFilters.usedDEsWithFilterIDs.pop();

      // Check if there is a missing DE and delete filter line if it's the case
      if (updatedCurrentElement &&
        !selectedDataExtensionsObjectIDsAndAliases.some(
          selectedDE => selectedDE.objectID === updatedCurrentElement?.deObjectId &&
            selectedDE.alias === updatedCurrentElement.deAlias,
        )) {
        const filters = filtersUtil.removeFilterLine(
          result.updatedFilters.filters,
          { filterLineId: updatedCurrentElement.filterId },
        );

        const usedDEsWithFilterIDs = filtersUtil.getUsedDEsPropsInFilterSet({ filters });

        return { updatedFilters: { filters, usedDEsWithFilterIDs }, isUpdated: true };
      }

      // Check if there is a field in SFMC and delete filter line if it's the case
      if (updatedCurrentElement) {
        const relatedDE = selectedDataExtensions?.find(de => de?.ObjectID === updatedCurrentElement?.deObjectId);
        const relatedField = relatedDE?.fields?.find(f => f?.ObjectID === updatedCurrentElement?.fieldObjectID);

        if (!relatedField) {
          const filters = filtersUtil.removeFilterLine(
            result.updatedFilters.filters,
            { filterLineId: updatedCurrentElement.filterId },
          );

          const usedDEsWithFilterIDs = filtersUtil.getUsedDEsPropsInFilterSet({ filters });

          return { updatedFilters: { filters, usedDEsWithFilterIDs }, isUpdated: true };
        }
      }

      return result;
    }, { updatedFilters: { filters, usedDEsWithFilterIDs: usedDEsObjectIDsInFilterSet }, isUpdated: false });
  },

  /**
   * This function helps to check if criteria is Contains or Does Not Contain
   * @param {string} criteria - criteria
   * @returns {boolean} True if the criteria is Contains or Does Not Contain, false otherwise
   */
  isContainsOrNotContains: criteria => (
    criteria === Constants.FILTERLINE__CRITERIA__CONTAINS ||
    criteria === Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN
  ),

  /**
   * This function helps to check if a criteria is EQUALS or NOT EQUAL TO
   * @param {string} criteria - A criteria
   * @returns {boolean} True if the criteria is EQUALS or NOT EQUAL TO, false otherwise
   */
  isEqualOrNotEqual: criteria => (
    criteria === Constants.FILTERLINE__CRITERIA__EQUALS || criteria === Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO
  ),

  /**
   * This function helps to check if a criteria is EMPTY or NOT EMPTY
   * @param {string} criteria - A criteria
   * @returns {boolean} True if the criteria is EMPTY or NOT EMPTY, false otherwise
   */
  isEmptyOrNotEmpty: criteria => (
    criteria === Constants.FILTERLINE__CRITERIA__IS_EMPTY || criteria === Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY
  ),

  /**
   * This function helps to check if a criteria is IN RESULTS or NOT IN RESULTS
   * @param {string} criteria - A criteria
   * @returns {boolean} True if the criteria is IN RESULTS or NOT IN RESULTS, false otherwise
   */
  isInResultsOrNotInResults: criteria => (criteria === Constants.FILTERLINE__CRITERIA__IN_RESULTS ||
    criteria === Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS
  ),

  /**
   * This function helps to check  if a criteria is IN or NOT IN
   * @param {string} criteria - A criteria
   * @returns {boolean} True if the criteria is IN or NOT IN, false otherwise
   */
  isInOrNotInOrOneOf: criteria => [Constants.FILTERLINE__CRITERIA__IN,
    Constants.FILTERLINE__CRITERIA__NOT_IN,
    Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF,
    Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF,
  ].includes(criteria),

  /**
   * This function helps to check  if a criteria is BETWEEN or NOT BETWEEN
   * @param {string} criteria - A criteria
   * @returns {boolean} True if the criteria is BETWEEN or NOT BETWEEN, false otherwise
   */
  isBetweenOrNotBetween: criteria => (criteria === Constants.FILTERLINE__CRITERIA__BETWEEN ||
    criteria === Constants.FILTERLINE__CRITERIA__NOT_BETWEEN),

  /**
   * Return array or string type of a given value
   * @param {object[]|string|undefined} value
   * - object[]: only when criteria in or not in is selected
   * - string: when criteria in or not in is not selected
   * - undefined: when filter`s value is empty while changing the criteria
   * @returns {array|string} array or string depending of a given value type
   */
  returnArrayOrStringTypeOfValue: (value) => {
    switch (typeof value) {
      case 'object':
        return value.join(',');
      case 'string':
        return value;
      case 'undefined':
        return '';
      default:
        return '';
    }
  },

  /**
   * Gets all filters and puts them in an array
   * @param {object} selectedFilters - An object containing an array of filters and operator
   * @param {array} result - The array to populate with the filters
   * @returns {array} An array of filters
   */
  getAllFilters: (selectedFilters, result) => {
    const { filters = [] } = selectedFilters;

    for (let i = 0; i < filters.length; i += 1) {
      // Nested filter
      if (filters[i].filters) {
        filtersUtil.getAllFilters(filters[i], result);
      } else {
        result.push(filters[i]);
      }
    }

    return result;
  },

  /**
   * Defines whether the fieldType is decimal/number and criteria is in/not in in filter
   * @param {string} fieldType - field type
   * @param {string} criteria - filter criteria
   * @returns {boolean} true/false depending on whether the filter uses tags
   */
  isFilterUsingTagInput: (fieldType, criteria) => (
    (fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ||
      fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) && (
      criteria === Constants.FILTERLINE__CRITERIA__IN ||
      criteria === Constants.FILTERLINE__CRITERIA__NOT_IN
    )),
  /**
   * Returns array with active picklist options
   * @param {array} pickLists - array with picklists
   * @param {string} fieldObjectID - selected field Object ID
   * @returns {array} array with active picklists
   */
  returnActiveOptionsForPicklist: (pickLists, fieldObjectID) => {
    const options = [];

    if (pickLists) {
      pickLists.forEach((pickListOption) => {
        if (pickListOption.fieldObjectId === fieldObjectID && pickListOption.isActive) {
          pickListOption.options?.forEach((option) => {
            if (option.isActive) {
              options.push(option);
            }
          });
        }
      });
    }

    return options;
  },

  /**
   * If criteria is Begins with / End with
   * don't show picklist option => show normal input field
   * @param {string} criteria - Criteria to check
   * @returns {boolean} True if we shouldn't render a picklists for this criteria, false otherwise
   */
  doNotRenderPickListsOptions: criteria => (
    criteria === Constants.FILTERLINE__CRITERIA__BEGINS_WITH ||
    criteria === Constants.FILTERLINE__CRITERIA__ENDS_WITH
  ),

  /**
   * Converts criteria to english
   * @param {string} criteria - Type of filter criteria
   * @param {string} fieldType - Type of field
   * @param {boolean} relation - defines whether filter is of relation type
   * @returns {string|null} The criteria label
   */
  convertCriteriaToCriteriaLabel: (criteria, fieldType, relation) => {
    const criteriaArray = {};

    if (relation) {
      criteriaArray[Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE] = 'exactly'; // =
      criteriaArray[Constants.FILTERLINE__RELATION_CRITERIA__AT_LEAST_VALUE] = 'at least'; // >=
      criteriaArray[Constants.FILTERLINE__RELATION_CRITERIA__MORE_THAN_VALUE] = 'more than'; // >
      criteriaArray[Constants.FILTERLINE__RELATION_CRITERIA__AT_MOST_VALUE] = 'at most'; // <=
      criteriaArray[Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE] = 'less than'; // <
    } else {
      if (fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
        criteriaArray[Constants.FILTERLINE__CRITERIA__EQUALS] = 'is equal to'; // =
        criteriaArray[Constants.FILTERLINE__CRITERIA__BETWEEN] = 'is between'; // between
        criteriaArray[Constants.FILTERLINE__CRITERIA__NOT_BETWEEN] = 'is not between'; // not between
        criteriaArray[Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO] = 'is not equal to'; // <>
      } else {
        criteriaArray[Constants.FILTERLINE__CRITERIA__EQUALS] = 'equals'; // =
        criteriaArray[Constants.FILTERLINE__CRITERIA__BETWEEN] = 'between'; // between
        criteriaArray[Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO] = 'not equal to'; // <>
      }
      criteriaArray[Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS] = 'is in previous'; // in-previous
      criteriaArray[Constants.FILTERLINE__CRITERIA__DATE__TODAY] = 'is today'; // today
      criteriaArray[Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT] = 'is in next'; // in-next
      criteriaArray[Constants.FILTERLINE__CRITERIA__SMALLER_THAN] = { // <
        [Constants.FILTERLINE__FIELDTYPE__DATE]: 'is before',
        default: 'smaller than',
      };
      criteriaArray[Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO] = { // <=
        [Constants.FILTERLINE__FIELDTYPE__DATE]: 'is before or on',
        default: 'smaller than or equal to',
      };
      criteriaArray[Constants.FILTERLINE__CRITERIA__GREATER_THAN] = { // >
        [Constants.FILTERLINE__FIELDTYPE__DATE]: 'is after',
        default: 'greater than',
      };
      criteriaArray[Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO] = { // >=
        [Constants.FILTERLINE__FIELDTYPE__DATE]: 'is after or on',
        default: 'greater than or equal to',
      };
      criteriaArray[Constants.FILTERLINE__CRITERIA__IS_EMPTY] = 'is empty'; // is-empty
      criteriaArray[Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY] = 'is not empty'; // is-not-empty
      criteriaArray[Constants.FILTERLINE__CRITERIA__IN] = 'equals one of'; // in
      criteriaArray[Constants.FILTERLINE__CRITERIA__NOT_IN] = 'not equal to one of'; // not-in
      criteriaArray[Constants.FILTERLINE__CRITERIA__CONTAINS] = 'contains'; // contains
      criteriaArray[Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN] = 'does not contain'; // does-not-contain
      criteriaArray[Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF] = 'contains one of'; // contains-one-of
      criteriaArray[
        Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF] = 'does not contain one of'; // does-not-contain-one-of
      criteriaArray[Constants.FILTERLINE__CRITERIA__BEGINS_WITH] = 'begins with'; // begins-with
      criteriaArray[Constants.FILTERLINE__CRITERIA__ENDS_WITH] = 'ends with'; // ends-with
      criteriaArray[Constants.FILTERLINE__CRITERIA__IN_RESULTS] = 'in'; // in-results
      criteriaArray[Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS] = 'not in'; // not-in-results
    }

    if (!criteriaArray[criteria]) return null;

    if (relation && criteriaArray[criteria]) {
      // for relation filter return only criteria
      return criteriaArray[criteria];
    }

    // Specific criteria + field type > Specific criteria default > Default
    return criteriaArray[criteria][fieldType] || criteriaArray[criteria].default || criteriaArray[criteria];
  },

  /**
   * Helper function for updateFilterText
   * @param {object} filters - Filters
   * @param {string} operator - Or/and
   * @param {string} showDEName - Boolean indicating if the Data Extension name needs to be included
   * @param {array} pickLists - Array of the pickLists
   * @param {object} userInfo - User info from cookie
   * @returns {string} The filter text
   */
  getFilterTextForFilters: (filters, operator, showDEName = true, pickLists, userInfo) => {
    let text = '';

    /*
     * Note JvD: I believe it's possible to work with filterTextForFilters and
     * .join, similar to filterTextForFilterContainer but havent been able
     * to figure it out yet. Once we do, we don't have to remove AND and OR from
     * the end of the query anymore
     */
    const filterTextForFilterContainer = [];
    const userLocale = timeUtil.getUserLocale(userInfo);

    if (filters) {
      for (let f = 0; f < filters.length; f += 1) {
        const filter = filters[f];
        // is filter a container of a filter line?

        if (filter.filters) {
          text +=
            `(${filtersUtil.getFilterTextForFilters(
              filter.filters,
              filter.operator,
              showDEName,
              pickLists,
              userInfo,
            )}) ${operator} `;
        } else {
          let value = '';

          let valueForRelation = '';

          if (filter.isCompareFieldsFilter && !filter.relation) {
            if (Object.prototype.hasOwnProperty.call(filter, 'comparedField') &&
              filter.comparedField &&
              Object.prototype.hasOwnProperty.call(filter.comparedField, 'Name') &&
              filter.comparedField.Name &&
              Object.prototype.hasOwnProperty.call(filter, 'comparedDataExtension') &&
              filter.comparedDataExtension &&
              Object.prototype.hasOwnProperty.call(filter.comparedDataExtension, 'deAlias') &&
              filter.comparedDataExtension.deAlias) {
              value += ` ${filter.comparedField.Name.toString()} (${filter.comparedDataExtension.deAlias.toString()}) `;
            } else value += '""';
          } else if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
            filtersUtil.isBetweenOrNotBetween(filter.criteria)) {
            value += `${timeUtil.formatDateFilterText(new Date(filter.startDate), userLocale)} 
            and ${timeUtil.formatDateFilterText(new Date(filter.endDate), userLocale)}`;
          } else if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
            filter.criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY) {
            value += ' ';
          } else if (filtersUtil.isInResultsOrNotInResults(filter.criteria)) {
            // only show when field and collection is chosen
            if (filter.subQuery &&
              filter.subQuery.fields && filter.subQuery.fields.length &&
              filter.subQuery.collections && filter.subQuery.collections.length &&
              !filter.relation
            ) {
              value = ` ${filter.subQuery.filters && filter.subQuery.filters.filters &&
                filter.subQuery.filters.filters.length > 0 ?
                'filtered' :
                ''}
                ${filter.subQuery.fields[0].field || '<choose field>'}
                  of
                ${filter.subQuery.collections[0].collection}`;
            }

            // show value for relation filter
            if (filter.subQuery?.relationData?.value) {
              valueForRelation = filter.subQuery.relationData.value;
            } else if (filter.subQuery?.relationData?.value === '') {
              valueForRelation = '""';
            }
          } else if (filter.dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE &&
            !filter.relation && !(filtersUtil.isEmptyOrNotEmpty(filter.criteria) ||
              filter.criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY)) {
            if (filter.dateValueStart !== Constants.FILTERLINE__DATE_VALUE_START__TODAY) {
              value = ` ${filter.dateValue} ${filter.filterInterval}${filter.dateValue > 1 ? 's' : ''}`;
            }
            if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE && (
              filter.criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
              filter.criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT ||
              filter.criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
              filter.criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO ||
              filter.criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
              filter.criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO ||
              filtersUtil.isEqualOrNotEqual(filter.criteria)) &&
              filter.dateValueStart !== Constants.FILTERLINE__DATE_VALUE_START__TODAY
            ) {
              if (filter.criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
                filter.criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO ||
                filter.criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
                filter.criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO
              ) {
                if (filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW ||
                  filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY) {
                  value += ' ago ';
                } else if (filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW) {
                  value += ' from now ';
                } else if (filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY) {
                  value += ' from today ';
                }
              } else if (filtersUtil.isEqualOrNotEqual(filter.criteria) &&
                (filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY ||
                  filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW)) {
                value += ' ago ';
              } else if (filtersUtil.isEqualOrNotEqual(filter.criteria) &&
                (filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY ||
                  filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW)) {
                if (filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY) {
                  value += ' from today ';
                } else {
                  value += ' from now ';
                }
              }
            } else {
              value += ` ${filter.dateValueStart?.toLowerCase()} `;
            }
          } else if (
            filter.criteria !== Constants.FILTERLINE__CRITERIA__IS_EMPTY &&
            filter.criteria !== Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY
          ) {
            /*
             * Server stores dates as strings, but we need to have them as dates in this component,
             * so transform if needed
             */
            if (
              filter.value &&
              !(filter.value instanceof Date) &&
              filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
              (!filter.formula) && (!filter.relation)
            ) {
              const dateValue = new Date(new Date(filter.value).setSeconds(0)).setMilliseconds(0);

              filter.value = new Date(dateValue);
            }
            // transform dates into nice DD/MM/YYYY format
            if (filter.value instanceof Date && !filter.relation) {
              const date = filter.value;

              value = timeUtil.formatDateFilterText(date, userLocale);
            } else {
              // check if criteria is 'between'
              if (filtersUtil.isBetweenOrNotBetween(filter.criteria)) {
                if (filter.value) {
                  if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
                    const { startDate, endDate } = filter.value;

                    value = `${startDate || "'null'"} and ${endDate || "'null'"}`;
                  } else {
                    const { minValue, maxValue } = filter.value;

                    value = `${minValue || "'null'"} and ${maxValue || "'null'"}`;
                  }
                }
              } else {
                /*
                 * all other field types
                 * we had <i>filter.value</i> before but doesnt work anymore since react
                 */
                value = filter.value ? filter.value : '""';
                if (filter.formula && !filter.relation) {
                  value = filter.value ? filter.value : '0';
                }
              }
            }
          }

          /**
           * Helper function for showing DE Name in proper format
           * @param {string} fromOrTo - it means whether DE is displayed for fromDE or toDE,
           * this only applies to the relation filter
           * @returns {string} The DE Name or empty string
           */
          const showDENameForFilter = (fromOrTo) => {
            if (showDEName) {
              // if showDEName exists and we are using relation filter with COUNT formula
              if (filter.relation && filter.subQuery.relationData?.formula &&
                filter.subQuery.relationData.formula ===
                Constants.FORMULA__VALUE__COUNT) {
                if (fromOrTo === 'from') {
                  // return collectionAlias without brackets
                  return `${Util.abbreviate(filter.collectionAlias, 60)} has/have`;
                }

                if (filter.subQuery?.relationData?.collectionAlias) {
                  // return toDE Name without brackets
                  return `${Util.abbreviate(filter.subQuery?.relationData?.collectionAlias, 60)}`;
                }
              }

              if (fromOrTo === 'from' && !filter.relation) {
                // in other filters use DE Name in brackets
                return `(${Util.abbreviate(filter.collectionAlias, 60)})`;
              }
            }

            // in other case return empty string
            return '';
          };

          /**
           * Helper function for display proper label with formula for relation filter
           * @returns {string} string with formula, field and collectionAlias
           */
          const getFormulaForRelationFilter = () => {
            // for relation filter, when formula is not COUNT
            if (filter.relation && filter.subQuery.relationData?.formula &&
              filter.subQuery.relationData.formula !== Constants.FORMULA__VALUE__COUNT) {
              // define field name
              const fieldName = filter.subQuery.relationData.field || '<no matching field available>';

              // define the formula label based on the formula value in relationData
              const formulaLabel = relationFormulas[filter.subQuery.relationData.formula];

              // return formula with field and collection Alias
              return `${formulaLabel} of
                ${fieldName} on
                ${filter.subQuery.relationData.collectionAlias} is`;
            }

            // in other case return empty string
            return '';
          };

          /**
           * Helper function for get proper criteria for criteria Label
           * @param {object} filterForCriteria - object with criteria
           * @returns {string} criteria string
           */
          const getCriteriaForCriteriaLabel = filterForCriteria => (filterForCriteria.relation &&
            filterForCriteria.subQuery.relationData &&
            filterForCriteria.subQuery.relationData.criteria) ?
            filterForCriteria.subQuery.relationData.criteria :
            filterForCriteria.criteria;

          if (
            value !== '' || valueForRelation !== '' ||
            filtersUtil.isEmptyOrNotEmpty(filter.criteria)
          ) {
            filterTextForFilterContainer.push(
              `${filter.formula && !filter.relation ? `${filter.formula} of` : ''}
                ${getFormulaForRelationFilter()}
                ${filter.relation ? '' : Util.abbreviate(filter.field, 60)}
                ${showDENameForFilter('from')}
                ${filtersUtil.convertCriteriaToCriteriaLabel(
    getCriteriaForCriteriaLabel(filter),
    filter.fieldType,
    filter.relation,
  )}
                ${value}
                ${valueForRelation}
                ${showDENameForFilter('to')}`,
            );
          }
        }
      }
      text += filterTextForFilterContainer.join(` ${operator} `);
    }

    // Remove trailing space
    text = text.trim();

    // Remove trailing AND or OR
    if (text.substring(text.length - 3) === 'AND') {
      text = text.substring(0, text.length - 3);
    }

    if (text.substring(text.length - 2) === 'OR') {
      text = text.substring(0, text.length - 2);
    }

    return text;
  },

  /**
   * It loops through the filters object received as a prop and
   * updates the proper fields name using fieldObjectID
   * @param {array} selectedDataExtensions - Data extensions containing the reference field names to update filters
   * @param {object} filtersToUpdate - Could be a filter group or a single filter line
   * @returns {void}
   */
  updateFiltersFieldName: (selectedDataExtensions, filtersToUpdate) => {
    // Store fields with already updated name
    const fields = [];

    // This obtains the fields from the selected Data Extension
    if (selectedDataExtensions && selectedDataExtensions.length > 0) {
      selectedDataExtensions.forEach((dataExtension) => {
        if (dataExtension.fields && dataExtension.fields.length > 0) {
          dataExtension.fields.forEach(field => fields.push(field));
        }
      });
    }

    // Abstraction needed to call function recursively
    const update = (filter) => {
      const actualFilter = filter;
      // If it's a filter group

      if ('filters' in filter && 'operator' in filter) {
        if (filter.filters && filter.filters.length) {
          filter.filters.forEach(childFilter => update(childFilter));
        }
      } else {
        // If it's a filter line
        fields.forEach((field) => {
          // Get the field name matching the ID of the filter we are updating
          if (field.ObjectID === filter.fieldObjectID) actualFilter.field = field.Name.toString();
        });

        /*
         * Set the new fields on the subQuery
         */
        if (filter.subQuery) {
          if (filter.subQuery.fields && filter.subQuery.fields.length > 0) {
            fields.forEach((field) => {
              filter.subQuery.fields.forEach((filterField) => {
                if (field.ObjectID === filterField.availableFieldObjectID) {
                  // Have to skip this eslint error as the prop 'filterField' needs to be reassigned
                  // eslint-disable-next-line no-param-reassign
                  filterField.field = field.Name.toString();
                }
              });
            });
          }
        }
      }
    };

    // Run the update
    update(filtersToUpdate);
  },

  /**
   *
   * @param {object} filters - filters to update
   * @param {object} settings - settings to apply to filters
   * @returns {void}
   */
  updateTimezoneSettingsForDateFilter: (filters, settings) => {
    const {
      convertToTimezone, convertTimezone, convertFromTimezone,
    } = settings;

    // Abstraction needed to call function recursively
    const update = (filter) => {
      // If it's a filter group
      if ('filters' in filter) {
        if (filter.filters?.length) {
          filter.filters.forEach(childFilter => update(childFilter));
        }
      } else {
        // If it's a filter line
        if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
          filter.convertToTimezone = convertToTimezone;
          filter.convertTimezone = convertTimezone;
          filter.convertFromTimezone = convertFromTimezone;
        }

        /*
         * Change settings on the subQuery
         */
        if (filter.subQuery?.filters?.filters?.length) {
          update(filter.subQuery.filters);
        }
      }
    };

    update(filters);
  },

  /**
   * Find the first available date filter in a filter container.
   * @param {object} filtersArray - array of filters
   * @returns {object} - The found filter
   */
  findDateTypeFilter: (filtersArray) => {
    let element;

    filtersArray.find((filter) => {
      if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
        element = filter;
      } else if (filter.filters) {
        element = filtersUtil.findDateTypeFilter(filter.filters);
      }

      return element;
    });

    return element;
  },

  /**
   * Update collectionAlias on filterLines in filters
   * @param {string} oldCollectionAlias - old alias
   * @param {string} newCollectionAlias - new alias
   * @param {object} filters - filters to update
   * @returns {array} - updated filters
   */
  handleUpdateCollectionAliasInFilters: (oldCollectionAlias, newCollectionAlias, filters) => filters.map((filter) => {
    // If the array element contains a filters array, execute this function on this branch
    if (filter.filters) {
      // eslint-disable-next-line no-param-reassign
      filter.filters = filtersUtil.handleUpdateCollectionAliasInFilters(
        oldCollectionAlias,
        newCollectionAlias,
        filter.filters,
      );
    } else {
      // If collectionAlias matches the name to be changed, update it.
      if (filter.collectionAlias === oldCollectionAlias) {
        // eslint-disable-next-line no-param-reassign
        filter.collectionAlias = newCollectionAlias;
      }

      // If deAlias of comparedDataExtension matches the name to be changed, update it.
      if (filter.comparedDataExtension && filter.comparedDataExtension.deAlias === oldCollectionAlias) {
        // eslint-disable-next-line no-param-reassign
        filter.comparedDataExtension.deAlias = newCollectionAlias;
      }
    }

    return filter;
  }),

  /**
   * Id generator for filters
   * @param {string} parentId - id of a parent
   * @param {number} id - id
   * @returns {string} - newId
   */
  createId: (parentId, id) => {
    const newId = parentId == null ? '' + id : parentId + ',' + id;

    return newId;
  },

  /**
   * Recreate all ids for filters
   * Needs to be called after updated filters has been saved as state
   * @param {object} filtersArray - filters
   * @param {string} parentId - id of a parent
   * @returns {object} - filters
   */
  recreateIds: (filtersArray, parentId) => {
    // Loop through filtersArray
    for (let f = 0; f < filtersArray.length; f += 1) {
      // Update id for this element
      // eslint-disable-next-line no-param-reassign
      filtersArray[f].id = filtersUtil.createId(parentId, f);

      /*
       * If the array element contains a filters array, execute this function on this branch
       * (executes when you join filters)
       */
      if (filtersArray[f].filters) {
        filtersUtil.recreateIds(filtersArray[f].filters, filtersArray[f].id);
      }
    }

    return filtersArray;
  },

  /**
   * Get the data extensions used in subquery in filterSet
   * @param {Object} filterSet - filterSet object to get data extensions from
   * @returns {Array} - selectedDataExtensions
   */
  getFilterSetSubqueryCollections: (filterSet) => {
    const selectedDataExtensions = (filterSet?.filters?.filters ?? []).reduce((acc, nestedFilter) => {
      if (nestedFilter.subQuery && nestedFilter.subQuery.collections[0]) {
        const collection = nestedFilter.subQuery.selectedDataExtension;

        acc.push(collection);
      } else if (nestedFilter.filters) {
        acc.push(...filtersUtil.getFilterSetSubqueryCollections(nestedFilter.filters));
      }

      return acc;
    }, []);

    return selectedDataExtensions;
  },

  /**
   *
   * @param {Object} filter - filter to update
   * @param {Array} retrievedSubqueryDEs - retrieved subquery data extensions for filterSet
   * @returns {void}
   */
  updateFilterSetSubqueryCollections: (filter, retrievedSubqueryDEs) => {
    const { subQuery } = filter;

    if (!subQuery || !subQuery.filters?.filters || subQuery.filters.filters.length === 0) {
      return;
    }

    const updatedSubQueryDEs = retrievedSubqueryDEs?.map((de) => {
      if (de.ObjectID === subQuery.selectedDataExtension?.ObjectID) {
        return {
          ...de,
          deAlias: de.Name,
        };
      }

      return de;
    });

    const updatedSubQueryDE = updatedSubQueryDEs?.find(
      de => de.ObjectID === subQuery.selectedDataExtension?.ObjectID,
    );

    if (updatedSubQueryDE) {
      subQuery.selectedDataExtension = updatedSubQueryDE;
    }

    subQuery.filters.filters.forEach((subFilter) => {
      filtersUtil.updateFilterSetSubqueryCollections(subFilter, retrievedSubqueryDEs);
    });
  },

  /**
   *
   * @param {Object} selectedFilters - filters to remove empty filters from
   * @returns {object} - filters without any empty filters
   */
  removeEmptyFilters: (selectedFilters) => {
    const filtersArrayCopy = JSON.parse(JSON.stringify(selectedFilters.filters));

    filtersArrayCopy.forEach((filter, i) => {
      if (Object.prototype.hasOwnProperty.call(filter, 'filters')) {
        filtersUtil.removeEmptyFilters(filter);
      } else if (!filter.fieldObjectID) {
        filtersArrayCopy.splice(i, 1);
      }
    });

    return { filters: [...filtersArrayCopy], operator: selectedFilters.operator };
  },
};

export default filtersUtil;
