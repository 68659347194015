import React from 'react';
import { SLDSTooltip } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
import timeUtil from '../../../utils/time/timeUtil';

import './styles.scss';

const Tooltip = ({
  id,
  align,
  content,
  dialogClassName,
  children,
  position,
  assistiveText,
  hoverCloseDelay,
  hoverOpenDelay,
  variant,
  type,
  theme,
  ...restProps
}) => {
  /**
   * Generates the appropriate text content for the tooltip based on the type prop
   * @returns {React.ReactNode} The content to be displayed in the tooltip
   */
  const getText = () => {
    switch (type) {
      case Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE:
        return (
          <>
            Please&nbsp;
            <a
              href="https://deselect.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="0"
              className="disabled-feature-link"
            >
              contact DESelect
            </a>
            &nbsp;for more information about using this feature.
          </>
        );
      case Constants.TOOLTIP_TYPE__RELATION_MODAL:
        return `Fields not compatible with the field selected on the left are
            disabled in this dropdown.`;
      case Constants.TOOLTIP_TYPE__DISABLED_AUTO_REFRESH:
        return 'Picklist auto refresh is disabled because server-to-server authentication is not set up.';
      case Constants.TOOLTIP_TYPE__UTC:
        return `Time set here is in UTC. You are currently in UTC ${timeUtil.formatUserUTC()}.`;
      case Constants.TOOLTIP_TYPE__DELETE_SELECTION:
        return `When you Run a selection, DESelect creates one or more query activities
            and writes the results to your target data extension.
            You can delete these items as well if you are not using them in an automation or journey.`;
      case Constants.TOOLTIP_TYPE__RECOMMENDED_FILTER_MODE:
        return 'It is recommended to use Include as this results in faster loading of the data extensions.';
      case Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_NOW:
        return 'Takes into account the current date and time and the date and time of the field.';
      case Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_TODAY:
        return 'Takes into account the current date (not the time) and the date of the field (not the time).';
      case Constants.TOOLTIP_TYPE__TEMPLATE_SELECTION:
        return `When a selection is marked as a template, only the person who created
            it or admins can modify it.`;
      case Constants.TOOLTIP_TYPE__CONVERT_FROM_TIMEZONE:
        return 'SFMC servers use the Central Standard Time (CST) Timezone.';
      case Constants.FEATURE__DATA_SETS:
        return 'As an admin, you can create data sets by defining relations between multiple data extensions.';
      case Constants.FEATURE__FILTER_SETS:
        return 'As an admin, you can create filter sets by predefining a combination of filters.';
      case Constants.TOOLTIP_TYPE__PREDEFINED_DATA_EXTENSIONS:
        return 'Only data extensions for which predefined relations are defined are available here';
      case Constants.TOOLTIP_TYPE__S2S_PACKAGE_NOT_INSTALLED:
        return Constants.S2S_NOT_INSTALLED;
      case Constants.TOOLTIP_TYPE__UPDATE_S2S_PACKAGE:
        return <span dangerouslySetInnerHTML={{ __html: Constants.UPDATE_S2S_PACKAGE }} />;
      case Constants.TOOLTIP_TYPE__PREDEFINED_FILTER_SET_SOURCE:
        return 'You can choose to create a Filter Set using any available Data Set or an individual Data Extension.';
      case Constants.TOOLTIP_TYPE__TEMPORARY_DATA_EXTENSION_RETENTION_POLICY:
        return 'You can set a retention policy for the Temporary Data Extensions created by DESelect.';
      case Constants.TOOLTIP_TYPE__NEW_DATA_EXTENSION_RETENTION_POLICY:
        return 'You can set a retention policy for the New Data Extensions created by you.';
      case Constants.TOOLTIP_TYPE__GLOBAL_CUSTOM_VALUE__INFO:
        return 'Please note that only the Fixed Value and Timestamp Custom Values will be visible inside ' +
        'all Selections. All other Custom Values will only be shown when the corresponding Data Extension is added ' +
        'in the selection.';
      case Constants.FEATURE__GLOBAL_CUSTOM_VALUES:
        return 'As an admin, you can create predefined custom values from the Admin Panel.';
      case Constants.TOOLTIP_TYPE__CHANGE_LOCATION_TO_SHARED_FOLDER:
        return 'Changing the type of a Data Extension from a Child Business Unit is currently not supported.';
      default:
        return content;
    }
  };

  // Wrap the tooltip content in a div to handle mouse events
  const tooltipContent = (
    <div>
      {getText()}
    </div>
  );

  return (
    <div className="des-tooltip">
      <SLDSTooltip
        {...restProps}
        id={id}
        align={align}
        content={tooltipContent}
        dialogClassName={dialogClassName}
        hoverCloseDelay={hoverCloseDelay}
      >
        {children}
      </SLDSTooltip>
    </div>
  );
};

/**
 * PropTypes definition for type checking and documentation
 * For SLDS Tooltip props https://design-system-react-site.herokuapp.com/components/tooltips/#property-details
 */
Tooltip.propTypes = {
  /**
   * Alignment of the Tooltip relative to the element that triggers it.
   */
  align: PropTypes.oneOf([
    'top',
    'top left',
    'top right',
    'right',
    'right top',
    'right bottom',
    'bottom',
    'bottom left',
    'bottom right',
    'left',
    'left top',
    'left bottom',
  ]),

  /**
   * Assistive text for accessibility.
   * This object is merged with the default props object on every render.
   * - tooltipTipLearnMoreIcon: This text is inside the info icon within the tooltip
   * content and exists to "complete the sentence" for assistive tech users.
   * - triggerLearnMoreIcon: This text is inside the info icon that triggers the tooltip in
   *  order to have text within the link.
   */
  assistiveText: PropTypes.shape({
    tooltipTipLearnMoreIcon: PropTypes.string,
    triggerLearnMoreIcon: PropTypes.string,
  }),

  /**
   * Pass the one element that triggers the Tooltip as a child.
   * It must be an element with tabIndex or an element that already has a tabIndex set such as an
   * anchor or a button, so that keyboard users can tab to it.
   */
  children: PropTypes.node,

  /**
   * Content inside Tooltip.
   */
  content: PropTypes.node,

  /**
   * CSS classes to be added to the popover dialog. That is the element with .slds-popover on it.
   */
  dialogClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),

  /**
   * Delay on Tooltip closing in milliseconds. Defaults to 50.
   */
  hoverCloseDelay: PropTypes.number,

  /**
   * Delay on Tooltip opening in milliseconds. Defaults to 0.
   */
  hoverOpenDelay: PropTypes.number,

  /**
   * A unique ID is needed in order to support keyboard navigation, ARIA support,
   * and connect the popover to the triggering element.
   */
  id: PropTypes.string,

  /**
   * Forces tooltip to be open. A value of false will disable any interaction with the tooltip.
   */
  isOpen: PropTypes.bool,

  /**
   * Determines the position of the Tooltip relative to the triggering element.
   */
  position: PropTypes.oneOf(['absolute', 'overflowBoundaryElement', 'relative']),

  /**
   * Determines the theme of tooltip: for informative purpose (blue background)
   * or warning purpose (red background).
   */
  theme: PropTypes.oneOf(['info', 'error']),

  /**
   * Determines the type of the tooltip.
   */
  type: PropTypes.oneOf([
    Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
    Constants.TOOLTIP_TYPE__RELATION_MODAL,
    Constants.TOOLTIP_TYPE__DISABLED_AUTO_REFRESH,
    Constants.TOOLTIP_TYPE__UTC,
    Constants.TOOLTIP_TYPE__DELETE_SELECTION,
    Constants.TOOLTIP_TYPE__RECOMMENDED_FILTER_MODE,
    Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_NOW,
    Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_TODAY,
    Constants.TOOLTIP_TYPE__TEMPLATE_SELECTION,
    Constants.TOOLTIP_TYPE__CONVERT_FROM_TIMEZONE,
    Constants.TOOLTIP_TYPE__PREDEFINED_DATA_EXTENSIONS,
    Constants.TOOLTIP_TYPE__S2S_PACKAGE_NOT_INSTALLED,
    Constants.TOOLTIP_TYPE__UPDATE_S2S_PACKAGE,
    Constants.TOOLTIP_TYPE__PREDEFINED_FILTER_SET_SOURCE,
    Constants.TOOLTIP_TYPE__TEMPORARY_DATA_EXTENSION_RETENTION_POLICY,
    Constants.TOOLTIP_TYPE__NEW_DATA_EXTENSION_RETENTION_POLICY,
    Constants.TOOLTIP_TYPE__GLOBAL_CUSTOM_VALUE__INFO,
    Constants.TOOLTIP_TYPE__CHANGE_LOCATION_TO_SHARED_FOLDER,
  ]),

  /**
   * Determines the variant of the tooltip.
   */
  variant: PropTypes.oneOf(['base', 'learnMore', 'list-item']),
};

// Default props for the Tooltip component
Tooltip.defaultProps = {
  align: 'top',
  hoverCloseDelay: 1500, // 1.5 second
  hoverOpenDelay: 0,
  position: 'absolute',
  theme: 'info',
  variant: 'base',
  type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
};

export default Tooltip;
