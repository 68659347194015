import React from 'react';
import ReactDOM from 'react-dom';
import { IconSettings } from '@salesforce/design-system-react';
import { Provider } from 'react-redux';

import store from './redux/store/store';
import './index.css';
// import * as serviceWorker from "./serviceWorker";
import { unregister } from './serviceWorker';
import Root from './Root';
import App from './App';
import ErrorBoundary from './ErrorBoundary';

unregister();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Root>
        <IconSettings iconPath="/assets/icons">
          <App />
        </IconSettings>
      </Root>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);

/*
 * If you want your app to work offline and load faster, you can change=
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: http://bit.ly/CRA-PWA
 * serviceWorker.unregister();
 */
