/* eslint-disable no-negated-condition */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import SwalUtil from '../../../../utils/swal/swalUtil';
import DataExtensionsAPI from '../../../../api/data-extensions';
import Tooltip from '../../../shared_v2/Tooltip/Tooltip';
import Spinner from '../../../shared_v2/Spinner/Spinner';
import LoadingModal from '../../../shared_v2/LoadingModal/LoadingModal';
import FolderDataExtensions from '../../DataExtensions/AvailableExtensions/FolderDataExtensions/FolderDataExtensions';
import GuidanceTip from '../../../shared_v2/GuidanceTip/GuidanceTip';
import SettingsDropdown from '../SelectedTargetFields/SettingsDropdown/SettingsDropdown';
import './styles.scss';
import Button from '../../../shared_v2/Button/Button';
import NewAutoCreatedTargetDE from '../NewAutoCreatedTargetDE/NewAutoCreatedTargetDE';
import Tip from '../../../shared_v2/Tip/Tip';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
class TargetDE extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clickedRefreshTargetDEButton: false,
      newDELoading: false,
      targetDEsFolderId: props.targetDEsFolderId || null,
      showTargetDEsFoldersModal: false,
      showTargetDEsForFolder: false,
      foldersPath: [],
      targetDEsForFolder: [],
      areCreateNewDEDataRetentionPolicySettingsChanged: false,
    };
  }

  /**
   * Choose target DE's id
   * Request api
   * Set field state of target DE
   * @param {object} event - event
   * @returns {void}
   */
  handleChangeTargetDataExtension = async (event) => {
    const {
      handleSetSelectionState,
      axiosCancelToken,
      handleSetAppState,
      unionSelections,
      selectionType,
      selectedDataExtensions,
      matchedFields,
      targetDataExtensionCustomerKey,
      dataAction,
      targetDataExtensions,
    } = this.props;

    // if there are mapped fields and targetDE throw a warning message
    if (matchedFields && matchedFields.length > 0 && targetDataExtensionCustomerKey) {
      const swalResult = await SwalUtil.fire({
        title: 'Change Target Data Extension',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to change the Target Data Extension? You will lose ${matchedFields.length > 1 ? 'all mapped fields' : 'the mapped field'}.`,
        options: {
          showCancelButton: true,
          confirmButtonText: 'Change',
        },
      });
      // if user chooses cancel then don't change the target de

      if (!swalResult.value) {
        return;
      }
    }

    if (selectionType === Constants.SELECTION__TYPE__UNION) {
      // reset the fields for each unionSelection
      unionSelections.forEach((selection) => {
        /* eslint-disable no-param-reassign */
        selection.targetDataExtensionFields = [];
        selection.matchedFields = [];
        /* eslint-enable no-param-reassign */
      });
      handleSetAppState({ unionSelections });
    }

    // get data of selected target data extension
    const targetDataExtension = targetDataExtensions.find(dataExtension => (
      dataExtension?.ObjectID?.toString() === event.value));

    handleSetSelectionState({
      targetDataExtensionFields: [],
      matchedFields: [],
      targetDataExtensionCustomerKey: targetDataExtension?.CustomerKey || '',
      targetCollectionObjectID: event.value,
    });

    if (event.value !== '') {
      try {
        // fetch targetDE data with fields
        const [collection] = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken,
          [{
            collectionCustomerKey: targetDataExtension?.CustomerKey || '',
            collectionObjectID: event.value.toString(),
            type: Constants.DESELECT__DATA_EXTENSION__TYPE__TARGET,
          }],
          null,
        );

        // define fields for targetDE
        const collectionFields = collection?.fields || [];

        /*
         * Update selectedDataExtensions fields with the fields from collection
         * Available fields will automatically be updated
         */
        if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          selectedDataExtensions.forEach((de) => {
            if (de?.ObjectID?.toString() === event.value) {
              // eslint-disable-next-line no-param-reassign
              de.fields = collectionFields;
            }
          });
        }

        handleSetSelectionState({
          // set targetDataExtensionFields
          targetDataExtensionFields: collectionFields,

          // set targetDE data without fields
          targetDataExtension: _.omit(collection, 'fields'),

          // restart priodedup settings
          prioDeduplication: {
            multipleSortingOptionLines: [],
            mode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
            type: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
            deduplicationFieldObjectID: '',
            criteriaFieldObjectID: '',
            priorities: [],
            sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
          },
          usePrioDeduplication: false,
          previousAdvancedDeduplicationRules: [],
          advancedDeduplicationRules: [],
          advancedDedupFilterSaveIndex: null,

          // reset sort and limit when target DE is changed
          sortLimit: {
            enabled: false,
            select: {
              type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
              value: 0,
            },
            orderBy: {
              type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
              fieldObjectID: '',
              sort: '',
            },
          },

          /*
           * if there are no fields with primary key and data action is update then set
           * data action to overwrite.
           */
          dataAction: this.checkIfActionTypeShouldBeChanged(collectionFields) ?
            Constants.DATA_ACTION__OVERWRITE :
            dataAction,
        });

        if (selectionType === Constants.SELECTION__TYPE__UNION) {
          unionSelections.forEach((selection) => {
            // eslint-disable-next-line no-param-reassign
            selection.targetDataExtensionFields = collectionFields;
          });

          handleSetAppState({ unionSelections });
        }
      } catch (error) {
        handleSetSelectionState({ error });
      }
    }
  };

  /**
   * Helps to return the appropriate tooltip for the default send relationship settings
   * @returns {JSX.Element | null} - Tooltip component or null depending on the validity of the settings
   */
  showDefaultSendRelationshipSettings = () => {
    const { defaultSendRelationshipField } = this.props;

    const { isEnabled, fieldName, defaultRelation } = defaultSendRelationshipField || {};

    if (isEnabled && fieldName && defaultRelation) {
      const tooltipText = 'A default send relationship has been specified by your admin where ' +
        `"Send Relationship ${fieldName} relates to Subscriber on ${defaultRelation}".`;

      return (
        <div className="default-send-relationship-tooltip-container">
          <Tooltip
            nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
            tooltipText={tooltipText}
          />
        </div>);
    }

    return null;
  };

  /**
   * Refresh and get all DE from SFMC
   * @param {boolean} clickedRefreshTargetDEsButton - true/false
   * @returns {void}
   */
  handleRefreshTargetDataExtensionList = async (
    clickedRefreshTargetDEsButton,
  ) => {
    const {
      targetCollectionObjectID,
      axiosCancelToken,
      matchedFields,
      targetDataExtensionFields,
      handleSetSelectionState,
      selectedDataExtensions,
      targetDataExtension,
      loadingForTargetDataExtensions,
    } = this.props;

    /*
     * refreshButton indicates refresh button in the target definition page. reset all the
     * states about target definition page if this button is clicked.
     */
    if (clickedRefreshTargetDEsButton) {
      this.setState({ clickedRefreshTargetDEButton: true });
    }

    const targetDataExtensions = await DataExtensionsAPI.getDataExtensions(
      axiosCancelToken,
      Constants.DATAEXTENSION__FILTER_MODE__TARGET,
    );

    // checks all the fields of updated target DE and detach matched field if the field has deleted or changed
    const updateTargetDataExtensionFields = (_matchedFields) => {
      const checkAndUpdateFields = _matchedFields.map(
        field => targetDataExtensionFields.filter(
          tdFilter => field?.targetDataExtensionFieldName?.toString() === tdFilter?.Name?.toString(),
        )[0],
      );

      checkAndUpdateFields.forEach((value, i) => value ? null : _matchedFields.splice(i, 1));
    };

    const { unionSelections, handleSetAppState, selectionType } = this.props;

    // if refresh button is clicked
    if (clickedRefreshTargetDEsButton) {
      const selectedTargetDataExtension = targetDataExtensions.find(
        de => de?.ObjectID?.toString() === targetCollectionObjectID?.toString(),
      );

      // check if target DE still exists in the list
      if (selectedTargetDataExtension) {
        const [dataExtension] = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken,
          [{
            collectionCustomerKey: selectedTargetDataExtension?.CustomerKey,
            collectionObjectID: targetDataExtension?.ObjectID || '',
            type: Constants.DESELECT__DATA_EXTENSION__TYPE__TARGET,
          }],
          null,
        );

        const dataExtensionFields = dataExtension?.fields || [];

        // if there are selectedDataExtensions update fields and name for each selected DE
        if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          for (let i = 0; i < selectedDataExtensions.length; i += 1) {
            for (let j = 0; j < targetDataExtensions.length; j += 1) {
              if (
                targetDataExtensions[j].ObjectID ===
                selectedDataExtensions[i].ObjectID
              ) {
                // update selectedDataExtensions fields and Available fields will automatically be updated
                // eslint-disable-next-line no-await-in-loop
                const updatedFields = await DataExtensionsAPI.getDataExtensionFields(
                  targetDataExtensions[j].CustomerKey,
                  axiosCancelToken,
                );

                selectedDataExtensions[i].fields = updatedFields.data;
                // update selected DE name
                selectedDataExtensions[i].Name = targetDataExtensions[j].Name.toString();
              }
            }
          }
        }

        handleSetSelectionState(
          {
            targetDataExtensionFields: dataExtensionFields,
            targetDataExtension: _.omit(dataExtension, 'fields') || {},
            targetDataExtensionCustomerKey: dataExtension?.CustomerKey?.toString(),
          },
          // check if any existing field has been changed, and update
          () => {
            updateTargetDataExtensionFields(matchedFields);
            handleSetSelectionState({ matchedFields });
          },
        );

        if (selectionType === Constants.SELECTION__TYPE__UNION) {
          const { unionSelections: unionSelectionsArray } = this.props;

          unionSelectionsArray.forEach((selection) => {
            // eslint-disable-next-line no-param-reassign
            selection.targetDataExtensionFields = dataExtensionFields;
          });

          handleSetAppState({ unionSelections: unionSelectionsArray }, () => {
            const { unionSelections: uSelections } = this.props;

            uSelections.map(selection => updateTargetDataExtensionFields(selection.matchedFields));

            handleSetAppState({ unionSelections: uSelections });
          });
        }
      } else {
        if (targetDataExtensions?.length && !loadingForTargetDataExtensions) {
          // if selectedTargetDataExtension is not found and there are target DEs to choose, then show error message.
          SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            // eslint-disable-next-line max-len
            message: 'This target data extension is not available anymore. Please choose another extension.',
          });
        }

        handleSetSelectionState({
          targetDataExtensionCustomerKey: '',
          targetCollectionObjectID: '',
          targetDataExtensionFields: [],
          matchedFields: [],
        });

        if (selectionType === Constants.SELECTION__TYPE__UNION) {
          /*
           * this and rest of the forEach object assigns and setStates
           * look ugly, but could not find a way to create a function
           * because they all different from each other. Any suggestions would be appreciated.
           */
          unionSelections.forEach((selection) => {
            /* eslint-disable no-param-reassign */
            selection.targetDataExtensionCustomerKey = '';
            selection.targetCollectionObjectID = '';
            selection.targetDataExtensionFields = [];
            selection.matchedFields = [];
            /* eslint-enable no-param-reassign */
          });

          handleSetAppState({ unionSelections });
        }
      }

      this.setState({ clickedRefreshTargetDEButton: false });
    }
    handleSetSelectionState({
      targetDataExtensions,
      isDataExtensionRequestDone: true,
    });

    if (selectionType === Constants.SELECTION__TYPE__UNION) {
      unionSelections.forEach((selection) => {
        /* eslint-disable no-param-reassign */
        selection.dataExtensions = targetDataExtensions;
        selection.isDataExtensionRequestDone = true;
        /* eslint-enable no-param-reassign */
      });
      handleSetAppState({ unionSelections });
    }

    return targetDataExtensions;
  };

  /**
   * get Target Data Extension link
   * @returns {string} - returns the TDE link
   */
  getTDELink = () => {
    const { targetDataExtension, orgInfo } = this.props;

    const domain = `https://mc.${orgInfo?.marketingCloudStackNumber}.exacttarget.com`;

    const link = domain + `/contactsmeta/admin.html#admin/data-extension/${targetDataExtension?.ObjectID}`;

    return link;
  };

  /**
   * Defines whether the data action should be changed because there is no primary key field in the array
   * @param {array} fields - array with targetDE fields
   * @returns {boolean} - true, false depending on whether the data action should be changed
   */
  checkIfActionTypeShouldBeChanged = (fields) => {
    const { dataAction } = this.props;

    // check if there is at least one field with Primary Key
    const primaryKeyExists = fields.filter(
      field => field.IsPrimaryKey === true,
    );

    // check if update action type should be disabled
    const disableUpdate = primaryKeyExists && primaryKeyExists.length === 0;

    // if data action is set to update and is disabled, return true
    if (disableUpdate && dataAction === Constants.DATA_ACTION__UPDATE) return true;

    // otherwise return false
    return false;
  };

  /**
   * function helps to open the NewAutoCreatedTargetDE modal
   * and sets the current matchedFields to prevMatchedFields so
   * matchedFields can be restored later if it`s needed
   * @returns {void}
   */
  handleShowNewTargetDEModal = () => {
    const { matchedFields, handleSetSelectionState } = this.props;

    handleSetSelectionState({
      createNewAutoTargetDE: true,
      prevMatchedFields: matchedFields,
      matchedFields: [],
    });
  };

  /**
   * Abbreviate a Data Extension
   * @returns {string} - abbreviated string
   */
  abbreviateDE = () => {
    const { targetDataExtensionCustomerKey, targetDataExtensions, newTargetDataExtension } = this.props;

    let strToAbbreviate = null;

    if (newTargetDataExtension?.name) strToAbbreviate = newTargetDataExtension.name;
    else if (targetDataExtensions?.length) {
      strToAbbreviate = Util.getDataExtensionName(targetDataExtensions, targetDataExtensionCustomerKey);
    }

    return Util.abbreviate(strToAbbreviate, 35);
  };

  /**
   * Generates HTML for the Edit DE button depending on edit status
   * @param {boolean} editNewAutoTargetDE - is the value of ac/de creation status
   * @param {boolean} editTargetDataExtension
   * => state from Selection for going in or out the edit target de mode
   * @returns {object} HTML for the Edit DE Button
   */
  generateHTMLForEditButton = (editNewAutoTargetDE, editTargetDataExtension) => {
    const { handleSetSelectionState } = this.props;

    if (editNewAutoTargetDE || editTargetDataExtension) {
      return (
        <Button
          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          onClick={() => handleSetSelectionState({ createNewAutoTargetDE: true })}
          title="Edit the settings of this Target Data Extension"
        >
          <svg className="slds-icon slds-icon_x-small data-extension-settings-button" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#custom_apps" />
          </svg>
          &nbsp;Data Extension Settings
        </Button>
      );
    }

    return null;
  };

  /**
   * Opens Data extensions modal for a certain folder
   * @param {number} folderId - id of the selected folder
   * @param {array} foldersPath - path to the selected folder
   * @returns {void}
   */
  openTargetDEsForCertainFolderModal = (folderId, foldersPath) => {
    const { targetDataExtensions } = this.props;

    // find data extensions by CategoryID for the given folderId
    const targetDEsForFolder = targetDataExtensions.filter(de => de.CategoryID === folderId);

    this.setState({
      targetDEsForFolder, showTargetDEsForFolder: true, targetDEsFolderId: folderId, foldersPath,
    });
  };

  /**
   * General function to handle the state of the Target DE component
   * @param {object} newState - State to be set
   * @returns {void}
   */
  handleSetTargetDEState = (newState) => {
    this.setState(newState);
  };

  /**
   * Function which helps to open data action modal
   * @returns {void}
   */
  handleOpenDataActionModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showDataActionModal: true });
  };

  handleOpenSourceLimitingModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showSourceLimitingModal: true });
  };

  /**
   * Function which helps to open sort limit modal
   * @returns {void}
   */
  handleOpenSortLimitModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showSortLimitModal: true });
  };

  /**
   * Go in Edit Target Data Extension mode
   * Save previous mathed fields and target DE fields
   * @returns {void}
   */
  handleOpenEditTargetDE = () => {
    const {
      handleSetSelectionState, targetDataExtension,
      targetDataExtensionFields, matchedFields, handleChangeSendableAndSubscriberField,
      addSubscriberOption, newTargetDataExtension,
    } = this.props;
    /**
     * Make copies for target de fields and save them in prevTargetDEFields
     * which will contain those fields before we entered in edit target de mode
     * So if we press cancel we will have older fields
     */
    const prevTargetDataExtensionFields = JSON.parse(JSON.stringify(targetDataExtensionFields));
    const prevMatchedFields = JSON.parse(JSON.stringify(matchedFields));

    let subscriberFieldName = '';

    let sendableFieldName = targetDataExtension?.SendableDataExtensionField?.Name || '';

    let sendableFieldType = targetDataExtension?.SendableDataExtensionField?.FieldType || '';

    // if the SendableSubscriberField Name ends with the Key word
    if (targetDataExtension?.SendableSubscriberField?.Name.endsWith('Key')) {
      // assign 'Subscriber Key' name
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY;
    } else if (targetDataExtension?.SendableSubscriberField?.Name.endsWith('Id') ||
      targetDataExtension?.SendableSubscriberField?.Name.endsWith('ID')) {
      // if the SendableSubscriberField ends with the Id or ID word, assign 'Subscriber Id' name
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID;
    } else if (targetDataExtension?.SendableSubscriberField?.Name.endsWith('Address')) {
      // if the SendableSubscriberField ends with the Address word, assign 'Email Address' name
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__EMAILADDRESS;
    } else if (!targetDataExtension?.SendableSubscriberField && !targetDataExtension?.SendableDataExtensionField) {
      // if there is no SendableSubscriberField, then set the first field in SendableSubscriberField object
      sendableFieldName = targetDataExtensionFields[0].Name;
      sendableFieldType = targetDataExtensionFields[0].FieldType;
      subscriberFieldName = handleChangeSendableAndSubscriberField(targetDataExtensionFields[0], true);
    }

    // add options in Send Relationship dropdown
    addSubscriberOption(targetDataExtensionFields);

    // defines whether the data in targetDataExtension corresponds to an enabled Data Retention Policy
    const isDataRetentionPolicyOn = (targetDataExtension?.RowBasedRetention ||
      targetDataExtension.hasOwnProperty('DeleteAtEndOfRetentionPeriod')) &&
      (targetDataExtension?.DataRetentionPeriodLength &&
        targetDataExtension?.DataRetentionPeriod ||
        targetDataExtension?.RetainUntil && targetDataExtension?.RetainUntil !== '');

    /*
     * transform the retrieved data for the Target Data Extension into the appropriate data displayed
     * in DataRetentionPolicy
     */
    const editedTargetDataExtension = {
      ...newTargetDataExtension,
      name: targetDataExtension.Name,
      folderId: targetDataExtension.CategoryID,
      description: targetDataExtension.Description || '',
      dataRetentionPolicy: {
        ...newTargetDataExtension.dataRetentionPolicy,
        dataRetentionPolicy: !!isDataRetentionPolicyOn,
        toggle: !!isDataRetentionPolicyOn,
        allRecords: !!(targetDataExtension?.DeleteAtEndOfRetentionPeriod && !targetDataExtension?.RowBasedRetention &&
          isDataRetentionPolicyOn),
        individualRecords: !!(targetDataExtension?.RowBasedRetention && isDataRetentionPolicyOn),
        dataRetentionPeriod: targetDataExtension?.DataRetentionPeriod || Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
        dataRetentionPeriodLength: targetDataExtension?.DataRetentionPeriodLength || false,
        periodAfter: !!(targetDataExtension?.DataRetentionPeriod &&
          targetDataExtension?.DataRetentionPeriodLength),
        allRecordsAndDE: !!(isDataRetentionPolicyOn &&
          !targetDataExtension?.DeleteAtEndOfRetentionPeriod &&
          !targetDataExtension?.RowBasedRetention),
        resetRetentionPeriodOnImport: targetDataExtension?.ResetRetentionPeriodOnImport || false,
        dataExtensionPeriodDate: !targetDataExtension?.DataRetentionPeriod &&
          !targetDataExtension?.DataRetentionPeriodLength ?
          targetDataExtension?.RetainUntil :
          false,
        periodOn: !!(targetDataExtension?.RetainUntil && !targetDataExtension?.DataRetentionPeriod &&
          !targetDataExtension?.DataRetentionPeriodLength),
        periodOnDisabled: false,
      },
      relationship: {
        ...newTargetDataExtension.relationship,
        isSendable: targetDataExtension.IsSendable || false,
        isTestable: targetDataExtension.IsTestable || false,
        sendableDataExtensionField: {
          name: sendableFieldName,
          type: sendableFieldType,
        },
        sendableSubscriberField: {
          name: subscriberFieldName,
        },
      },
    };

    handleSetSelectionState({
      editTargetDataExtension: true,
      prevMatchedFields,
      prevTargetDEFields: prevTargetDataExtensionFields,
      openSettings: false,
      newTargetDataExtension: editedTargetDataExtension,
    });
  };

  /**
   * Generates HTML for the DEs (select target, create or auto create)
   * @param {boolean} createNewAutoTargetDE - true if user clicked on "Create Data Extension" button and open
   * a modal to input info about new DE
   * @param {boolean} editNewAutoTargetDE - is the value of ac/de creation status
   * @param {object} newTargetDataExtension - is the New Auto Create Data Extension
   * @param {array} targetDataExtensions - is the target data extensions after they are retrieved from SFMC
   * @param {array} targetDeOptions - are the options for the target DE
   * @param {boolean} editTargetDataExtension
   * => is the state from Selection for going in or out the edit target de mode
   * @param {array} targetDataExtensionFields - are the fields of an existing target data extension
   * @param {string} targetCollectionObjectID
   * => is the ObjectID of a selected existing target data extension
   * @param {boolean} clickedRefreshTargetDEButton - true if user clicked on the refresh DE Button
   * @param {array} prevMatchedFields - are the fields matched in Target Definition screen so we don't lose them
   * @param {array} matchedFields - are the matchedFields for a target data extension of the Selection
   * @param {function} handleSetSelectionState - is the function to helps to set the Selection's state
   * @param {string} selectionType - is the type of selection (Union or Simple)
   * @param {string} unionType - is the type of the union type of selections (union or union all)
   * @param {string} selectionName - is the name of the selection
   * @param {string} targetDataExtensionCustomerKey - is the CustomerKey of the target data extension
   * @returns {object} HTML for the Data Extension
   */
  generateHTMLForDE = (
    createNewAutoTargetDE,
    editNewAutoTargetDE,
    newTargetDataExtension,
    targetDataExtensions,
    targetDeOptions,
    editTargetDataExtension,
    targetDataExtensionFields,
    targetCollectionObjectID,
    clickedRefreshTargetDEButton,
    prevMatchedFields,
    matchedFields,
    handleSetSelectionState,
    // eslint-disable-next-line no-unused-vars
    selectionType,
    // eslint-disable-next-line no-unused-vars
    unionType,
    selectionName,
    targetDataExtensionCustomerKey,
  ) => {
    const {
      loadingForTargetDataExtensions,
      targetDataExtension, defaultSendRelationshipField, openSettings, featureAutoCreateTargetDEIsEnabled,
    } = this.props;

    const { areCreateNewDEDataRetentionPolicySettingsChanged } = this.state;

    if (!createNewAutoTargetDE && !editNewAutoTargetDE && !editTargetDataExtension && !targetDataExtensionCustomerKey) {
      const { isArchived, userInfo } = this.props;
      const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

      return (
        <>
          {' '}
          <div className="target-de-title">
            <p className="des-lib-target-de-title-2">Target Data Extension</p>
            <div className="des-lib-target-de-text">
              <Tip
                title="Tip:"
                description="The Target Data Extension is the Data Extension in which the results
                             of your Selection will be stored. This Data Extension can then be used
                             in a Journey, in Email Studio,... You can create a new Data Extension
                             or choose an existing one."
                mode="dark"
              />
            </div>
          </div>
          <div className="des-lib-target-de-create-container-v2">
            <p className="small-title">Create a new Data Extension</p>
            <div className={'select-TDE-wrapper' +
            (targetDataExtensionFields && targetDataExtensionFields.length > 0 ?
              ' edit-target-de-field-wrapper' :
              '')}
          >
            {!editNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled ?
              (
                <>
                <Button
                  buttonLook={Constants.BUTTON__TYPE__BRAND}
                  className="create-new-data-extension-button"
                  onClick={() => this.handleShowNewTargetDEModal()}
                  disabled={isArchived || hasReadOnlyAccess}
                >
                  Create Data Extension
                </Button>
                <GuidanceTip tipId="create-dataextension-tip" />
                </>
              ) :
              null}
            </div>
            <div className="des-lib-target-de-or-container">
              <div className="line" />
              <div className="text">OR</div>
              <div className="line" />
            </div>
            <p className="small-title">Select an existing Target Data Extension</p>
            <div className={'select-TDE-wrapper' +
              (targetDataExtensionFields && targetDataExtensionFields.length > 0 ?
                ' edit-target-de-field-wrapper' :
                '')}
            >
              {(loadingForTargetDataExtensions || clickedRefreshTargetDEButton) ?
                (
                  <div className="targetDE-loader-container">
                    <Dropdown
                      id="target-DE-dropdown"
                      selection
                      className="target-data-extension-dropdown target-de-loading-dropdown"
                      loading
                      style={{ borderRadius: '.25rem' }}
                      placeholder="Loading Target Data Extensions..."
                      disabled
                      options={targetDeOptions}
                    />
                    <Spinner size={Constants.SPINNER__SIZE__X_SMALL} />
                  </div>
                ) :
                (
                  <Dropdown
                    id="target-DE-dropdown"
                    selection
                    className="target-data-extension-dropdown"
                    search
                    placeholder={
                      targetDataExtensions?.length ?
                        'Select a Target DE' :
                        'There are no Target Data Extensions available'
                    }
                    disabled={!targetDataExtensions?.length}
                    options={targetDeOptions}
                    value={
                      targetDataExtensions?.length ?
                        targetCollectionObjectID?.toString() :
                        ''
                    }
                    onChange={(e, data) => this.handleChangeTargetDataExtension(data)}
                    loading
                    style={{ borderRadius: '.25rem' }}
                  />
                )}

              {targetCollectionObjectID !== '' &&
                (
                  <a href={this.getTDELink()} target="__blank">
                    <Button
                      className="target-folders-funnel-icon"
                      title="Link to TDE"
                      onClick={() => { }}
                      buttonIcon
                      buttonIconBorderFilled
                      noSpan
                    >
                      <svg className="slds-icon slds-icon-text-default slds-icon_x-small status-error">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#new_window" />
                      </svg>
                    </Button>
                  </a>
                )}

              <Button
                className={`target-folders-funnel-icon ${!targetDataExtensions?.length ? 'button-disabled' : ''}`}
                title="Filter Target Data Extensions by Folders"
                onClick={() => this.setState({ showTargetDEsFoldersModal: true })}
                buttonIcon
                buttonIconBorderFilled
                noSpan
                disabled={!targetDataExtensions?.length}
              >
                <svg
                className={`slds-icon slds-icon-text-default slds-icon_x-small
                  ${!targetDataExtensions?.length ?
                  'icon-disabled' :
                  ''}`}
                  aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#open_folder" />
                </svg>
              </Button>

              <Button
                buttonIconBorderFilled
                buttonIcon
                className="refresh-button"
                id="refresh-button"
                onClick={() => this.handleRefreshTargetDataExtensionList(true)}
                title="Refresh target data extension list"
                disabled={!targetDataExtensions?.length}
                noSpan
              >
                <svg
                  aria-hidden="true"
                  className={`slds-button__icon ${clickedRefreshTargetDEButton ||
                    loadingForTargetDataExtensions ?
                    'fa-spin' :
                    ''
                  }`}
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
                </svg>
              </Button>
            </div>
          </div>
          {targetCollectionObjectID !== '' &&
            <div className="settings-actions" style={{ display: 'none' }}>
              <SettingsDropdown
                handleSetSelectionState={handleSetSelectionState}
                openSettings={openSettings}
                handleOpenDataActionModal={this.handleOpenDataActionModal}
                handleOpenEditTargetDE={this.handleOpenEditTargetDE}
                handleOpenSortLimitModal={this.handleOpenSortLimitModal}
                handleOpenSourceLimitingModal={this.handleOpenSourceLimitingModal}
                disableSettingsDropdown={!!(!targetDataExtensions || targetDataExtensions.length <= 0)}
              />
            </div>}
        </>
      );
    }

    if (createNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled === true) {
      return (
        <NewAutoCreatedTargetDE
          defaultSendRelationshipField={defaultSendRelationshipField}
          newTargetDataExtension={newTargetDataExtension}
          handleSetSelectionState={handleSetSelectionState}
          createNewAutoTargetDE={createNewAutoTargetDE}
          isChangeLocation
          editNewAutoTargetDE={editNewAutoTargetDE}
          prevMatchedFields={prevMatchedFields}
          matchedFields={matchedFields}
          targetDataExtensions={targetDataExtensions}
          targetDataExtensionFields={targetDataExtensionFields}
          selectCopyTargetDE
          editTargetDataExtension={editTargetDataExtension}
          targetDataExtension={targetDataExtension}
          selectionName={selectionName}
          areCreateNewDEDataRetentionPolicySettingsChanged={areCreateNewDEDataRetentionPolicySettingsChanged}
          handleSetTargetDEState={this.handleSetTargetDEState}
        />
      );
    }

    return null;
  };

  render() {
    const {
      clickedRefreshTargetDEButton,
      newDELoading,
      showTargetDEsFoldersModal,
      targetDEsFolderId,
      showTargetDEsForFolder,
      targetDEsForFolder,
      foldersPath,
    } = this.state;

    const {
      // eslint-disable-next-line no-unused-vars
      unionType,
      selectionType,
      newTargetDataExtension,
      handleSetSelectionState,
      createNewAutoTargetDE,
      editNewAutoTargetDE,
      prevMatchedFields,
      matchedFields,
      targetDataExtensionCustomerKey,
      targetDataExtensions,
      editTargetDataExtension,
      targetDataExtensionFields,
      targetCollectionObjectID,
      newTargetDataExtensionFields,
      subscriberFieldNames,
      handleChangeSendableAndSubscriberField,
      foldersSettings,
      targetDEsFolders,
      featureAutoCreateTargetDEIsEnabled,
      selectionName,
      defaultSendRelationshipField,
    } = this.props;
    /*
     * formatting dataExtensions for dropdown and removing data views from it
     * for that dataextensions array is filtered if de name (text in dropdown)
     * doesn't start with _ (underscore)
     */
    const targetDeOptions = Util.formattedDataForTheDropdown(targetDataExtensions, 'ObjectID', 'Name')
      .filter(de => (de.text || de.text.toString()) ? de.text[0].toString() !== '_' : false);

    const editedFields = editNewAutoTargetDE ?
      [...newTargetDataExtensionFields] :
      [...targetDataExtensionFields];

    let sendableDataExtensionField = '';

    // If there is at least one new target de field
    if (editedFields?.length > 0) {
      /**
       * update picked option for sendable field name
       * check if sendable field name matches with new target de field name
       * if so then set sendableDataExtensionField to that new target de field
       */
      const matchingFieldNames = editedFields.find((field) => {
        return newTargetDataExtension?.relationship?.sendableDataExtensionField?.name ===
          field.Name.toString();
      });

      if (matchingFieldNames) {
        sendableDataExtensionField = JSON.stringify(matchingFieldNames);
      }
    }

    const classNameForRelationDiv = classNames(
      'relation-div ',
      newTargetDataExtension?.relationship?.isSendable &&
        subscriberFieldNames?.length ?
        '' :
        'disable-div',
    );

    return (
      <div
        className="target-de-container-v2"
        style={{
          justifyContent:
            targetDataExtensionCustomerKey && selectionType === Constants.SELECTION__TYPE__UNION ?
              'flex-end' :
              'center',
        }}
      >
        <div className="target-de-main">
          {
              ((editNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled === true) ||
            editTargetDataExtension) && !createNewAutoTargetDE ?
                (
                  <>
                    <h4>
                      {this.abbreviateDE()}
                    </h4>
                    <div className="create-new-de-user-actions">
                      {this.generateHTMLForEditButton(editNewAutoTargetDE, editTargetDataExtension)}
                    </div>
                  </>
                ) :
                null
            }
          {this.generateHTMLForDE(
            createNewAutoTargetDE,
            editNewAutoTargetDE,
            newTargetDataExtension,
            targetDataExtensions,
            targetDeOptions,
            editTargetDataExtension,
            targetDataExtensionFields,
            targetCollectionObjectID,
            clickedRefreshTargetDEButton,
            prevMatchedFields,
            matchedFields,
            handleSetSelectionState,
            selectionType,
            unionType,
            selectionName,
            targetDataExtensionCustomerKey,
          )}
          {showTargetDEsFoldersModal && (
            <NewAutoCreatedTargetDE
              defaultSendRelationshipField={defaultSendRelationshipField}
              handleSetSelectionState={handleSetSelectionState}
              openAvailableDEsForCertainFolderModal={this.openTargetDEsForCertainFolderModal}
              handleSetAvailableExtensionsState={this.handleSetTargetDEState}
              availableDEFolderId={targetDEsFolderId}
              targetDataExtensionFields={targetDataExtensionFields}
              targetDEsFolders={targetDEsFolders}
              foldersSettings={foldersSettings}
              isTargetDEsFoldersModal
              selectionName={selectionName}
            />
          )}

          {showTargetDEsForFolder && (
            <FolderDataExtensions
              dataExtensions={targetDEsForFolder}
              handleSetAvailableExtensionsState={this.handleSetTargetDEState}
              targetDEsFolderId={targetDEsFolderId}
              handleSetSelectionState={handleSetSelectionState}
              foldersPath={foldersPath}
              handleChangeTargetDataExtension={this.handleChangeTargetDataExtension}
              isTargetDEsFoldersModal
              handleSetTargetDEState={this.handleSetTargetDEState}
            />
          )}
        </div>
        {/* show dropdowns only if user already filled the details for new DE (or edited DE)
      and have feature enabled and if there are at least one field  */}
        {!createNewAutoTargetDE && newTargetDataExtension?.relationship?.isSendable && (
          ((editNewAutoTargetDE && newTargetDataExtensionFields?.length) ||
            (editTargetDataExtension && targetDataExtensionFields?.length)) &&
          featureAutoCreateTargetDEIsEnabled
        ) ?
          (
            <div
              className={classNameForRelationDiv}
            >
              <label>
                <span>Send Relationship </span>
                <div className="slds-form-element">
                  <div className="slds-form-element__control">
                    <div className="slds-select_container">
                      <select
                        className="slds-select dropdown-relation"
                        onChange={
                          e => e.target.value !== 'none' && handleChangeSendableAndSubscriberField(e.target.value)
                        }
                        value={sendableDataExtensionField}
                      >
                        <option
                          value="none"
                        >
                          -- Select a Field --
                        </option>
                        {editedFields.map((targetDeField) => {
                          /**
                           * if newTargetDataExtensionFields contains field type Text,
                           * Number or Email then show that field name as a option
                           */
                          if (targetDeField.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                            targetDeField.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
                            targetDeField.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
                            return (
                              <option
                                key={targetDeField.Name.toString()}
                                value={JSON.stringify(targetDeField)}
                              >
                                {Util.abbreviate(targetDeField.Name.toString(), 20)}
                              </option>
                            );
                          }

                          return null;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </label>
              <label>
                <span> relates to Subscriber on</span>
                <div className="slds-form-element">
                  <div className="slds-form-element__control">
                    <div className="slds-select_container">
                      <select
                        className="slds-select dropdown-relation"
                        value={newTargetDataExtension?.relationship?.sendableSubscriberField?.name ||
                          'Subscriber Key'}
                      >
                        <option
                          value="Subscriber Key"
                          disabled={
                            newTargetDataExtension?.relationship?.sendableSubscriberField?.name !== 'Subscriber Key'
                          }
                        >
                          Subscriber Key
                        </option>
                        <option
                          value="Subscriber Id"
                          disabled={
                            newTargetDataExtension?.relationship?.sendableSubscriberField?.name !== 'Subscriber Id'
                          }
                        >
                          Subscriber Id
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </label>

              {this.showDefaultSendRelationshipSettings()}

            </div>
          ) :
          null}
        <LoadingModal
          hideCloseButton
          hide={!newDELoading}
          loadingText={editTargetDataExtension ?
            'Updating Target Data Extension' :
            'Creating Target Data Extension'}
          id="DE-loadingmodal"
        />
      </div>
    );
  }
}

TargetDE.propTypes = {

  /**
   * It keeps the Customer Key of a selected existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionCustomerKey: PropTypes.string.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the type of the union type of selections (union or union all)
   * It will be passed from Selection.js
   */
  unionType: PropTypes.string,
  /**
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * @property {object} unionSelections.relations => it keeps the relation between selected data extensions
   * @property {object} unionSelections.collections => it keeps the selected data extensions
   * @property {object} unionSelections.fields => it keeps the selected fields for
   * target data extensions (matchedFields)
   * @property {object} unionSelections.filters => it keeps the filters of a Selection
   * It will be passed from App.js
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * It keeps the type of selection (Union or Simple)
   * It will be passed from Selection.js
   */
  selectionType: PropTypes.string,
  /**
   * It keeps the info about a New Auto Create Data Extension
   */
  newTargetDataExtension: PropTypes.instanceOf(Object),
  /**
   * It shows if user clicked on "Create Data Extension" button and open
   * a modal to input info about new DE
   */
  createNewAutoTargetDE: PropTypes.bool,
  /**
   * It keeps the value of ac/de creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool,
  /**
   * It keeps the fields which user drag and drop during the process of creating a new DE
   */
  newTargetDataExtensionFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the fields matched in Target Definition screen so we don't lose them
   * if we cancel creation of New Data Extension
   */
  prevMatchedFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the target data extensions after they are retrieved from SFMC
   * data views will not be inside target data extensions
   */
  targetDataExtensions: PropTypes.instanceOf(Array),
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * Responsible for the loading status of Target Data Extensions
   */
  loadingForTargetDataExtensions: PropTypes.bool.isRequired,
  /**
   * It keeps subscriber field names
   */
  subscriberFieldNames: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps data action state (overwrite, append, update)
   */
  dataAction: PropTypes.string.isRequired,
  /**
   * Handles the changing of sendable and subscriber field names
   */
  handleChangeSendableAndSubscriberField: PropTypes.func,
  /**
   * It keeps data for selected target data extension
   */
  targetCollectionObjectID: PropTypes.string.isRequired,
  /**
   * Indicates whether selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
  /*
   * Selected id of the target DE folder
   */
  targetDEsFolderId: PropTypes.number,
  /**
   * Keeps folder filtering information
   */
  foldersSettings: PropTypes.instanceOf(Object),
  /**
   * Stored targetDEsFolders folders in selection state (Stored in order to prevent making unnecessary api calls)
   */
  targetDEsFolders: PropTypes.instanceOf(Array),
  /**
   * It keeps data for selected target data extension
   */
  targetDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * Indicates whether AutoCreateTargetDEIsEnabled is enabled
   */
  featureAutoCreateTargetDEIsEnabled: PropTypes.bool,
  /**
   * Stores the selection name
   */
  selectionName: PropTypes.string.isRequired,
  /**
   * Object containing default send relationship settings
   */
  defaultSendRelationshipField: PropTypes.object,
  /**
   * Opens settings
   */
  openSettings: PropTypes.bool.isRequired,
  /**
   * Function for adding Subscriber Field option in dropdown
   */
  addSubscriberOption: PropTypes.instanceOf(Object).isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo', 'orgInfo']), null, null, { pure: false })(TargetDE);
