import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Tip = ({
  mode, title, description, enterAfterTitle,
}) => {
  return (
    <div className="good-to-know-container">
      <div className={`good-to-know good-to-know-${mode}-mode`}>
        <svg className="slds-button__icon light-bulb" aria-hidden="true">
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#light_bulb" />
        </svg>
        <div className="text">
          <span className="good-to-know-title">
            {title}
          </span>
          {enterAfterTitle ?
            (<br />) :
            ' '}
          <span className="good-to-know-second-subtext">
            {description}
          </span>
        </div>
      </div>
    </div>
  );
};

Tip.propTypes = {
  /**
   * The string phrase that appears in the first span element in the component
   */
  title: PropTypes.string,
  /**
   * Sentence below
   */
  description: PropTypes.node,
  /**
   * Dark or light mode
   */
  mode: PropTypes.oneOf(['dark', 'light']),
  /**
   * Enter after title?
   */
  enterAfterTitle: PropTypes.bool,
};

export default Tip;
