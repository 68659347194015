import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Popover, Button, Tooltip } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import ReactDragListView from 'react-drag-listview';
import { connect } from 'react-redux';

import Constants from '../../constants/constants';
import SwalUtil from '../../utils/swal/swalUtil';
import UsersAPI from '../../api/users';
import './styles.scss';
import mapStateToProps from '../../mapStateToProps';
import Checkbox from '../shared_v2/Checkbox/Checkbox';
import ColumnsIcon from '../../icons_v2/columns.svg';
import { showPendoGuide } from '../../utils/pendo/pendo';

/**
 * RightBar component for application navigation and user preferences
 * @param {Object} props - Component props
 * @param {Object} props.userPreferences - User preferences object
 * @param {Function} props.updateUserPreferences - Function to update user preferences
 * @param {Function} props.handleNavigator - Function to handle navigation
 * @param {string} props.navigator - Current navigation state
 * @param {Function} props.handleSetOverviewState - Function to set overview state
 * @param {Object} props.selections - Selections object
 * @param {boolean} props.isOverview - Boolean indicating if current view is overview
 * @param {string} props.overviewSection - Current overview section
 * @param {Object} props.userInfo - User information object
 * @param {Function} props.handleExpandRightBar - Function to expand the right bar
 * @param {boolean} props.expandedRightBar - Boolean indicating if the right bar is expanded
 * @param {boolean} props.zendeskChatbot - Boolean indicating if the Zendesk chatbot is enabled
 * @returns {JSX.Element} - RightBar component
 */
const RightBar = ({
  userPreferences,
  handleNavigator,
  navigator,
  handleSetOverviewState,
  isOverview,
  overviewSection,
  userInfo,
  handleExpandRightBar,
  expandedRightBar,
  zendeskChatbot,
  handleSetAppState,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isColumnsClicked, setIsColumnsClicked] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [isZendeskChatOpen, setIsZendeskChatOpen] = useState(false);
  const [isPendoInitialized, setIsPendoInitialized] = useState(false);

  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

  const axiosToken = axios.CancelToken.source();

  /**
   * Update user's preferences in the DB
   * @param {Object} preferences - user preferences object
   * @returns {void}
   */
  const updatePreferences = async (preferences) => {
    try {
      await UsersAPI.updatePreferences(
        preferences,
        axiosToken.token,
      );
      handleSetAppState({ preferences: { ...userPreferences, ...preferences } });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  /**
   * Remove or add a column from the overview table
   * @param {Object} column - Column object
   * @returns {void} - Remove or add column from the overview table
   */
  const removeOrAddColumn = (column) => {
    const columnIndex = checkedColumns.findIndex(c => c.name === column.name);

    const checkedColumnsCopy = JSON.parse(JSON.stringify(checkedColumns));

    if (columnIndex !== -1) {
      checkedColumnsCopy[columnIndex].isChecked = !column.isChecked;
    }

    try {
      updatePreferences({ selectionOverviewColumnsToBeShown: checkedColumnsCopy });
    } catch (error) {
      if (!axios.isCancel(error)) handleSetOverviewState({ error });
    }
  };

  /**
   * Pendo and Zendesk UseEffect setup
   */
  useEffect(() => {
    // Check if chat is open on component mount
    if (window.zE) {
      window.zE('messenger:on', 'open', () => setIsZendeskChatOpen(true));
      window.zE('messenger:on', 'close', () => setIsZendeskChatOpen(false));
    }
  }, []);

  /**
   * Toggles the visibility of the Zendesk chat widget.
   *
   * If the Zendesk chat widget is currently open, it will be hidden and closed.
   * If the Zendesk chat widget is currently closed, it will be shown and opened.
   * @returns {void} - Toggles the visibility of the Zendesk chat widget
   */
  const toggleZendeskChat = () => {
    if (window.zE) {
      if (isZendeskChatOpen) {
        window.zE('messenger', 'hide');
        window.zE('messenger', 'close');
      } else {
        window.zE('messenger', 'show');
        window.zE('messenger', 'open');
      }
    }
    setIsZendeskChatOpen(!isZendeskChatOpen);
  };

  // useffect to check if pendo window.pendo is available and update state of isPendoInitialized usestate
  useEffect(() => {
    const checkPendoInitialization = () => {
      if (Array.isArray(window.pendo?.guides)) {
        setIsPendoInitialized(true);
      } else {
        // If Pendo isn't initialized yet, check again after a short delay
        setTimeout(checkPendoInitialization, 100);
      }
    };

    checkPendoInitialization();
  }, []);

  /**
   * Opens the Pendo Resource Center guide.
   * Utilizes the Pendo API to display the guide identified by the PENDO_RESOURCE_CENTER_ID constant.
   * @returns {void} - Opens the Pendo Resource Center guide
   */
  const handleOpenPendoResourceCenter = () => {
    showPendoGuide(Constants.PENDO_RESOURCE_CENTER_ID);
  };

  useEffect(() => {
    if (userPreferences.selectionOverviewColumnsToBeShown) {
      setCheckedColumns(
        [
          ...userPreferences.selectionOverviewColumnsToBeShown,
        ],
      );
    }
  }, [userPreferences]);

  /**
   * Handler for the onClick event on the admin button
   * @returns {void}
   */
  const handleClickOnAdminButton = async () => {
    const confirmationNeededFor = [
      Constants.NAVIGATION__SELECTION_CRITERIA,
      Constants.NAVIGATION__TARGET_DEFINITION,
      Constants.NAVIGATION__PREVIEW,
    ];

    if (confirmationNeededFor.includes(navigator)) {
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to go to the Admin Panel?
        Unsaved changes will be lost.`,
        options: {
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (res.value) {
        handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
      }
    } else {
      handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
    }
  };

  /**
   * Toggle the expanded state of the RightBar
   * @returns {void} - Toggle the expanded state of the RightBar
   */
  const toggleExpand = useCallback(() => {
    handleExpandRightBar(!expandedRightBar);
    setShowTooltip(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleExpandRightBar, expandedRightBar]);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  /**
   * Handle the drag end event for reordering columns
   * @param {number} fromIndex - The starting index of the dragged item
   * @param {number} toIndex - The ending index where the item was dropped
   * @returns {void} - Handle the drag end event for reordering columns
   */
  const onDragEnd = (fromIndex, toIndex) => {
    const newColumns = [...checkedColumns];
    const draggedColumn = newColumns.splice(fromIndex, 1)[0];

    newColumns.splice(toIndex, 0, draggedColumn);

    setCheckedColumns(newColumns);

    try {
      updatePreferences({ selectionOverviewColumnsToBeShown: newColumns });
    } catch (error) {
      if (!axios.isCancel(error)) handleSetOverviewState({ error });
    }
  };

  const editColumnsPopover = (
    <div className="rightbar-v2__columns-menu">
      <div
        className="expand-column-header"
      >
        <span className="expand-column-label">
          Edit and reorder columns of the selections overview table.
        </span>
      </div>
      <ReactDragListView nodeSelector="div" handleSelector="#drag" onDragEnd={onDragEnd}>
        {checkedColumns.map((column) => {
          if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS &&
            ['Template', 'Records'].includes(column.name)) return null;

          return (
            <div
              key={column.name}
              className="column-item"
            >
              <Checkbox
                formElementClassName="folder-selection-checkbox"
                name={column.name}
                id={column.name}
                checked={column.isChecked}
                onChange={() => removeOrAddColumn(column)}
                label={column.name}
              />
              <Button
                title="Drag this card to change its order"
                id="drag"
                className="drag-and-drop"
                iconCategory="utility"
                iconName="drag_and_drop"
                iconSize="small"
                variant="icon"
              />
            </div>
          );
        })}
      </ReactDragListView>
    </div>
  );

  return (
    <div className={classNames('rightbar-v2', { 'rightbar-v2--expanded': expandedRightBar })}>
      <div className="rightbar-v2__content">
        {isOverview && (
          <div className="rightbar-v2__popover">
            <Popover
              body={editColumnsPopover}
              id="edit-columns-popover"
              ariaLabelledby="edit-columns-popover"
              isOpen={isColumnsClicked}
              onRequestClose={() => setIsColumnsClicked(false)}
              align="right"
            >
              <Button
                className="rightbar-v2__button"
                iconCategory="utility"
                variant="base"
                onClick={() => setIsColumnsClicked(!isColumnsClicked)}
                title="Edit Columns"
              >
                <img
                  className="rightbar-v2__button-icon"
                  src={ColumnsIcon}
                  alt="icon"
                />
                {expandedRightBar && <span>Edit Columns</span>}
              </Button>
            </Popover>
          </div>
        )}
        {userInfo !== undefined && userInfo.isAdmin && !hasReadOnlyAccess && (
          <Button
            className="rightbar-v2__button"
            iconCategory="utility"
            iconName="settings"
            iconSize="large"
            variant="icon"
            onClick={handleClickOnAdminButton}
            title="Admin Panel"
          >
            {expandedRightBar && <span>Admin Panel</span>}
          </Button>
        )}
        {
          userInfo !== undefined && isPendoInitialized && (
            <Button
              className="rightbar-v2__button des-pendo-feedback"
              iconCategory="utility"
              iconName="info_alt"
              iconSize="large"
              variant="icon"
              onClick={handleOpenPendoResourceCenter}
              title="Resource Center"
            >
              {expandedRightBar && <span>Resource Center</span>}
            </Button>
          )
        }
        {
          userInfo !== undefined && zendeskChatbot && (
            <Button
              className="rightbar-v2__button"
              iconCategory="utility"
              iconName="chat"
              iconSize="large"
              variant="icon"
              onClick={toggleZendeskChat}
              title="Live Chat"
            >
              {expandedRightBar && <span>Live Chat</span>}
            </Button>
          )
        }
      </div>
      <div
        className="rightbar-v2__toggle"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Tooltip
          align={expandedRightBar ? 'left' : 'right'}
          content={expandedRightBar ? 'Collapse the navigation' : 'Expand the navigation'}
          isOpen={showTooltip}
        >
          <Button
            className="rightbar-v2__toggle-button"
            iconCategory="utility"
            iconName={expandedRightBar ? 'chevronright' : 'chevronleft'}
            iconSize="medium"
            variant="icon"
            onClick={toggleExpand}
            onMouseEnter={(e) => {
              e.stopPropagation();
              handleMouseEnter();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              handleMouseLeave();
            }}
            onFocus={handleMouseEnter}
            onBlur={handleMouseLeave}
            assistiveText={{ icon: expandedRightBar ? 'Collapse navigation' : 'Expand navigation' }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

RightBar.propTypes = {
  /**
   * User preferences
   */
  userPreferences: PropTypes.object,
  /**
   * HandleNavigator prop is passed from App.js and it helps to navigate between the different screen of the App
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * Navigator prop is passed from App.js and it determines to know where we where in the App
   */
  navigator: PropTypes.string.isRequired,
  /**
   * Function setting state of App.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func,
  /**
   * Boolean indicating if current view is overview
   */
  isOverview: PropTypes.bool.isRequired,
  /**
   * Current overview section
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Function to expand the right bar
   */
  handleExpandRightBar: PropTypes.func.isRequired,
  /**
   * Boolean indicating if the right bar is expanded
   */
  expandedRightBar: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(RightBar);
