/* eslint-disable spellcheck/spell-checker */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Modal } from '@salesforce/design-system-react';
import classNames from 'classnames';

import Constants from '../../constants/constants';
import './styles.scss';
import Spinner from '../shared_v2/Spinner/Spinner';
import Input from '../../components/shared/Input/Input';
import Tooltip from '../shared/Tooltip/Tooltip';
import Util from '../../util';
import Tip from '../shared_v2/Tip/Tip';
import DataExtensionsAPI from '../../api/data-extensions';
import SwalUtil from '../../utils/swal/swalUtil';

const customButtonStyles = {
  width: '9.375rem',
};

class DeleteModal extends Component {
  isMounting = false;

  constructor(props) {
    super(props);
    this.state = {
      deleteQueryActivities: false,
      deleteTargetDE: false,
      loadingData: false,
      targetDEName: null,
      dedupDEName: null,
    };
  }

  /**
   * Fetch data while loading modal
   * @returns {void}
   */
  async componentDidMount() {
    // Before mounting we create 'CancelToken' for further cancellation of memory leak
    this.axiosCancelToken = axios.CancelToken.source();
    this.isMounting = true;

    // start loading data when component is mounted
    if (this.isMounting) {
      // show the loading spinner while fetching data
      this.setState({ loadingData: true });

      // call functions asynchronously
      Promise.all([this.getTargetDEName(), this.getDedupDEName()]).then(() => {
        // show the content after retrieving the data
        this.setState({ loadingData: false });
      });
    }
  }

  /**
   * Avoid memory leak by canceling all subscriptions and asynchronous tasks
   * @returns {void}
   */
  componentWillUnmount() {
    this.axiosCancelToken.cancel();
    this.isMounting = false;
  }

  /**
   * Function returns new Promise after retrieving the targetDE
   * @returns {Promise<Void>} void
   */
  getTargetDEName = () => (new Promise((resolve) => {
    resolve(this.fetchTargetDEName());
  }));

  /**
   * Function returns new Promise after retrieving the dedupDE
   * @returns {Promise<Void>} void
   */
  getDedupDEName = () => (new Promise((resolve) => {
    resolve(this.fetchDedupDEName());
  }));

  /**
   * Fetch and set Target Data Extension Name
   * @returns {void}
   */
  fetchTargetDEName = async () => {
    const { selection } = this.props;

    try {
      // if selection has target data extension
      if (selection.targetCollectionCustomerKey) {
        // get target data extension to display the name
        const targetDataExtension = await DataExtensionsAPI.getDataExtension(
          selection.targetCollectionCustomerKey,
        );

        // if found, set the targetDE name
        if (targetDataExtension) {
          this.setState({ targetDEName: targetDataExtension.Name });
        } else {
          // if targetDE is not found, set the state to null
          this.setState({ targetDEName: null });
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        // in case of error, display the message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            customClass: {
              popup: 'popup-targetDE',
            },
          },
        });
      }
    }
  };

  /**
   * Fetch and set Deduplication Data Extension Name
   * @returns {void}
   */
  fetchDedupDEName = async () => {
    const { selection } = this.props;

    try {
      // if selection has deduplication data extension
      if (selection.runDeduplicationDECustomerKey) {
        // get deduplication data extension to display the name
        const dedupDEName = await DataExtensionsAPI.getDataExtension(
          selection.runDeduplicationDECustomerKey,
        );

        // if found, set the dedupDEName name
        if (dedupDEName) {
          this.setState({ dedupDEName: dedupDEName.Name });
        } else {
          // if dedupDE is not found, set the state to null
          this.setState({ dedupDEName: null });
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        // in case of error, display the message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            customClass: {
              popup: 'popup-targetDE',
            },
          },
        });
      }
    }
  };

  /**
   * Removes selected Selection
   * @returns {void}
   */
  handleOnSubmit = async () => {
    const { deleteQueryActivities, deleteTargetDE } = this.state;
    const { handleRemoveSelection, handleCloseDeleteSelectionModal, selection } = this.props;

    // delete a Selection and close the modal
    handleRemoveSelection(selection._id, deleteQueryActivities, deleteTargetDE);
    handleCloseDeleteSelectionModal();
  };

  render() {
    const {
      handleCloseDeleteSelectionModal,
      selection,
      isWaterfall,
      handleOpenArchiveSelectionModal,
    } = this.props;

    const {
      loadingData,
      deleteQueryActivities,
      deleteTargetDE,
      targetDEName,
      dedupDEName,
    } = this.state;

    const titleCheckboxClassName = activeCondition => classNames(
      'title-target-DE',
      activeCondition ? 'active' : 'disabled',
    );

    const renderDeleteSelectionTip = (isWaterfall) => {
      const selectionType = isWaterfall ? 'Waterfall Selections' : 'Selections';

      return (
        <div className="delete-selection-tip">
          <span>
            If you&apos;re not sure about this, you always can archive it. It won&apos;t be on your active
            {' '}
            {selectionType}
            {' '}
            list while always being findable in your Archived
            {' '}
            {selectionType}
            {' '}
            folder.
          </span>
          <div
            className="des-lib-datatable-delete-middle-box-link"
            onClick={() => {
              handleCloseDeleteSelectionModal();
              handleOpenArchiveSelectionModal(selection?._id);
            }}
          >
            Archive the
            {isWaterfall ? ' Waterfall Selection ' : ' Selection '}
          </div>
        </div>
      );
    };

    return (
      selection ?
        (
          <Modal
            disableClose
            size="small"
            isOpen
          >
            {loadingData ?
              (
                <div style={{ height: '12rem' }}>
                  <Spinner size={Constants.SPINNER__SIZE__LARGE} assistiveText="Loading" />
                </div>
              ) :
              (
                <div className="slds-m-around_medium des-delete-selection-modal">
                  <div className="des-lib-datatable-delete-title">
                    {isWaterfall ? 'Delete this Waterfall Selection? ' : 'Delete this Selection?'}
                  </div>
                  <div className="des-lib-datatable-delete-text-top">
                    <div>{`'${selection.name}' will be deleted. Are you sure you want to delete this selection?`}</div>
                    <div>You will not be able to restore it.</div>
                  </div>
                  {((selection.targetCollectionCustomerKey) || (selection.queryActivityId)) &&
                    (
                      <div className="modal-content-with-additional-items">
                        <div className="selection-delete-title-wrapper">
                          Choose additional items to be deleted:
                          <span>
                            <Tooltip
                              type={Constants.TOOLTIP_TYPE__DELETE_SELECTION}
                              align={Constants.SLDS_TOOLTIP_POSITION__RIGHT}
                              customClassName="delete-selection-tooltip"
                            />
                          </span>
                        </div>
                        <div className="selection-modal target-DE ">
                          <div className="checkbox-wrapper">
                            <Input
                              type="checkbox"
                              name="selection-copy checkbox-onoff"
                              className="selection-copy checkbox"
                              noInputClassName
                              id="query-activity-checkbox"
                              onChange={() => this.setState({ deleteQueryActivities: !deleteQueryActivities })}
                              disabled={!selection.queryActivityId}
                              checked={deleteQueryActivities}
                            />
                            <div
                              className={titleCheckboxClassName(selection.queryActivityId)}
                              id="query-activity-label"
                              title=" Query Activities"
                              onClick={() => selection.queryActivityId &&
                                this.setState({ deleteQueryActivities: !deleteQueryActivities })}
                            >
                              Query Activities
                            </div>
                          </div>
                          <div className="checkbox-wrapper">
                            <Input
                              type="checkbox"
                              name="selection-copy checkbox-onoff"
                              className="selection-copy checkbox"
                              noInputClassName
                              id="target-de-checkbox"
                              onChange={() => (this.setState({ deleteTargetDE: !deleteTargetDE }))}
                              disabled={!selection.targetCollectionCustomerKey || !targetDEName}
                              checked={deleteTargetDE}
                            />
                            <div
                              className={titleCheckboxClassName(selection.targetCollectionCustomerKey &&
                                targetDEName)}
                              title="Target Data Extension"
                              id="target-de-label"
                              onClick={() => (selection.targetCollectionCustomerKey && targetDEName) &&
                                this.setState({ deleteTargetDE: !deleteTargetDE })}
                            >
                              {selection.targetCollectionCustomerKey && targetDEName ?
                                'Target Data Extension:' :
                                'Target Data Extension'}
                              {targetDEName && (
                                <span
                                  className="DE-name targetDE"
                                  title={targetDEName}
                                  onClick={() => selection.targetCollectionCustomerKey &&
                                    this.setState({ deleteTargetDE: !deleteTargetDE })}
                                >
                                  {Util.abbreviate(targetDEName, 80)}
                                </span>
                              )}
                            </div>
                          </div>

                          {(selection.runDeduplicationDECustomerKey && dedupDEName &&
                            targetDEName && selection.targetCollectionCustomerKey) &&
                            (
                            <div
                              className="dedup-de-name"
                              onClick={() => this.setState({ deleteTargetDE: !deleteTargetDE })}
                            >
                              and related Deduplication Data Extension:
                              {dedupDEName && (
                                <span
                                  className="DE-name dedupDE"
                                  title={dedupDEName}
                                  onClick={() => this.setState({ deleteTargetDE: !deleteTargetDE })}
                                >
                                  {Util.abbreviate(dedupDEName, 80)}
                                </span>
                              )}
                            </div>
                            )}
                        </div>
                      </div>
                    )}
                  {selection?.isArchived ?
                    null :
                    (
                      <Tip
                        title="Tip:"
                        description={renderDeleteSelectionTip(isWaterfall)}
                        mode="dark"
                      />
                    )}
                  <div className="des-lib-datatable-delete-actions">
                    <div>
                      <Button
                        iconCategory="utility"
                        label="Cancel"
                        size="large"
                        style={customButtonStyles}
                        onClick={() => handleCloseDeleteSelectionModal()}
                      />
                    </div>
                    <div className="des-lib-datatable-delete-btn">
                      <Button
                        label="Delete"
                        variant="destructive"
                        style={customButtonStyles}
                        onClick={() => this.handleOnSubmit()}
                      />
                    </div>
                  </div>
                </div>
              )}
          </Modal>

        ) :
        (
          null
        )
    );
  }
}

DeleteModal.propTypes = {
  /**
   * Object with a selected Selection
   */
  selection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  /**
   * It closes the DeleteSelectionModal
   */
  handleCloseDeleteSelectionModal: PropTypes.func.isRequired,
  /**
   * It removes the Selection
   */
  handleRemoveSelection: PropTypes.func.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
  /**
   * It opens the ArchiveSelection
   */
  handleOpenArchiveSelectionModal: PropTypes.func,
};

export default DeleteModal;
