import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../../mapStateToProps';
import Util from '../../../../../util';
import LoadingModal from '../../../../shared_v2/LoadingModal/LoadingModal';
import FiltersModal from './FiltersModal/FiltersModal';
import Constants from '../../../../../constants/constants';
import './styles.scss';
import Button from '../../../../shared_v2/Button/Button';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import SwalUtil from '../../../../../utils/swal/swalUtil';
import Tooltip from '../../../../shared_v2/Tooltip/Tooltip';

class Aggregation extends Component {
  constructor(props) {
    super(props);
    const {
      aggregationValues,
      handleSetSelectionState,
    } = props;

    // showAggregationModal is used to set filters value in selection.js
    handleSetSelectionState({ showAggregationModal: true });

    // state of aggregationValues
    this.state = {
      aggregationData: {
        formula: aggregationValues ? aggregationValues.formula : null,
        dataExtensionCustomerKey: aggregationValues ? aggregationValues.dataExtensionCustomerKey : null,
        fieldObjectId: aggregationValues ? aggregationValues.fieldObjectId : null,
        relations: {
          fromFieldObjectId: aggregationValues && aggregationValues.relations ?
            aggregationValues.relations.fromFieldObjectId :
            null,
          fromCollectionObjectId: aggregationValues && aggregationValues.relations ?
            aggregationValues.relations.fromCollectionObjectId :
            null,
          toCollectionObjectId: aggregationValues && aggregationValues.relations ?
            aggregationValues.relations.toCollectionObjectId :
            null,
          fromCollectionCustomerKey: aggregationValues && aggregationValues.relations ?
            aggregationValues.relations.fromCollectionCustomerKey :
            null,
          toCollectionAlias: aggregationValues && aggregationValues.relations ?
            aggregationValues.relations?.toCollectionAlias :
            null,
          toFieldObjectId: aggregationValues && aggregationValues.relations ?
            aggregationValues.relations.toFieldObjectId :
            null,
          relationId: aggregationValues?.relations?.relationId || null,
        },
        mode: this.getAggregationTypeMode(),
        filters: aggregationValues?.filters ? aggregationValues.filters[0] : [],
        fieldTypeAggregationValue:
          aggregationValues?.fieldTypeAggregationValue || Constants.FILTERLINE__FIELDTYPE__NUMBER,
      },
      fields: [],
      loadingFields: false,
      targetDeFields: [],
      dataExtensionName: '',
      loadingData: true,
      showText: false,
      aggregatedValuesFilters: { aggregationFilterRemoveClicked: true, filters: [], operator: 'AND' },
      filtersObject: { aggregationFilterRemoveClicked: true, filters: [], operator: 'AND' },
      selectedFieldDataExtension: [],
      relations: [],
      showRelation: true,
      hasMatchingFieldTypes: true,
    };

    // set the filters for aggregated values
    if (aggregationValues && aggregationValues.filters && aggregationValues.filters.length > 0) {
      this.state.aggregatedValuesFilters = JSON.parse(JSON.stringify(aggregationValues.filters[0]));
    } else if (aggregationValues?.filters?.filters?.length) {
      this.state.aggregatedValuesFilters = JSON.parse(JSON.stringify(aggregationValues?.filters));
    }
  }

  /**
   * Mount the component with dataExtensions
   * @returns {void}
   */
  async componentDidMount() {
    const { aggregationData } = this.state;
    const {
      availableDataExtensionsWithExclusionSkipped,
      dataExtensions,
      getDataExtensionOrDataViewFields,
      handleSetCustomValuesState,
      aggregationValues,
      isNewValue,
      getAllRelationsForSelectedDE,
      selectedDataExtensions,
    } = this.props;

    // get relations by selected data extensions object id
    const selectedRelations = getAllRelationsForSelectedDE();

    // set relations in state
    this.setState({ relations: selectedRelations, loadingData: false });

    // if aggregation data has toCollectionAlias
    if (aggregationData?.relations?.toCollectionAlias) {
      // get specific data extension determined by alias

      const dataExtensionsToSearch = availableDataExtensionsWithExclusionSkipped === undefined ?
        selectedDataExtensions :
        this.getRequiredDataExtensions(dataExtensions, availableDataExtensionsWithExclusionSkipped);

      const dataExtension = Util.getDataExtensionByAlias(
        aggregationData.relations.toCollectionAlias,
        dataExtensionsToSearch,
      );

      // if dataextension found
      if (dataExtension) {
        // set targetDeFields of dataExtension
        const targetDeFields = dataExtension.fields ?
          dataExtension?.fields :
          await getDataExtensionOrDataViewFields(dataExtension);

        this.setState({
          targetDeFields,
          loadingFields: false,
        });
      }
    }

    // if aggregation data has dataExtensionCustomerKey
    if (aggregationData && aggregationData.dataExtensionCustomerKey) {
      this.setState({ loadingData: true });
      try {
        // get specific data extension determined by ObjectID
        const dataExtension = this.getRequiredDataExtensions(
          dataExtensions,
          availableDataExtensionsWithExclusionSkipped,
        )?.find(d => d.CustomerKey === aggregationData.dataExtensionCustomerKey);

        // if dataExtension is found
        if (dataExtension) {
          // get the fields based on data extension or data view
          dataExtension.fields = await getDataExtensionOrDataViewFields(dataExtension);
          dataExtension.deAlias = dataExtension?.Name?.toString();

          // if field is deleted in SFMC, show swal pop up
          if (aggregationData && aggregationData.fieldObjectId) {
            const selectedField = dataExtension.fields.find(field => field.ObjectID === aggregationData.fieldObjectId);

            if (!selectedField) {
              this.handleThrowSwalMissingDeOrField('Field');

              // set fieldObjectId null in aggregation state
              this.setState(prevState => ({
                aggregationData: {
                  ...prevState.aggregationData,
                  fieldObjectId: null,
                  filters: [],
                },
              }));

              // set fieldObjectId null in custom value state
              handleSetCustomValuesState({
                aggregationValues: {
                  ...aggregationValues,
                  fieldObjectId: null,
                  filters: [],
                },
              });
            }
          }

          // if field is deleted in SFMC, show swal pop up
          if (aggregationData?.relations?.fromFieldObjectId) {
            const dataExtensionFields = dataExtension?.fields ?
              dataExtension?.fields :
              await getDataExtensionOrDataViewFields(dataExtension);

            const selectedField = dataExtensionFields.find(field => field.ObjectID ===
              aggregationData.relations.fromFieldObjectId);

            if (!selectedField) {
              this.handleThrowSwalMissingDeOrField('Field');

              // set fromFieldObjectId null in aggregation state
              this.setState(prevState => ({
                aggregationData: {
                  ...prevState.aggregationData,
                  relations: {
                    ...prevState.aggregationData.relations,
                    fromFieldObjectId: null,
                  },
                },
              }));

              // set fromFieldObjectId null in custom value state
              handleSetCustomValuesState({
                aggregationValues: {
                  ...aggregationValues,
                  relations: {
                    ...aggregationValues.relations,
                    fromFieldObjectId: null,
                  },
                },
              });
            }
          }

          // const fields = dataExtensionResponse.data;
          this.setState({
            fields: dataExtension.fields,
            loadingFields: false,
            dataExtensionName: dataExtension.Name,
            loadingData: false,
            selectedFieldDataExtension: [dataExtension],
          });
        } else {
          // if data extension is deleted in SFMC, throw swal
          this.handleThrowSwalMissingDeOrField('Data Extension');

          // set dataextension and selected field null in aggregation state
          this.setState(prevState => ({
            aggregationData: {
              ...prevState.aggregationData,
              dataExtensionCustomerKey: null,
              fieldObjectId: null,
              relations: {
                ...aggregationData.relations,
                fromFieldObjectId: null,
              },
              filters: [],
            },
            loadingData: false,
            loadingFields: false,
          }));

          // set dataextension and selected field null in custom value state
          handleSetCustomValuesState({
            aggregationValues: {
              ...aggregationValues,
              dataExtensionCustomerKey: null,
              fieldObjectId: null,
              relations: {
                ...aggregationValues.relations,
                fromFieldObjectId: null,
              },
              filters: [],
            },
          });
        }
      } catch (error) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ error });
      }
    }
    const { getSelectedRelation } = this.props;

    // if we are in edit mode, find selected relation
    if (!isNewValue) {
      // check if there is relation for selected data extensions
      const selectedRelationBasedOnDE = selectedRelations.find(rel => rel.toDECustomerKey ===
        aggregationData.relations.fromCollectionCustomerKey && rel.fromDeAlias ===
        aggregationData.relations?.toCollectionAlias);

      // check if there is relation for selected data extensions and fields
      const selectedRelation = getSelectedRelation(selectedRelations, aggregationData);

      // if relations are removed
      if (aggregationData?.mode && aggregationData.mode === Constants.AGGREGATION__MODE__BASIC &&
        !selectedRelationBasedOnDE) {
        /*
         * if mode is basic and selected relation is no longer available
         * switch mode to advanced
         */
        this.setState({
          aggregationData: {
            ...aggregationData,
            mode: Constants.AGGREGATION__MODE__ADVANCED,
          },
        });

        // set state in customValues for aggregation
        handleSetCustomValuesState({
          aggregationValues: {
            ...aggregationValues,
            mode: Constants.AGGREGATION__MODE__ADVANCED,
          },
        });
      }

      /*
       * if mode is basic and if the fields in relation have changed,
       * update fields in relations in aggregationValues
       */
      if (aggregationData?.mode && aggregationData.mode === Constants.AGGREGATION__MODE__BASIC &&
        selectedRelationBasedOnDE && !selectedRelation) {
        this.setState({
          aggregationData: {
            ...aggregationData,
            relations: {
              ...aggregationData.relations,
              fromFieldObjectId: selectedRelationBasedOnDE.toFieldObjectId,
              toFieldObjectId: selectedRelationBasedOnDE.fromFieldObjectId,
            },
          },
        });

        // set state in customValues for aggregation
        handleSetCustomValuesState({
          aggregationValues: {
            ...aggregationValues,
            relations: {
              ...aggregationValues.relations,
              fromFieldObjectId: selectedRelationBasedOnDE.toFieldObjectId,
              toFieldObjectId: selectedRelationBasedOnDE.fromFieldObjectId,
            },
          },
        });
      }
    }

    /*
     * if mode is basic and no relations are available
     * set mode to advanced
     */
    if (isNewValue && aggregationData.mode === Constants.AGGREGATION__MODE__BASIC &&
      !selectedRelations?.length) {
      this.setState(prevState => ({
        aggregationData: {
          ...prevState.aggregationData,
          mode: Constants.AGGREGATION__MODE__ADVANCED,
        },
      }));

      // set state in customValues for aggregation
      handleSetCustomValuesState({
        aggregationValues: {
          ...aggregationValues,
          mode: Constants.AGGREGATION__MODE__ADVANCED,
        },
      });
    }
  }

  /**
   * unmount the component
   * @returns {void}
   */
  componentWillUnmount() {
    const { handleSetSelectionState } = this.props;
    // showAggregationModal is used to set filters value in selection.js

    handleSetSelectionState({ showAggregationModal: false });
  }

  /**
   * Return the data extensions and add excluded DE that was selected before
   * @param {Array} dataExtensions - List of available data extensions
   * @param {Array} availableDataExtensionsWithExclusionSkipped - List of available data extensions with excluded ones
   * @returns {void}
   */
  getRequiredDataExtensions = (
    dataExtensions,
    availableDataExtensionsWithExclusionSkipped,
  ) => {
    const { aggregationData } = this.state;

    const dataExtensionsToReturn = [...(dataExtensions || [])];

    let aggregationDataExtension =
          dataExtensions.find(d => d.CustomerKey === aggregationData?.dataExtensionCustomerKey);

    let toDataExtension = dataExtensions.find(d => d.deAlias === aggregationData?.relations?.toCollectionAlias);

    if (!aggregationDataExtension) {
      aggregationDataExtension =
            availableDataExtensionsWithExclusionSkipped?.find(
              d => d.CustomerKey === aggregationData?.dataExtensionCustomerKey,
            );

      if (aggregationDataExtension) {
        dataExtensionsToReturn.push(aggregationDataExtension);
      }
    }

    if (!toDataExtension) {
      toDataExtension =
            availableDataExtensionsWithExclusionSkipped?.find(
              d => d.deAlias === aggregationData?.relations?.toCollectionAlias,
            );

      if (toDataExtension) {
        dataExtensionsToReturn.push(toDataExtension);
      }
    }

    return dataExtensionsToReturn;
  };

  /**
   * Function for saving filters for aggregation
   * @param {object} newState - object with new states
   * @returns {void}
   */
  handleSetAggregationFilterState = (newState) => {
    const { handleSetCustomValuesState } = this.props;
    const updateState = { ...newState };

    // get the updated filters
    const aggregationCustomValuesFilters = [updateState.aggregationValuesFilters];

    // save filters in aggregation state
    this.setState(prevState => ({
      aggregationData: {
        ...prevState.aggregationData,
        filters: aggregationCustomValuesFilters,
      },
      aggregatedValuesFilters: aggregationCustomValuesFilters[0],
    }));

    // save filters in custom values state
    handleSetCustomValuesState(prevState => ({
      aggregationValues: {
        ...prevState.aggregationValues,
        filters: aggregationCustomValuesFilters,
      },
    }));
  };

  /**
   * onChange event handler for formula
   * @param {object} e - event
   * @returns {void}
   */
  handleChangeFormulaOption = (e) => {
    // remove all selected filters while changing formula option
    const { filtersObject } = this.state;

    this.setState({ aggregatedValuesFilters: filtersObject });

    // set current value to state
    const { value } = e;

    this.setState(prevState => ({
      aggregationData: {
        ...prevState.aggregationData,
        fieldTypeAggregationValue: value === Constants.FORMULA__VALUE__COUNT ?
          Constants.FILTERLINE__FIELDTYPE__NUMBER :
          prevState.fieldTypeAggregationValue,
        formula: value,
        fieldObjectId: null,
        filters: [],
      },
    }));

    // set state in customValues for aggregation
    const { handleSetCustomValuesState, aggregationValues } = this.props;

    handleSetCustomValuesState({
      aggregationValues: {
        ...aggregationValues,
        fieldTypeAggregationValue: value === Constants.FORMULA__VALUE__COUNT ?
          Constants.FILTERLINE__FIELDTYPE__NUMBER :
          aggregationValues.fieldTypeAggregationValue,
        formula: value,
        fieldObjectId: null,
        filters: [],
      },
    });

    /*
     * if mode is basic and formula is count set
     * fieldType number and fieldObjectId to run query
     */
    if (aggregationValues && aggregationValues.mode === Constants.AGGREGATION__MODE__BASIC &&
      value === Constants.FORMULA__VALUE__COUNT) {
      const {
        fields,
      } = this.state;
      // find the selected field
      const field = fields[0];

      // if field found
      if (field) {
        // set state with default MaxLength, fieldType and fieldObjectId
        this.setState(prevState => ({
          aggregationData: {
            ...prevState.aggregationData,
            MaxLength: { MaxLength: 12, Scale: 6 },
            fieldObjectId: field.ObjectID,
            fieldTypeAggregationValue: Constants.FILTERLINE__FIELDTYPE__NUMBER,
          },
        }));

        // set customValue state with default MaxLength, fieldType and fieldObjectId
        handleSetCustomValuesState({
          aggregationValues: {
            ...aggregationValues,
            formula: value,
            MaxLength: { MaxLength: 12, Scale: 6 },
            fieldObjectId: field.ObjectID,
            fieldTypeAggregationValue: Constants.FILTERLINE__FIELDTYPE__NUMBER,
          },
        });
      }
    }
  };

  /**
   * It helps to change selectedDataExtension
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetSelectedDataExtension = async (e) => {
    const {
      dataExtensions,
      getDataExtensionOrDataViewFields,
      selectedDataExtensions,
    } = this.props;

    // get the customer key and deAlias from the value of an event
    const deCustomerKey = e.value.split('+')[0];
    const fromDeAlias = e.value.split('+')[1];

    // remove all selected filters while changing dataextension
    const { filtersObject, aggregationData } = this.state;

    this.setState({ selectedFieldDataExtension: [], aggregatedValuesFilters: filtersObject, showRelation: true });

    // find the selected de
    const dataExtension = dataExtensions.find(d => d.CustomerKey === deCustomerKey);

    // Get the fields of the Data Extension
    const fields = await getDataExtensionOrDataViewFields(dataExtension);

    // if de is found
    if (dataExtension) {
      try {
        // set in state
        if (aggregationData.mode === Constants.AGGREGATION__MODE__BASIC) {
          const { relations } = this.state;

          // find selected relation in all relations based on their relationship
          const selectedRelation = relations.find(item => item.toDECustomerKey === deCustomerKey &&
            item.fromDeAlias === fromDeAlias);

          // if selectedRelation is found
          if (selectedRelation) {
            // get targetDE from selected dataextensions
            const targetDE = Util.getDataExtensionByAlias(selectedRelation.fromDeAlias, selectedDataExtensions);

            // if de is found
            if (targetDE) {
              // set fields of target data extension
              const targetDeFields = targetDE.fields ?
                targetDE?.fields :
                await getDataExtensionOrDataViewFields(targetDE);

              // set in state
              this.setState({
                targetDeFields,
              });

              /*
               * set all required fields in aggregation state
               * setting reverse relation because backend is built like this to avoid
               * changes in backend
               */
              this.setState(prevState => ({
                aggregationData: {
                  ...prevState.aggregationData,
                  dataExtensionCustomerKey: dataExtension.CustomerKey,
                  fieldObjectId: prevState.aggregationData.formula === Constants.FORMULA__VALUE__COUNT ?
                    selectedRelation.toFieldObjectId :
                    null,
                  relations: {
                    ...prevState.aggregationData.relations,
                    fromFieldObjectId: selectedRelation.toFieldObjectId,
                    fromCollectionObjectId: selectedRelation.toDEObjectId,
                    toCollectionObjectId: selectedRelation.fromDEObjectId,
                    fromCollectionCustomerKey: selectedRelation.toDECustomerKey,
                    toCollectionAlias: targetDE.deAlias,
                    toFieldObjectId: selectedRelation.fromFieldObjectId,
                    relationId: selectedRelation._id,
                  },
                  filters: [],
                },
                loadingFields: true,
                dataExtensionName: dataExtension.Name,
              }));

              /*
               * set state in customValues for aggregation
               * setting reverse relation because backend is built like this to avoid
               * changes in backend
               */
              const { handleSetCustomValuesState, aggregationValues } = this.props;

              handleSetCustomValuesState({
                aggregationValues: {
                  ...aggregationValues,
                  dataExtensionCustomerKey: dataExtension.CustomerKey,
                  fieldObjectId: aggregationValues.formula === Constants.FORMULA__VALUE__COUNT ?
                    selectedRelation.toFieldObjectId :
                    null,
                  relations: {
                    ...aggregationValues.relations,
                    fromFieldObjectId: selectedRelation.toFieldObjectId,
                    fromCollectionObjectId: selectedRelation.toDEObjectId,
                    toCollectionObjectId: selectedRelation.fromDEObjectId,
                    fromCollectionCustomerKey: selectedRelation.toDECustomerKey,
                    toCollectionAlias: targetDE.deAlias,
                    toFieldObjectId: selectedRelation.fromFieldObjectId,
                    relationId: selectedRelation._id,
                  },
                  filters: [],
                },
              });
            }
          }
        } else {
          // otherwise set default values in state
          this.setState(prevState => ({
            aggregationData: {
              ...prevState.aggregationData,
              dataExtensionCustomerKey: dataExtension.CustomerKey,
              fieldObjectId: null,
              relations: {
                ...prevState.aggregationData.relations,
                fromFieldObjectId: null,
              },
              filters: [],
            },
            loadingFields: true,
            dataExtensionName: dataExtension.Name,
          }));

          // set state in customValues for aggregation
          const { handleSetCustomValuesState, aggregationValues } = this.props;

          handleSetCustomValuesState({
            aggregationValues: {
              ...aggregationValues,
              dataExtensionCustomerKey: dataExtension.CustomerKey,
              fieldObjectId: null,
              relations: {
                ...aggregationValues.relations,
                fromFieldObjectId: null,
              },
              filters: [],
            },
          });
        }

        // set state
        this.setState({
          fields,
          loadingFields: false,
          selectedFieldDataExtension: [{ ...dataExtension, deAlias: dataExtension?.Name?.toString(), fields }],
        });
      } catch (error) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ error });
      }
    } else {
      // set default state de is not found
      this.setState(prevState => ({
        aggregationData: {
          ...prevState.aggregationData,
          dataExtensionCustomerKey: '',
          fieldObjectId: null,
          relations: {
            ...prevState.aggregationData.relations,
            fromFieldObjectId: null,
          },
        },
        fields: [],
      }));

      // set state in customValues for aggregation
      const { handleSetCustomValuesState, aggregationValues } = this.props;

      handleSetCustomValuesState({
        aggregationValues: {
          ...aggregationValues,
          dataExtensionCustomerKey: '',
          fieldObjectId: null,
          relations: {
            ...aggregationValues.relations,
            fromFieldObjectId: null,
          },
        },
      });
    }
  };

  /**
   * It helps to change field
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetSelectedField = async (e) => {
    const {
      fields,
      aggregationData,
    } = this.state;
    // find the selected field
    const field = fields.find(f => f.ObjectID === e.value);
    // if field found

    if (field) {
      // set state with fieldObjectId
      this.setState(prevState => ({
        aggregationData: {
          ...prevState.aggregationData,
          fieldObjectId: field.ObjectID,
          fieldTypeAggregationValue: this.getFieldType(aggregationData.formula, field.FieldType),
        },
      }));

      // set state in customValues for aggregation
      const { handleSetCustomValuesState, aggregationValues } = this.props;

      handleSetCustomValuesState({
        aggregationValues: {
          ...aggregationValues,
          fieldObjectId: field.ObjectID,
          fieldTypeAggregationValue: this.getFieldType(aggregationData.formula, field.FieldType),
        },
      });
    }
  };

  /**
   * It helps to change selected field in relation
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetSelectedRelationsField = async (e) => {
    const {
      fields,
      targetDeFields,
      selectedFieldDataExtension,
      aggregationData,
    } = this.state;

    // find the selected field
    const field = fields.find(f => f.ObjectID === e.value);
    const toFieldObject = targetDeFields.find(f => f.ObjectID === aggregationData.relations?.toFieldObjectId);

    // if field found
    if (field) {
      if (toFieldObject) {
        this.setState(() => ({
          hasMatchingFieldTypes: field.FieldType === toFieldObject.FieldType,
        }));
      }
      // set state with fromFieldObjectId
      this.setState(prevState => ({
        aggregationData: {
          ...prevState.aggregationData,
          relations: {
            ...prevState.aggregationData.relations,
            fromFieldObjectId: field.ObjectID,
            // use CustomerKey for data views
            fromCollectionObjectId: selectedFieldDataExtension[0].ObjectID ||
              selectedFieldDataExtension[0].CustomerKey || null,
            fromCollectionCustomerKey: selectedFieldDataExtension[0].CustomerKey || null,

          },
        },
      }));

      // set state in customValues for aggregation
      const { handleSetCustomValuesState, aggregationValues } = this.props;

      handleSetCustomValuesState({
        aggregationValues: {
          ...aggregationValues,
          relations: {
            ...aggregationValues?.relations,
            fromFieldObjectId: field.ObjectID,
            // use CustomerKey for data views
            fromCollectionObjectId: selectedFieldDataExtension[0].ObjectID ||
              selectedFieldDataExtension[0].CustomerKey || null,
            fromCollectionCustomerKey: selectedFieldDataExtension[0].CustomerKey || null,
          },
        },
      });
    }
  };

  /**
   * It helps to change target data extension in relation
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetSelectedRelationsToDE = async (e) => {
    const {
      selectedDataExtensions,
      getDataExtensionOrDataViewFields,
    } = this.props;

    // find the selected de
    const dataExtension = Util.getDataExtensionByAlias(e.value, selectedDataExtensions);

    // if de is found
    if (dataExtension) {
      // set fields of data extension
      const targetDeFields = dataExtension.fields ?
        dataExtension?.fields :
        await getDataExtensionOrDataViewFields(dataExtension);

      // set in state
      this.setState(prevState => ({
        aggregationData: {
          ...prevState.aggregationData,
          relations: {
            ...prevState.aggregationData.relations,
            toCollectionAlias: dataExtension.deAlias,
            toFieldObjectId: null,
          },
        },
        targetDeFields,
        loadingFields: false,
      }));

      // set state in customValues for aggregation
      const { handleSetCustomValuesState, aggregationValues } = this.props;

      handleSetCustomValuesState({
        aggregationValues: {
          ...aggregationValues,
          relations: {
            ...aggregationValues.relations,
            toCollectionAlias: dataExtension.deAlias,
            toFieldObjectId: null,
          },
        },
      });
    }
  };

  /**
   * It helps to change target field
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetSelectedRelationsToField = async (e) => {
    const { targetDeFields, aggregationData, fields } = this.state;
    // find the selected field
    const field = targetDeFields.find(f => f.ObjectID === e.value);
    const fromFieldObject = fields.find(f => f.ObjectID === aggregationData.relations?.fromFieldObjectId);

    // if field found
    if (field) {
      if(fromFieldObject) {
        this.setState(() => ({
          hasMatchingFieldTypes: field.FieldType === fromFieldObject.FieldType,
        }));
      }
      // set state with toFieldObjectId
      this.setState(prevState => ({
        aggregationData: {
          ...prevState.aggregationData,
          relations: {
            ...prevState.aggregationData.relations,
            toFieldObjectId: field.ObjectID,
          },
        },
      }));

      // set state in customValues for aggregation
      const { handleSetCustomValuesState, aggregationValues } = this.props;

      handleSetCustomValuesState({
        aggregationValues: {
          ...aggregationValues,
          relations: {
            ...aggregationValues?.relations,
            toFieldObjectId: field.ObjectID,
          },
        },
      });
    }
  };

  /**
   * Function for closing filters modal
   * @returns {void}
   */
  closeFiltersModal = () => {
    const { handleSetCustomValuesState } = this.props;

    handleSetCustomValuesState({ filtersModal: false });
  };

  /**
   * Show/hide text
   * @returns {void}
   */
  handleShowTextBtn = () => {
    const { showText } = this.state;

    this.setState({ showText: !showText });
  };

  /**
   * Helps to find deleted fields and remove them from the filters
   * @param {Array} filters - Filters to update
   * @param {Array} selectedFieldDataExtension - Selected Data Extensions
   * @param {Object} prevResults - Previous call results in case of subfilters
   * @returns {Object} - An object with the updated filters and a flag that's true if filters were updated
   */
  checkMissingFieldAndUpdateFilters = (filters, selectedFieldDataExtension, prevResults) => {
    const { updatedFilters, isUpdated } = filters?.filters?.reduce((result, currentFilter) => {
      if (currentFilter?.filters) {
        const resultOfChildFilter =
        this.checkMissingFieldAndUpdateFilters(currentFilter, selectedFieldDataExtension, prevResults || result);

        return resultOfChildFilter;
      }
      let fieldDE = selectedFieldDataExtension?.find((de) => {
        const checkProperty = Util.startsWithUnderScore(de.Name, '_') ? de?.CustomerKey : de?.ObjectID;

        return checkProperty === currentFilter?.deId;
      });

      // In case Field's DE is not found by ObjectID, try to find it by DE alias as fallback
      if (!fieldDE) {
        fieldDE = selectedFieldDataExtension?.find(de => de?.deAlias === currentFilter?.collectionAlias);
      }

      const isFieldFound = !!fieldDE?.fields?.find(field => field.ObjectID === currentFilter?.fieldObjectID);

      if (!isFieldFound) {
        return {
          isUpdated: true,
          updatedFilters: filtersUtil.removeFilterLine(
            prevResults?.updatedFilters || result?.updatedFilters,
            { filterLineId: currentFilter?.id },
          ),
        };
      }

      return prevResults || result;
    }, { updatedFilters: filters, isUpdated: false }) || {};

    return {
      updatedFilters,
      isUpdated,
    };
  };

  /**
   * Function to open filters modal and populate aggregation filters
   * @returns {void}
   */
  handleEditFilters = () => {
    const { handleSetSelectionState, handleSetCustomValuesState } = this.props;
    const { aggregatedValuesFilters, selectedFieldDataExtension } = this.state;

    // get and set updated filters to aggregation filters
    let filters = [];

    filters = JSON.parse(JSON.stringify(aggregatedValuesFilters));

    const { updatedFilters, isUpdated } = this.checkMissingFieldAndUpdateFilters(filters, selectedFieldDataExtension);

    if (isUpdated) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Deleted Field used in Aggregation Filters',
        message: 'The Filters of this Aggregation Custom Value are updated due to deleted Field(s).',
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    handleSetSelectionState({ aggregationFilters: updatedFilters });

    // open filter modal
    handleSetCustomValuesState({ filtersModal: true });
  };

  /**
   * get field type based on given function value
   * @param {string} value - function value of aggregate
   * @param {string} fieldType - field type of the field to be aggregated
   * @returns {string} - returns corresponding field type
   */
  getFieldType = (value, fieldType) => {
    // Check function value and field type to determine generated field type.
    switch (true) {
      // If function is MIN or MAX and field type is Date return Date
      case value === Constants.FORMULA__VALUE__MINIMUM && fieldType === Constants.FILTERLINE__FIELDTYPE__DATE:
      case value === Constants.FORMULA__VALUE__MAXIMUM && fieldType === Constants.FILTERLINE__FIELDTYPE__DATE:
        return Constants.FILTERLINE__FIELDTYPE__DATE;
      // If function is count return number.
      case value === Constants.FORMULA__VALUE__COUNT:
        return Constants.FILTERLINE__FIELDTYPE__NUMBER;
      // Return decimal in all other cases.
      default:
        return Constants.FILTERLINE__FIELDTYPE__DECIMAL;
    }
  };

  /**
   * Function to throw swal pop up
   * @param {string} fieldOrDataExtension - field or dataextension
   * @returns {void}
   */
  handleThrowSwalMissingDeOrField = (fieldOrDataExtension) => {
    SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      title: `Deleted ${fieldOrDataExtension} used in Aggregation Value`,
      message: `This aggregated Custom Value contains a ${fieldOrDataExtension} which no longer exists,
        please choose another one.`,
      options: {
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      },
    });
  };

  /**
   * Function that checks if all required fields are filled after changing from advanced to basic mode.
   * It updates the showRelation state depending on whether the fields from the defined relation have been selected
   * @param {object} aggregationData - object with aggregation data
   * @returns {object} object with updated aggregation data
   */
  validateAndUpdateAggregationBasicMode = (aggregationData) => {
    const { relations } = this.state;
    const { getSelectedRelation } = this.props;

    // check if the same relation and fields are selected as in predefined relation
    const selectedRelation = getSelectedRelation(relations, aggregationData);

    // aggregation data that will be updated
    const validAggregationData = { ...aggregationData };

    if (selectedRelation) {
      // show selected relation
      this.setState({ showRelation: true });

      // to avoid errors, if there is no fieldObjectId for count formula, then assign field from toFieldObjectId
      if (!validAggregationData.fieldObjectId && validAggregationData.formula === Constants.FORMULA__VALUE__COUNT) {
        validAggregationData.fieldObjectId = validAggregationData?.relations?.toFieldObjectId;
      }
    } else {
      // do not set relation in basic mode, because other fields are defined than in predefined relation
      this.setState({ showRelation: false });
    }

    // return valid and updated aggregationData
    return validAggregationData;
  };

  /**
   * onChange event handler for change the mode ('basic' or 'advanced')
   * @param {object} mode - mode to choose - 'basic' or 'advanced'
   * @returns {void}
   */
  handleChangeMode = (mode) => {
    const { disabled } = this.props;

    if (disabled) return;

    let { aggregationData } = this.state;

    // by switching to the basic mode, check if all fields are filled and update if necessary
    if (mode === Constants.AGGREGATION__MODE__BASIC) {
      aggregationData = this.validateAndUpdateAggregationBasicMode(aggregationData);
    }

    // set state with mode
    this.setState({
      aggregationData: {
        ...aggregationData,
        mode,
      },
    });

    // set state in customValues for aggregation
    const { handleSetCustomValuesState } = this.props;

    let { aggregationValues } = this.props;

    // by switching to the basic mode, check if all fields are filled and update if necessary
    if (mode === Constants.AGGREGATION__MODE__BASIC) {
      aggregationValues = this.validateAndUpdateAggregationBasicMode(aggregationValues);
    }

    handleSetCustomValuesState({
      aggregationValues: {
        ...aggregationValues,
        mode,
      },
    });
  };

  /**
   * Function to get mode of aggregation type 'basic' or 'advanced'
   * @returns {String} mode
   */
  getAggregationTypeMode = () => {
    const {
      aggregationValues,
      isNewValue,
      featurePredefinedRelationsIsEnabled,
    } = this.props;

    // set the default mode
    let mode = Constants.AGGREGATION__MODE__BASIC;

    /*
     * if we have mode set in aggregation value get that mode, Otherwise for
     * previous aggregation value set mode to advanced
     */
    if (aggregationValues && aggregationValues.mode) {
      mode = aggregationValues.mode;
    } else if (!isNewValue && !aggregationValues.mode) {
      // if edit mode and previous mode is not set
      mode = Constants.AGGREGATION__MODE__ADVANCED;
    } else if (isNewValue) {
      // if new value set mode to basic
      mode = Constants.AGGREGATION__MODE__BASIC;
    }

    // if predefined relation feature is false, set mode to advance
    if (!featurePredefinedRelationsIsEnabled) {
      mode = Constants.AGGREGATION__MODE__ADVANCED;
    }

    // set state in customValues for aggregation
    const { handleSetCustomValuesState } = this.props;

    handleSetCustomValuesState({
      aggregationValues: {
        ...aggregationValues,
        mode,
      },
    });

    // return the mode
    return mode;
  };

  render() {
    const {
      dataExtensions,
      loadingAllAvailableDataExtensions,
      availableDataExtensionsWithExclusionSkipped,
      selectedDataExtensions,
      handleSetSelectionState,
      handleFiltersSave,
      compareSelectedDataExtensions,
      aggregationFilters,
      getDataExtensionOrDataViewFields,
      DEBorderMouseOver,
      filterBorderMouseOver,
      pickLists,
      handlePickListOptions,
      handleRemoveFilterLine,
      filtersModal,
      featurePredefinedRelationsIsEnabled,
      applyTimezoneSettingsToAllDateFields,
      handleSetTimezoneToAllDateFields,
      timezoneSettingsForAllDateFields,
      disabled,
    } = this.props;

    const {
      loadingFields,
      fields,
      targetDeFields,
      aggregationData,
      dataExtensionName,
      showText,
      loadingData,
      aggregatedValuesFilters,
      selectedFieldDataExtension,
      relations,
      showRelation,
      hasMatchingFieldTypes,
    } = this.state;

    // check if to and from fields are set
    const toFieldObject = fields?.find(f => f.ObjectID === aggregationData.relations?.fromFieldObjectId);
    const fromFieldObject = targetDeFields?.find(f => f.ObjectID === aggregationData.relations?.toFieldObjectId);

    const newToFieldType = toFieldObject?.FieldType;

    const newFromFieldType = fromFieldObject?.FieldType;

    let checkLoadedMatchingFieldTypes = hasMatchingFieldTypes;

    if (newToFieldType && newFromFieldType) {
      checkLoadedMatchingFieldTypes = newToFieldType === newFromFieldType;
    }

    // formula options to be populated
    const renderFunctionOptions = () => (
      [
        {
          key: Constants.FORMULA__VALUE__COUNT,
          text: 'Count number of records',
          value: Constants.FORMULA__VALUE__COUNT,
        },
        /*
         * commented for while as SFMC giving issue while running query
         * {
         *   key: Constants.FORMULA__VALUE__COUNT_DISTINCT,
         *   text: 'Count distinct values for field',
         *   value: Constants.FORMULA__VALUE__COUNT_DISTINCT,
         * },
         */
        {
          key: Constants.FORMULA__VALUE__SUM,
          text: 'Sum values for field',
          value: Constants.FORMULA__VALUE__SUM,
        },
        {
          key: Constants.FORMULA__VALUE__MINIMUM,
          text: 'Minimum value for field',
          value: Constants.FORMULA__VALUE__MINIMUM,
        },
        {
          key: Constants.FORMULA__VALUE__MAXIMUM,
          text: 'Maximum value for field',
          value: Constants.FORMULA__VALUE__MAXIMUM,
        },
        {
          key: Constants.FORMULA__VALUE__AVERAGE,
          text: 'Average value for field',
          value: Constants.FORMULA__VALUE__AVERAGE,
        },
      ]);

    /*
     * Format the data extensions for the dropdown
     */
    const deOptions = Util.formattedDataForTheDropdown(
      this.getRequiredDataExtensions(dataExtensions, availableDataExtensionsWithExclusionSkipped),
      'CustomerKey',

      'Name',
    );

    /*
     * Format the relations for the dropdown
     */
    const relationsOptions = relations
      .map(relation => ({
        value: relation ? `${relation.toDECustomerKey.toString()}+${relation.fromDeAlias.toString()}` : '',
        title: relation ? relation.toDEName.toString() + ' per ' + relation.fromDeAlias.toString() : '',
        text: relation ? relation.toDEName.toString() + ' per ' + relation.fromDeAlias.toString() : '',
        key: relation ? `${relation.toDECustomerKey.toString()}+${relation.fromDeAlias.toString()}` : '',
      }));

    /*
     * Format the fields for the dropdown
     * and filter out based on selected formula
     */
    const fieldsOptions = Util.formattedDataForTheDropdown(fields, 'ObjectID', 'Name', 'FieldType')
      .filter((field) => {
        // Variable that stores whether the field should be included in the options or not.
        let include = false;

        // Count and count distinct works for all field types.
        if (aggregationData.formula === Constants.FORMULA__VALUE__COUNT ||
          aggregationData.formula === Constants.FORMULA__VALUE__COUNT_DISTINCT) {
          include = true;
          // Minimum and maximum works for numbers, decimals and dates.
        } else if (aggregationData.formula === Constants.FORMULA__VALUE__MINIMUM ||
          aggregationData.formula === Constants.FORMULA__VALUE__MAXIMUM) {
          include =
            field.type === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
            field.type === Constants.FILTERLINE__FIELDTYPE__DECIMAL ||
            field.type === Constants.FILTERLINE__FIELDTYPE__DATE;
          // All the rest functions work only for numbers and decimals.
        } else {
          include =
            field.type === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
            field.type === Constants.FILTERLINE__FIELDTYPE__DECIMAL;
        }

        return include;
      });

    // sort fieldsOptions alphabetically
    Util.sortArrayOfObjects(fieldsOptions, 'text');

    /*
     * Format the relations to fields for the dropdown
     */
    const relationsToFieldsOptions = Util.formattedDataForTheDropdown(fields, 'ObjectID', 'Name', 'FieldType');

    // sort relations to fields options alphabetically
    Util.sortArrayOfObjects(relationsToFieldsOptions, 'text');

    /*
     * Format the data extensions for the dropdown
     */
    const targetDeOptions = Util.formattedDataForTheDropdown(
      this.getRequiredDataExtensions(selectedDataExtensions, availableDataExtensionsWithExclusionSkipped),
      'deAlias',
      'deAlias',
    );
    /*
     * Format the fields for the dropdown
     */
    const targetFieldsOptions = Util.formattedDataForTheDropdown(targetDeFields, 'ObjectID', 'Name');

    // sort targetFieldsOptions alphabetically
    Util.sortArrayOfObjects(targetFieldsOptions, 'text');

    /**
     * Function to set text of selected filters
     * @returns {void}
     */
    const renderFilterText = () => {
      const { userInfo } = this.props;

      // check if there are any filters to generate text
      if (aggregatedValuesFilters) {
        const filterText = filtersUtil.getFilterTextForFilters(
          aggregatedValuesFilters.filters,
          aggregatedValuesFilters.operator,
          true,
          pickLists,
          userInfo,
        );

        // if text generated from filters return the text, otherwise default text
        if (filterText) {
          return (
            <p style={{ wordWrap: 'break-word', lineHeight: '1.25rem', lineBreak: 'anywhere' }}>
              {filterText.length > 150 && showText === false ?
                `${Util.abbreviate(filterText, 125)}` :
                filterText}
              <span
                id="show-more-less-text"
                className={filterText.length > 149 ? 'show-more-less-text' : 'show-more-less-text-none'}
                onClick={() => this.handleShowTextBtn()}
              >
                {showText === false ? 'Show More' : 'Show Less'}
              </span>
            </p>
          );
        }
      }

      return 'No filter selected.';
    };

    // Text for filter info tooltip
    const filterToolTipText =
      `Optionally, filter the results of\ ${aggregationData.dataExtensionCustomerKey ?
        dataExtensionName :
        'Data extension'} further.`;

    /**
     * Function to render filters container component
     * @returns {object} HTML object for filter container
     */
    const renderFiltersContainer = () => (
      <div className="filters-container margin-top">
        <div className="filters-content">
          <div className="filters-flex-title-main">
            <div>
              <span className="label">Filters</span>
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                tooltipText={filterToolTipText}
              />
            </div>
            <div className="add-filters-btn">
              <Button
                id="applyFormula-insert-button"
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                title="edit filters"
                disabled={selectedFieldDataExtension.length === 0 || disabled}
                onClick={() => this.handleEditFilters()}
              >
                Edit Filters
                <svg
                  className="slds-button__icon slds-icon_small edit-icon"
                  id="plus-icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
                </svg>
              </Button>
            </div>
          </div>
          <div className="filters-flex">
            <div className="filters-text">
              {renderFilterText()}
            </div>
          </div>
        </div>
      </div>
    );

    /**
     * show field dropdown based on conditions
     * @returns {Boolean} - true or false
     */
    const showFieldDropdown = () => (aggregationData.formula === undefined || aggregationData.formula === null ||
      !aggregationData.dataExtensionCustomerKey || !aggregationData.relations.toCollectionAlias ||
      aggregationData.formula === Constants.FORMULA__VALUE__COUNT);

    /**
     * returns relations value depending on the chosen one
     * @returns {string} - relations value
     */
    const returnRelationsValue = () => showRelation && aggregationData?.dataExtensionCustomerKey &&
      aggregationData?.relations.toCollectionAlias ?
      `${aggregationData.dataExtensionCustomerKey.toString()}+${aggregationData.relations
        .toCollectionAlias.toString()}` :
      '';

    /**
     * returns class name for aggregation option
     * @param {string} mode - aggregation mode
     * @returns {string} - class name
     */
    const aggregationOptionClassName = (mode) => {
      const { disabled } = this.props;

      return classNames(
        'aggregation-option',
        // eslint-disable-next-line quote-props
        { 'active': aggregationData.mode === mode },
        { disabled },
      );
    };

    // class name for aggregation modal
    const aggregationModalClassName = classNames(
      'aggregation-modal',
      {
        'basic-mode-modal-height': aggregationData.mode === Constants.AGGREGATION__MODE__BASIC,
        'basic-mode-modal-height-field': aggregationData.mode === Constants.AGGREGATION__MODE__BASIC &&
          !showFieldDropdown() === true,
        'advanced-mode-container': aggregationData.mode === Constants.AGGREGATION__MODE__ADVANCED,
      },
    );

    return (
      <div>
        {loadingData ?
          (
            <LoadingModal
              bgNone
              hideCloseButton
              id="DE-loadingmodal"
              loadingText="Loading..."
            />
          ) :
          null}
        <div className="aggregation-header-wrapper">
          <span className="aggregation-header-wrapper__title">{Constants.CUSTOM_VALUES__HEADER__AGGREGATION}</span>
          <span className="aggregation-header-wrapper__description">
            {Constants.CUSTOM_VALUES__TEXT_AGGREGATION}
          </span>
        </div>
        {relations.length > 0 && featurePredefinedRelationsIsEnabled && (
          <div className="aggregation-options-container">
            <div className="header-options">
              {relations.length > 0 && (
                <div
                  className={aggregationOptionClassName(Constants.AGGREGATION__MODE__BASIC)}
                  onClick={() => this.handleChangeMode(Constants.AGGREGATION__MODE__BASIC)}
                >
                  Basic
                </div>
              )}
              <div
                className={aggregationOptionClassName(Constants.AGGREGATION__MODE__ADVANCED)}
                onClick={() => this.handleChangeMode(Constants.AGGREGATION__MODE__ADVANCED)}
              >
                Advanced
              </div>
            </div>
          </div>
        )}
        {aggregationData.mode === Constants.AGGREGATION__MODE__BASIC && (
          <>
            <br />
            <div className={aggregationModalClassName}
            >
              <div className="aggregation-container">
                <div className="aggregation-content">
                  <div className="formula-container">
                    <div className="formula-content">
                      <div className="flex-title">
                        Aggregation function:
                      </div>
                      <div className="dropdown-functions">
                        <Dropdown
                          id="formula-dropdown"
                          selection
                          className="target-data-extension-dropdown searchable-dropdown"
                          search
                          value={aggregationData.formula}
                          placeholder="Choose function"
                          options={renderFunctionOptions()}
                          loading
                          onChange={(e, data) => this.handleChangeFormulaOption(data)}
                          lazyLoad
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field-container margin-top">
                    <div className="field-content">
                      <div className="flex-title">
                        Field
                        <Tooltip
                          nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                          tooltipText="Choose the field you want to apply the aggregation function to."
                        />
                      </div>
                      <div className="dropdown-functions">
                        <div className="dropdown-field data-extension">
                          <Dropdown
                            id="data-extensions-dropdown"
                            selection
                            className="target-data-extension-dropdown searchable-dropdown offset-dropdown"
                            search
                            placeholder="Choose data extension"
                            options={relationsOptions}
                            value={returnRelationsValue()}
                            onChange={(e, data) => this.handleSetSelectedDataExtension(data)}
                            disabled={!deOptions.length || !deOptions ||
                              loadingFields || disabled || loadingAllAvailableDataExtensions}
                            loading
                            lazyLoad
                          />
                        </div>
                        {showFieldDropdown() ?
                          null :
                          (
                            <div className="dropdown-field fields">
                              <Dropdown
                                id="field-dropdown"
                                selection
                                className="target-data-extension-dropdown searchable-dropdown"
                                search
                                placeholder="Choose field"
                                options={fieldsOptions}
                                value={aggregationData.fieldObjectId}
                                loading
                                onChange={(e, data) => this.handleSetSelectedField(data)}
                                disabled={!aggregationData.dataExtensionCustomerKey || loadingFields || disabled}
                                lazyLoad
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {renderFiltersContainer()}
                </div>
              </div>
            </div>
          </>
        )}
        {aggregationData.mode === Constants.AGGREGATION__MODE__ADVANCED && (
          <>
            <br />
            <div className={aggregationModalClassName}>
              <div className="aggregation-container">
                <div className="aggregation-content">
                  <div className="formula-container">
                    <div className="formula-content">
                      <div className="flex-title">
                        Aggregation function:
                      </div>
                      <div className="dropdown-functions">
                        <Dropdown
                          id="formula-dropdown"
                          selection
                          className="target-data-extension-dropdown searchable-dropdown"
                          search
                          value={aggregationData.formula}
                          placeholder="Choose function"
                          options={renderFunctionOptions()}
                          loading
                          onChange={(e, data) => this.handleChangeFormulaOption(data)}
                          lazyLoad
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field-container margin-top">
                    <div className="field-content">
                      <div className="flex-title">
                        Field
                        <Tooltip
                          nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                          tooltipText="Choose the field you want to apply the aggregation function to."
                        />
                      </div>
                      <div className="dropdown-functions">
                        <div className="dropdown-field data-extension">
                          <Dropdown
                            id="data-extensions-dropdown"
                            selection
                            className="target-data-extension-dropdown searchable-dropdown offset-dropdown"
                            search
                            placeholder="Choose data extension"
                            options={deOptions}
                            value={aggregationData.dataExtensionCustomerKey}
                            onChange={(e, data) => this.handleSetSelectedDataExtension(data)}
                            disabled={
                              !deOptions.length || !deOptions || !aggregationData.formula ||
                               loadingFields || disabled || loadingAllAvailableDataExtensions
                            }
                            loading
                            lazyLoad
                          />
                        </div>
                        <div className="dropdown-field fields">
                          <Dropdown
                            id="field-dropdown"
                            selection
                            className="target-data-extension-dropdown searchable-dropdown"
                            search
                            placeholder="Choose field"
                            options={fieldsOptions}
                            value={aggregationData.fieldObjectId}
                            loading
                            onChange={(e, data) => this.handleSetSelectedField(data)}
                            disabled={!aggregationData.dataExtensionCustomerKey || loadingFields || disabled}
                            lazyLoad
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="relation-container margin-top">
                    <div className="relation-content">
                      <div className="label">
                        <span> Relation to results</span>
                        <Tooltip
                          nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                          tooltipText="Specify how the data set on which the
                          aggregation function is being applied relates to the main selection."
                        />
                      </div>
                      <div className="field-mismatch-error">
                          {!checkLoadedMatchingFieldTypes && (
                            <span>
                              Selecting different field types might throw an error.
                            </span>
                          )}
                      </div>
                      <div className="relation-fields-container">
                        <div className="flex-title">Field:</div>
                        <div className="dropdown-functions">
                          <Dropdown
                            id="from-field-dropdown"
                            selection
                            className="target-data-extension-dropdown searchable-dropdown"
                            search
                            placeholder="Choose field"
                            options={relationsToFieldsOptions}
                            loading
                            value={aggregationData.relations.fromFieldObjectId}
                            onChange={(e, data) => this.handleSetSelectedRelationsField(data)}
                            disabled={!aggregationData.dataExtensionCustomerKey || loadingFields || disabled}
                            lazyLoad
                          />

                        </div>
                      </div>
                      <div className="relations-description-container">
                        <div className="flex-title" />
                        <div className="dropdown-functions">
                          of
                          {' '}
                          <span
                            className="data-extension-name"
                            title={aggregationData.dataExtensionCustomerKey ? dataExtensionName : 'Data extension'}
                          >
                            {aggregationData.dataExtensionCustomerKey ? dataExtensionName : 'Data extension'}
                          </span>
                        </div>
                      </div>
                      <div className="matches-with-container">
                        <span className="flex-title"> Matches with:</span>

                        <div className="dropdown-functions">
                          <div className="dropdown-field relations-to-field">
                            <Dropdown
                              id="to-DE-dropdown"
                              selection
                              className="target-data-extension-dropdown searchable-dropdown"
                              search
                              placeholder="Choose data extension"
                              options={targetDeOptions}
                              value={aggregationData.relations.toCollectionAlias}
                              disabled={!selectedDataExtensions.length || !selectedDataExtensions || disabled}
                              loading
                              onChange={(e, data) => this.handleSetSelectedRelationsToDE(data)}
                              lazyLoad
                            />
                          </div>

                          <div className="dropdown-field relations-to-field">
                            <Dropdown
                              id="to-field-dropdown"
                              selection
                              className="target-data-extension-dropdown searchable-dropdown"
                              search
                              placeholder="Choose field"
                              options={targetFieldsOptions}
                              disabled={!aggregationData.relations.toCollectionAlias || disabled}
                              value={aggregationData.relations.toFieldObjectId}
                              loading
                              onChange={(e, data) => this.handleSetSelectedRelationsToField(data)}
                              lazyLoad
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  {renderFiltersContainer()}
                </div>
              </div>
            </div>
          </>
        )}

        {filtersModal ?
          (
            <FiltersModal
              show
              closeFiltersModal={this.closeFiltersModal}
              selectedDataExtensions={selectedFieldDataExtension}
              handleSetSelectionState={handleSetSelectionState}
              handleFiltersSave={handleFiltersSave}
              compareSelectedDataExtensions={compareSelectedDataExtensions}
              handleSetAggregationFilterState={this.handleSetAggregationFilterState}
              aggregationFilters={aggregationFilters}
              getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
              DEBorderMouseOver={DEBorderMouseOver}
              filterBorderMouseOver={filterBorderMouseOver}
              pickLists={pickLists}
              handlePickListOptions={handlePickListOptions}
              handleRemoveFilterLine={handleRemoveFilterLine}
              applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
              timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
              handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
              isCriteriaFilter
            />
          ) :
          null}
      </div>

    );
  }
}

Aggregation.propTypes = {
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It stores the value of subquery modal if it will be shown or not
   */
  filtersModal: PropTypes.bool.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  aggregationFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Keeps object data of aggregation
   */
  aggregationValues: PropTypes.instanceOf(Object),
  /**
   * Keeps track if value is new or for update
   */
  isNewValue: PropTypes.bool.isRequired,
  /**
   * It's predefined relations feature value passed
   * from CustomValues.js
   */
  featurePredefinedRelationsIsEnabled: PropTypes.bool.isRequired,
  /**
   * Function helps to get predefined relation by relation type and selected dataextension object id
   */
  getAllRelationsForSelectedDE: PropTypes.func.isRequired,
  /**
   * Function helps to check if the same relation appears in aggregation data as in predefined relation
   */
  getSelectedRelation: PropTypes.func.isRequired,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * Should custom value be editable or not?
   */
  disabled: PropTypes.bool,
  /**
   * List of data extensions with excluded ones
   */
  availableDataExtensionsWithExclusionSkipped: PropTypes.instanceOf(Array),
  /**
   * Responsible for the loading status of all available data extensions
   */
  loadingAllAvailableDataExtensions: PropTypes.bool,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(Aggregation);
