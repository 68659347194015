import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import axios from 'axios';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Util from '../../../util';
import Constants from '../../../constants/constants';
import SelectionsLeft from './SelectionsLeft/SelectionsLeft';
import SelectionsCenter from './SelectionsCenter/SelectionsCenter';
// eslint-disable-next-line import/prefer-default-export
import SelectionsRight from './SelectionsRight/SelectionsRight';
import GuidanceTip from '../../shared/GuidanceTip/GuidanceTip';
import syncScroll from './syncscroll';
import Spinner from '../../shared/Spinner/Spinner';
import SortIcon from '../../shared/SortIcon/SortIcon';
import timeUtil from '../../../utils/time/timeUtil';
import Tooltip from '../../shared/Tooltip/Tooltip';
import DataTable from '../../../components_v2/DataTable/DataTable';
import UsersAPI from '../../../api/users';

const axiosCancelToken = axios.CancelToken.source();

// eslint-disable-next-line import/prefer-default-export
const SelectionList = ({
  selections,
  searchField,
  handleOpenDeleteSelectionModal,
  handleOpenArchiveSelectionModal,
  handleEditSelection,
  hideFolders,
  handleSortSelections,
  sortDirection,
  sortedProperty,
  startDraggingToFolder,
  handleSetOverviewState,
  openAllFolders,
  openSelections,
  handleOpenCopySelectionModal,
  offsetTop,
  featureScheduleSelectionsIsEnabled,
  handleOpenSelectionRunLogsModal,
  loadingSelectionsList,
  overviewSection,
  featureSelectionTemplateIsEnabled,
  featureSelectionFoldersEnabled,
  inArchivedFolder,
  showCalendarViewForSchedules,
  showEssentialsUpgradeModal,
  userPreferences,
  handleNavigator,
  userInfo,
  orgInfo,
}) => {
  const defaultColumns = [
    { name: 'Status', isChecked: true },
    { name: 'Records', isChecked: true },
    { name: 'Last Run', isChecked: true },
    { name: 'Scheduled', isChecked: true },
    { name: 'Created By', isChecked: true },
    { name: 'Created At', isChecked: true },
    { name: 'Last Modified By', isChecked: true },
    { name: 'Updated At', isChecked: true },
    { name: 'Template', isChecked: true },
  ];

  const dataTableColumns = [{ name: 'name', isChecked: true }, ...(userPreferences?.selectionOverviewColumnsToBeShown ?
    [...userPreferences.selectionOverviewColumnsToBeShown] :
    [...defaultColumns]),
  ];

  const [hover, setHover] = useState({});
  const userLocale = timeUtil.getUserLocale(userInfo);
  const userDateTimeFormat = timeUtil.getUserDateTimeFormat(userInfo);
  const [useNewDesign, setUseNewDesign] = useState(true);

  // Reset the script onLoad
  syncScroll.reset();

  // define classNames
  const centerHeaderClassName = classNames(
    'selections_center_header',
    // eslint-disable-next-line quote-props
    { 'waterfall': overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS },
  );

  const selectionsCenterClassName = classNames(
    'selections_center',
    // eslint-disable-next-line quote-props
    { 'center-bigger': hideFolders },
    // eslint-disable-next-line quote-props
    { 'waterfall': overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS },
  );

  const selectionsLeftClassName = classNames(
    'selections_left',
    // eslint-disable-next-line quote-props
    { 'waterfall': overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS },
  );

  /**
   * Handles the visibility of the selection buttons.
   * @param {boolean} obj.isTemplate - Is the selection a template?
   * @param {string} obj.createdById - The id of the user that created the selection.
   * @returns {boolean} true if the button should be hidden, false otherwise.
   */
  const handleRestrictedVisibility = ({ isTemplate, createdById }) => {
    /**
     * If the selection is a template, and the user is not an admin, and did not create the selection
     * restrict the actions he can take on the selection.
     */
    return !!(isTemplate && !userInfo.isAdmin && userInfo.id !== createdById);
  };
  const mouseOver = (index) => {
    setHover((c) => {
      return {
        ...c,
        [index]: true,
      };
    });
  };
  const mouseOut = (index) => {
    setHover((c) => {
      return {
        ...c,
        [index]: false,
      };
    });
  };

  /**
   * Get user's preferences in the DB
   * @returns {Object} - preferences object
   */
  const getUserPreferences = async () => {
    const { data } = await UsersAPI.getPreferences(
      axiosCancelToken.token,
    );

    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const preferences = await getUserPreferences();
        // eslint-disable-next-line max-len
        const newDesign = !!preferences?.showNewDesign;

        setUseNewDesign(newDesign);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Opens New Selection depending on the selected section
   * @returns {void}
   */
  const handleOpenNewSelection = async () => {
    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
      handleNavigator(Constants.NAVIGATION__WATERFALL_SELECTION);
    } else {
      // Check for limits for essentials edition
      if (orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS) {
        if (selections?.length >= Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT) {
          await Util.handleUpgradeWarning(Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_LIMIT_LABEL);

          return;
        }
      }
      handleNavigator(Constants.NAVIGATION__SELECTION);
    }
  };

  return (
    <div>
    {useNewDesign && (
      <div
      className="selections_list"
      style={{ minHeight: 'calc(100vh - 120px)' }}>
        <DataTable
        initialData={selections}
        handleOpenNewSelection={handleOpenNewSelection}
        columns={dataTableColumns}
        overviewSection={overviewSection}
        sortDirection={sortDirection}
        handleSortSelections={handleSortSelections}
        sortedProperty={sortedProperty}
        handleEditSelection={handleEditSelection}
        handleOpenDeleteSelectionModal={handleOpenDeleteSelectionModal}
        handleOpenArchiveSelectionModal={handleOpenArchiveSelectionModal}
        handleOpenCopySelectionModal={handleOpenCopySelectionModal}
        handleOpenSelectionRunLogsModal={handleOpenSelectionRunLogsModal}
        startDraggingToFolder={startDraggingToFolder}
        handleSetOverviewState={handleSetOverviewState}
        openAllFolders={openAllFolders}
        openSelections={openSelections}
        featureSelectionFoldersEnabled={featureSelectionFoldersEnabled}
        searchField={searchField}
        loadingSelectionsList={loadingSelectionsList}
        featureScheduleSelectionsIsEnabled={featureScheduleSelectionsIsEnabled}
        showCalendarViewForSchedules={showCalendarViewForSchedules}
        />
      </div>
    )}

      {!useNewDesign &&
        <div
          className="selections_list"
          style={{ minHeight: `calc(100vh - 76px - ${offsetTop}px)` }}
        >
          {loadingSelectionsList ?
            (
              <div className="folder-loading-spinner">
                <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading..." />
              </div>
            ) :
            (
              <>
                <div className={selectionsLeftClassName}>
                  <ul
                    className="selections_left_header"
                  >
                    <li className="selections_left_header-name">
                      Name
                      <span
                        id="nameSort"
                        className="sorting slds-icon_container slds-icon-utility-announcement"
                        onClick={() => handleSortSelections(
                          'name',
                          sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                            Constants.SORT_DIRECTION__DESCENDING :
                            Constants.SORT_DIRECTION__ASCENDING,
                        )}
                      >
                        <SortIcon sortDirection={sortedProperty === 'name' ? sortDirection : ''} />
                      </span>
                    </li>
                  </ul>
                  {selections.length > 0 ?
                    selections.map(val => (
                      <div
                        key={val._id}
                        onMouseEnter={() => mouseOver(val._id)}
                        onMouseLeave={() => mouseOut(val._id)}
                      >
                        <SelectionsLeft
                          key={val._id}
                          name={val.name}
                          _id={val._id}
                          folderId={val.folderId || ''}
                          handleEditSelection={handleEditSelection}
                          startDraggingToFolder={startDraggingToFolder}
                          handleSetOverviewState={handleSetOverviewState}
                          openAllFolders={openAllFolders}
                          openSelections={openSelections}
                          selectionDescription={val.selectionDescription || ''}
                          featureSelectionFoldersEnabled={featureSelectionFoldersEnabled}
                          showHover={hover[val._id]}
                        />
                      </div>
                    )) :
                    null}
                </div>
                <div className={selectionsCenterClassName}>
                  <div className="selections_center_header_container">
                    <div className="syncscroll scroller" name="overview-scroll">
                      <ul
                        className={centerHeaderClassName}
                      >
                        <li className="selections_center_header-status">Status</li>
                        {overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS && (
                          <li className="selections_center_header-records">
                            Records
                            {' '}
                            <span
                              id="numberOfRecords"
                              className="sorting slds-icon_container slds-icon-utility-announcement"
                              onClick={() => handleSortSelections(
                                'numberOfRecords',
                                sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                  Constants.SORT_DIRECTION__DESCENDING :
                                  Constants.SORT_DIRECTION__ASCENDING,
                              )}
                            >
                              <SortIcon sortDirection={sortedProperty === 'numberOfRecords' ? sortDirection : ''} />
                            </span>
                          </li>
                        )}
                        <li className="selections_center_header-taskCompletedDate">
                          Last Run
                          {' '}
                          <span
                            className="sorting slds-icon_container slds-icon-utility-announcement"
                            onClick={() => handleSortSelections(
                              overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
                                'waterfallSelectionCompletedDate' :
                                'taskCompletedDate',
                              sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                Constants.SORT_DIRECTION__DESCENDING :
                                Constants.SORT_DIRECTION__ASCENDING,
                            )}
                          >
                            <SortIcon sortDirection={
                              sortedProperty === 'taskCompletedDate' ||
                                sortedProperty === 'waterfallSelectionCompletedDate' ?
                                sortDirection :
                                ''
                            }
                            />
                          </span>
                          <GuidanceTip tipId="run-logs-tip" />
                        </li>
                        {!inArchivedFolder && (
                          <li
                            onClick={featureScheduleSelectionsIsEnabled ?
                              null :
                              () => {
                                showEssentialsUpgradeModal(Constants.FEATURE__SCHEDULE_SELECTIONS);
                              }}
                            className={classNames(
                              'selections_center_header-taskCompletedDate scheduled-column-title',
                              { 'disabled-overlay': !featureScheduleSelectionsIsEnabled },
                            )}
                          >
                            <span className="template-header-text">Scheduled</span>
                            {
                              !featureScheduleSelectionsIsEnabled &&
                              (
                                <div className="schedule-tooltip-position">
                                  <Tooltip
                                    nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                                    type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                                  />
                                </div>
                              )
                            }

                          </li>
                        )}
                        <li className="selections_center_header-createdBy">
                          Created By
                          {' '}
                          <span
                            id="createdBySort"
                            className="sorting slds-icon_container slds-icon-utility-announcement"
                            onClick={() => handleSortSelections(
                              'createdBy',
                              sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                Constants.SORT_DIRECTION__DESCENDING :
                                Constants.SORT_DIRECTION__ASCENDING,
                            )}
                          >
                            <SortIcon sortDirection={sortedProperty === 'createdBy' ? sortDirection : ''} />
                          </span>
                        </li>
                        <li className="selections_center_header-createdAt">
                          Created At
                          {' '}
                          <span
                            id="createdAtSort"
                            className="sorting slds-icon_container slds-icon-utility-announcement"
                            onClick={() => handleSortSelections(
                              'createdAt',
                              sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                Constants.SORT_DIRECTION__DESCENDING :
                                Constants.SORT_DIRECTION__ASCENDING,
                            )}
                          >
                            <SortIcon sortDirection={sortedProperty === 'createdAt' ? sortDirection : ''} />
                          </span>
                        </li>
                        <li className="selections_center_header-updatedBy">
                          Last Modified By
                          {' '}
                          <span
                            className="sorting slds-icon_container slds-icon-utility-announcement"
                            onClick={() => handleSortSelections(
                              'updatedBy',
                              sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                Constants.SORT_DIRECTION__DESCENDING :
                                Constants.SORT_DIRECTION__ASCENDING,
                            )}
                          >
                            <SortIcon sortDirection={sortedProperty === 'updatedBy' ? sortDirection : ''} />
                          </span>
                        </li>
                        <li className="selections_center_header-updatedAt">
                          Last Modified
                          {' '}
                          <span
                            className="sorting slds-icon_container slds-icon-utility-announcement"
                            onClick={() => handleSortSelections(
                              'updatedAt',
                              sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                Constants.SORT_DIRECTION__DESCENDING :
                                Constants.SORT_DIRECTION__ASCENDING,
                            )}
                          >
                            <SortIcon sortDirection={sortedProperty === 'updatedAt' ? sortDirection : ''} />
                          </span>
                        </li>

                        {
                          overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS && (
                            <li
                              className={classNames(
                                'selections_center_header-taskCompletedDate template-column-title',
                                { 'disabled-overlay': !featureSelectionTemplateIsEnabled },
                              )}
                              onClick={featureSelectionTemplateIsEnabled ?
                                null :
                                () => {
                                  showEssentialsUpgradeModal(Constants.FEATURE__SELECTION_TEMPLATE);
                                }}
                            >
                              <span className="template-header-text">Template</span>
                              {' '}
                              <span
                                className="sorting slds-icon_container slds-icon-utility-announcement"
                                onClick={() => handleSortSelections(
                                  'isTemplate',
                                  sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                                    Constants.SORT_DIRECTION__DESCENDING :
                                    Constants.SORT_DIRECTION__ASCENDING,
                                )}
                              >
                                <SortIcon sortDirection={sortedProperty === 'isTemplate' ? sortDirection : ''} />
                              </span>
                            </li>
                          )
                        }
                      </ul>
                    </div>
                    <div className="inside-scroll syncscroll" name="overview-scroll">
                      {selections.length > 0 ?
                        selections.map(val => (
                          <div
                            key={val._id}
                            onMouseEnter={() => mouseOver(val._id)}
                            onMouseLeave={() => mouseOut(val._id)}
                          >
                            <SelectionsCenter
                              key={val._id + 1}
                              numberOfRecords={val.numberOfRecords}
                              runStatus={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
                                val.waterfallSelectionStatus :
                                val.runStatus}
                              createdBy={val.createdBy}
                              createdAt={val.createdAt}
                              updatedBy={val.updatedBy}
                              updatedAt={val.updatedAt}
                              userDateTimeFormat={userDateTimeFormat}
                              taskCompletedDate={val.taskCompletedDate ||
                                val.runDeduplicationTaskCompletedDate ||
                                val.waterfallSelectionCompletedDate ||
                                val.taskStartDate ||
                                val.waterfallSelectionTaskStartDate}
                              userLocale={userLocale}
                              taskError={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
                                val.waterfallSelectionError :
                                val.taskError || val.runDeduplicationTaskError}
                              _id={val._id}
                              scheduledRun={val.scheduledRun}
                              featureScheduleSelectionsIsEnabled={featureScheduleSelectionsIsEnabled}
                              featureSelectionFoldersEnabled={featureSelectionFoldersEnabled}
                              handleOpenSelectionRunLogsModal={handleOpenSelectionRunLogsModal}
                              overviewSection={overviewSection}
                              isTemplate={val.isTemplate || false}
                              selectionDescription={val.selectionDescription || ''}
                              featureSelectionTemplateIsEnabled={featureSelectionTemplateIsEnabled}
                              selection={val}
                              isArchived={val.isArchived}
                              waterfallSchedules={val.waterfallSchedules || []}
                              showCalendarViewForSchedules={showCalendarViewForSchedules}
                              handleSetOverviewState={handleSetOverviewState}
                              showHover={hover[val._id]}
                            />
                          </div>
                        )) :
                        null}
                    </div>
                  </div>
                </div>
                <div className="selections_right">
                  <ul className="selections_right_header">
                    <li>Action</li>
                  </ul>
                  {selections.length > 0 ?
                    selections.map(val => (
                      <div
                        key={val._id}
                        onMouseEnter={() => mouseOver(val._id)}
                        onMouseLeave={() => mouseOut(val._id)}
                      >
                        <SelectionsRight
                          key={val._id + 2}
                          handleOpenDeleteSelectionModal={handleOpenDeleteSelectionModal}
                          handleOpenArchiveSelectionModal={handleOpenArchiveSelectionModal}
                          handleOpenCopySelectionModal={handleOpenCopySelectionModal}
                          isArchived={val.isArchived || false}
                          featureSelectionFoldersEnabled={featureSelectionFoldersEnabled}
                          _id={val._id}
                          restrictedVisibility={handleRestrictedVisibility(val) || false}
                          isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                          showHover={hover[val._id]}
                        />
                      </div>
                    )) :
                    null}
                </div>
              </>
            )}
        </div>}
    </div>
  );
};

SelectionList.propTypes = {
  /**
   * selections which are kept in the db
   */
  selections: PropTypes.instanceOf(Object).isRequired,
  /**
   * It opens the DeleteSelectionModal
   */
  handleOpenDeleteSelectionModal: PropTypes.func.isRequired,
  /**
   * It opens the ArchiveSelectionModal
   */
  handleOpenArchiveSelectionModal: PropTypes.func.isRequired,
  /**
   * It helps to open a selection
   */
  handleEditSelection: PropTypes.func.isRequired,
  /**
   * It hide/show folders
   */
  hideFolders: PropTypes.bool.isRequired,
  /**
   * It sorts selections
   */
  handleSortSelections: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * It saves the id of dragged selection to folder
   */
  startDraggingToFolder: PropTypes.func.isRequired,
  /**
   * It sets overview state
   */
  handleSetOverviewState: PropTypes.func.isRequired,
  /**
   * It open/close all created folders
   */
  openAllFolders: PropTypes.bool.isRequired,
  /**
   * It open/close all uncategorized folders
   */
  openSelections: PropTypes.bool.isRequired,
  /**
   * It opens the CopySelectionModal
   */
  handleOpenCopySelectionModal: PropTypes.func.isRequired,
  /**
   * The number of pixels for the offsetTop property
   */
  offsetTop: PropTypes.number,
  /**
   * It determines if the feature for scheduling selections is enabled or not
   */
  featureScheduleSelectionsIsEnabled: PropTypes.bool.isRequired,
  /**
   * It opens the selectionRunLogsModal
   */
  handleOpenSelectionRunLogsModal: PropTypes.func.isRequired,
  /**
   * It opens the calendar view modal
   */
  showCalendarViewForSchedules: PropTypes.func.isRequired,
  /**
   * Defines which section in Overview is selected - selections or waterfall
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * Indicates whether a list with selections is loaded
   */
  loadingSelectionsList: PropTypes.bool.isRequired,
  /**
   * It determines if the feature for selection template is enabled or not
   */
  featureSelectionTemplateIsEnabled: PropTypes.bool.isRequired,
  /**
   * It determines if the feature for selection folder is enabled or not
   */
  featureSelectionFoldersEnabled: PropTypes.bool.isRequired,
  /**
   * It determines if we are in the Archived Selections folder
   */
  inArchivedFolder: PropTypes.bool.isRequired,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * User preferences
   */
  userPreferences: PropTypes.object,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
};

export default connect(
  mapStateToProps(['userInfo', 'orgInfo', 'featuresInfo']),
  null,
  null,
  { pure: false },
)(SelectionList);
