import React from 'react';

import TimezoneList from '../components/Selection/ScheduleSelectionModal/TimezoneList.json';

const Constants = {
  STATUS_CREATING: -2,
  STATUS_CREATING_LABEL: 'Creating',
  STATUS_CREATED: -1,
  STATUS_CREATED_LABEL: 'Created',
  STATUS_QUEUED: 0,
  STATUS_QUEUED_LABEL: 'Queued',
  STATUS_PROCESSING: 1,
  STATUS_PROCESSING_LABEL: 'Processing',
  STATUS_COMPLETE: 2,
  STATUS_COMPLETE_LABEL: 'Complete',
  STATUS_ERROR: 3,
  STATUS_ERROR_LABEL: 'Error',
  STATUS_WAITING_LABEL: 'Waiting',
  STATUS_WAITING: 5,
  STATUS_QUEUED_FOR_WATERFALL: 6,
  STATUS_QUEUED_FOR_WATERFALL_LABEL: 'Queued for Waterfall',
  STATUS_RETRY: 7,
  STATUS_RETRY_LABEL: 'Paused',

  // Picklist Status
  PICKLIST__STATUS__QA_ERROR: 3,
  PICKLIST__STATUS__COMPLETE: 5,
  PICKLIST__STATUS__SEPARATION_ERROR: 6,

  // Network
  NETWORK_ONLINE: 'online',
  NETWORK_OFFLINE: 'offline',
  NETWORK_ERROR: 'Error: Network Error',

  // Overview
  OVERVIEW__SELECTIONS_LOADING: 'Selections are loading',

  // Sorting
  SORT_DIRECTION__ASCENDING: 'ascending',
  SORT_DIRECTION__DESCENDING: 'descending',
  DUMMY_DATE__1990_01_01: '1990-01-01T00:00:00.00-00:00',

  // Selection Search Criteria
  SELECTION__SEARCH_CRITERIA__NAME: 'name',
  SELECTION__SEARCH_CRITERIA__CREATED_BY_ID: 'createdById',
  SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID: 'updatedById',
  SELECTION__SEARCH_CRITERIA__UPDATED_AT: 'updatedAt',

  SELECTION__SEARCH_CRITERIA__NAME__LABEL: 'Name',
  SELECTION__SEARCH_CRITERIA__CREATED_BY_ID__LABEL: 'Created By',
  SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID__LABEL: 'Modified By',

  // number of selections per page in Overview
  OVERVIEW__LIMIT__NUMBER_OF_SELECTIONS_PER_PAGE: '15',

  // Features
  FEATURE__APPEND_DATA_EXTENSIONS: 'appendDataExtensions',
  FEATURE__AUTO_CREATE_TARGET_DATA_EXTENSION: 'autoCreateTargetDataExtension',
  FEATURE__DYNAMIC_DATE_FILTERS: 'dynamicDateFilters',
  FEATURE__SUB_QUERIES: 'subQueries',
  FEATURE__DATA_VIEWS: 'dataViews',
  FEATURE__COMPARE_FIELDS: 'compareFields',
  FEATURE__FORMULAS_IN_FILTERS: 'formulasInFilters',
  FEATURE__FORMULAS_IN_SELECT: 'formulasInSelect',
  FEATURE__PRIO_DEDUP: 'prioDeduplication',
  FEATURE__PRIO_DEDUP__BASIC: 'basic',
  FEATURE__PRIO_DEDUP__ADVANCED: 'advanced',
  FEATURE__CUSTOM_VALUES: 'customValues',
  FEATURE__FOLDERS: 'selectionFolders',
  FEATURE__PICKLISTS: 'picklists',
  FEATURE__FREE_FORMULAS: 'freeFormulas',
  FEATURE__AGGREGATED_VALUES: 'aggregatedValues',
  FEATURE__SCHEDULE_SELECTIONS: 'scheduleSelections',
  FEATURE__PREDEFINED_RELATIONS: 'predefinedRelations',
  FEATURE__IN_RESULTS_2: 'inResults2',
  FEATURE__COPY_TO_OTHER_BUS: 'copyToOtherBUs',
  FEATURE__ENCRYPTED_FIELDS: 'encryptedFields',
  FEATURE__WATERFALL_SELECTIONS: 'waterfallSelections',
  FEATURE__SELECTION_TEMPLATE: 'selectionTemplate',
  FEATURE__DATA_SETS: 'dataSets',
  FEATURE__FILTER_SETS: 'filterSets',
  FEATURE__FOLDER_FILTERS: 'folderFilters',
  FEATURE__GLOBAL_CUSTOM_VALUES: 'globalCustomValues',
  FEATURE__AUDIT_LOGS: 'auditLogs',
  FEATURE__IMPORTED_RELATIONS: 'importedRelations',
  FEATURE__SOURCE_LIMITING: 'sourceLimiting',
  FEATURE__DEEDEE_AI: 'deedeeAI',
  FEATURE__SQL_TO_SELECTION: 'sqlToSelection',
  FEATURE__NEW_DESIGN: 'newDesign',
  FEATURE__ZENDESK_CHATBOT: 'zendeskChatbot',

  // Feature is missing
  FEATURE__APPEND_DATA_EXTENSIONS_LABEL: 'Append Data Extensions',
  FEATURE__DYNAMIC_DATE_FILTERS_LABEL: 'Dynamic Date Filters',
  FEATURE__SUB_QUERIES_LABEL: 'Sub Queries',
  FEATURE__DATA_VIEWS_LABEL: 'Data Views',
  FEATURE__FORMULAS_IN_FILTERS_LABEL: 'Formulas In Filters',
  FEATURE__COMPARE_FIELDS_LABEL: 'Compare Fields',

  // Union types
  SELECTION__UNION_TYPE__UNION: 'union',
  SELECTION__UNION_TYPE__UNION_ALL: 'union all',

  // Selection types
  SELECTION__TYPE__UNION: 'union',
  SELECTION__TYPE__SIMPLE: 'simple',

  // Filter set source types
  FILTER_SET__SOURCE_TYPE__DATA_SET: 'dataSet',
  FILTER_SET__SOURCE_TYPE__DATA_EXTENSION: 'dataExtension',

  // Notification types
  NOTIFICATION__TYPE__WARNING: 'warning',
  NOTIFICATION__TYPE__ERROR: 'error',
  NOTIFICATION__TYPE__INFO: 'info',

  // Notification data for toastify
  NOTIFICATION__TOAST_ID__SAVE: 'toastId_save',
  NOTIFICATION__TOAST_ID__AUTOFIX: 'toastId_autofix',
  NOTIFICATION__TOAST_ID__AUTOMAP: 'toastId_automap',
  NOTIFICATION__TOAST_ID__RUNNING: 'toastId_running',
  NOTIFICATION__CONTAINER_ID__SELECTION: 'containerId_selection',
  NOTIFICATION__CONTAINER_ID__TARGET_DEFINITION: 'containerId_targetDefinition',
  NOTIFICATION__CONTAINER_ID__RUNNING: 'containerId_running',
  NOTIFICATION__TEXT__SAVED: 'Saved',
  NOTIFICATION__TEXT__AUTOFIXED: `This selection was auto-fixed.
  Please click the Save if you want to save the changes.`,

  // Navigation
  NAVIGATION__SELECTION: 'selection',
  NAVIGATION__SELECTION_CRITERIA: 'selectionCriteria',
  NAVIGATION__TARGET_DEFINITION: 'targetDefinition',
  NAVIGATION__PREVIEW: 'preview',
  NAVIGATION__OVERVIEW: 'overview',
  NAVIGATION__ADMIN_PANEL: 'adminPanel',
  NAVIGATION__WATERFALL_SELECTION: 'waterfallSelection',
  NAVIGATION__STEPS: 'steps',
  NAVIGATION__RUN_DETAILS: 'runDetails',

  // Overview sections
  OVERVIEW__SECTION__SELECTIONS: 'selections',
  OVERVIEW__SECTION__WATERFALL_SELECTIONS: 'waterfallSelections',

  // Rest location actions
  FULL_RESET: 'fullReset',
  PAGE_RESET: 'pageReset',

  // Waterfall views
  WATERFALL__VIEW__STEPS: 'steps',
  WATERFALL__VIEW__RUN_DETAILS: 'runDetails',

  // Admin panel menu option
  ADMIN_PANEL__MENU__USERS: 'users',
  ADMIN_PANEL__MENU__SETTINGS: 'notifications',
  ADMIN_PANEL__MENU__PICKLISTS: 'picklists',
  ADMIN_PANEL__MENU__NEW_PICKLIST: 'newPickList',
  ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL: 'globalCustomValuesPanel',
  ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUE: 'globalCustomValue',
  ADMIN_PANEL__MENU__EDITED_PICKLIST: 'editedPickList',
  ADMIN_PANEL__MENU__RELATIONS: 'relations',
  ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS: 'predefinedRelations',
  ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES: 'contactBuilderAttributes',
  ADMIN_PANEL__MENU__NEW_RELATION: 'newRelation',
  ADMIN_PANEL__MENU__EDIT_RELATION: 'editRelation',
  ADMIN_PANEL__MENU__EDIT_IMPORTED_RELATION: 'editImportedRelation',
  ADMIN_PANEL__MENU__DE_FOLDERS: 'dEFolders',
  ADMIN_PANEL__SUBMENU__AVAILABLE_DES: 'availableDEs',
  ADMIN_PANEL__SUBMENU__TARGET_DES: 'targetDEs',
  ADMIN_PANEL__MENU__DATA_SETS: 'dataSets',
  ADMIN_PANEL__MENU__FILTER_SETS: 'filterSets',
  ADMIN_PANEL__MENU__NEW_DATA_SET: 'newDataSet',
  ADMIN_PANEL__MENU_EDIT_DATA_SET: 'editDataSet',
  ADMIN_PANEL__MENU__NEW_FILTER_SET: 'newFilterSet',
  ADMIN_PANEL__MENU_EDIT_FILTER_SET: 'editFilterSet',
  ADMIN_PANEL__MENU__AUDIT_LOGS: 'auditLogs',
  ADMIN_PANEL__MENU__DEEDEE_AI_SCOPE_DES: 'deeDeeAIScopeDEs',

  // Data extension folders filtering mode
  DO_NOT_FILTER_FOLDERS: 'doNotFilter',
  INCLUDE_FOLDERS: 'include',
  EXCLUDE_FOLDERS: 'exclude',

  // DE types title text
  AVAILABLE_DES__TITLE: 'Available Data Extensions',
  TARTGET_DES__TITLE: 'Target Data Extensions',

  // Filterline
  FILTERLINE__FIELDTYPE__TEXT: 'Text',
  FILTERLINE__FIELDTYPE__NUMBER: 'Number',
  FILTERLINE__FIELDTYPE__DATE: 'Date',
  FILTERLINE__FIELDTYPE__BOOLEAN: 'Boolean',
  FILTERLINE__FIELDTYPE__EMAILADDRESS: 'EmailAddress',
  FILTERLINE__FIELDTYPE__EMAILADDRESS_LABEL: 'Email Address',
  FILTERLINE__FIELDTYPE__PHONE: 'Phone',
  FILTERLINE__FIELDTYPE__DECIMAL: 'Decimal',
  FILTERLINE__FIELDTYPE__LOCALE: 'Locale',
  FILTERLINE__CRITERIA__IS_EMPTY: 'is-empty',
  FILTERLINE__CRITERIA__IS_NOT_EMPTY: 'is-not-empty',
  FILTERLINE__CRITERIA__DATE__IN_PREVIOUS: 'in-previous',
  FILTERLINE__CRITERIA__DATE__IN_NEXT: 'in-next',
  FILTERLINE__CRITERIA__EQUALS: '=',
  FILTERLINE__CRITERIA__NOT_EQUAL_TO: '<>',
  FILTERLINE__CRITERIA__SMALLER_THAN: '<',
  FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO: '<=',
  FILTERLINE__CRITERIA__GREATER_THAN: '>',
  FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO: '>=',
  FILTERLINE__CRITERIA__IN: 'in',
  FILTERLINE__CRITERIA__NOT_IN: 'not-in',
  FILTERLINE__CRITERIA__IN_RESULTS: 'in-results',
  FILTERLINE__CRITERIA__NOT_IN_RESULTS: 'not-in-results',
  FILTERLINE__CRITERIA__BETWEEN: 'between',
  FILTERLINE__CRITERIA__NOT_BETWEEN: 'not-between',
  FILTERLINE__CRITERIA__DATE__TODAY: 'today',
  FILTERLINE__CRITERIA__CONTAINS: 'contains',
  FILTERLINE__CRITERIA__DOES_NOT_CONTAIN: 'does-not-contain',
  FILTERLINE__CRITERIA__CONTAINS_ONE_OF: 'contains-one-of',
  FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF: 'does-not-contain-one-of',
  FILTERLINE__CRITERIA__BEGINS_WITH: 'begins-with',
  FILTERLINE__CRITERIA__ENDS_WITH: 'ends-with',
  FILTERLINE__OPERATOR__AND: 'AND',
  FILTERLINE__OPERATOR__OR: 'OR',

  FILTERLINE__DATE_TYPE__CALENDAR: 'calendar',
  FILTERLINE__DATE_TYPE__RELATIVE: 'relative',
  FILTERLINE__DATE_VALUE__INTERVAL_MINUTE: 'minute',
  FILTERLINE__DATE_VALUE__INTERVAL_HOUR: 'hour',
  FILTERLINE__DATE_VALUE__INTERVAL_WEEKS: 'week',
  FILTERLINE__DATE_VALUE__INTERVAL_DAYS: 'day',
  FILTERLINE__DATE_VALUE__INTERVAL_QUARTER: 'quarter',
  FILTERLINE__DATE_VALUE__INTERVAL_MONTH: 'month',
  FILTERLINE__DATE_VALUE__INTERVAL_YEAR: 'year',
  FILTERLINE__DATE_VALUE__INTERVAL_WEEK_DAY: 'weekday',
  FILTERLINE__DATE_VALUE_START__AFTER_NOW: 'After Now',
  FILTERLINE__DATE_VALUE_START__BEFORE_NOW: 'Before Now',
  FILTERLINE__DATE_VALUE_START__BEFORE_TODAY: 'Before Today',
  FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL: 'Ago',
  FILTERLINE__DATE_VALUE_START__AFTER_NOW_DATE_LABEL: 'From Now',
  FILTERLINE__DATE_VALUE_START__AFTER_TODAY_DATE_LABEL: 'From Today',
  FILTERLINE__DATE_VALUE_START__AFTER_TODAY: 'After Today',
  FILTERLINE__DATE_VALUE_START__TODAY: 'Today',
  FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_FILTER_TYPE: 'dateFilterType',
  FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE: 'dateValue',
  FILTERLINE__DYNAMIC_DATE_PROPERTY__FILTER_INTERVAL: 'filterInterval',
  FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START: 'dateValueStart',
  FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_RANGE_START: 'startDate',
  FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_RANGE_END: 'endDate',

  // Filterline criteria value labels
  FILTERLINE__CRITERIA__EQUALS_LABEL: 'Equals',
  FILTERLINE__CRITERIA__EQUALS_DATE_LABEL: 'Is equal to',
  FILTERLINE__CRITERIA__DATE__IN_PREVIOUS_LABEL: 'Is in previous',
  FILTERLINE__CRITERIA__DATE__IN_NEXT_LABEL: 'Is in next',
  FILTERLINE__CRITERIA__NOT_EQUAL_TO_LABEL: 'Is not equal to',
  FILTERLINE__CRITERIA__NOT_EQUAL_TO_DATE_LABEL: 'Is not equal to',
  FILTERLINE__CRITERIA__SMALLER_THAN_LABEL: 'Is smaller than',
  FILTERLINE__CRITERIA__SMALLER_THAN_DATE_LABEL: 'Is before',
  FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO_LABEL: 'Is smaller than or equal to',
  FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO_DATE_LABEL: 'Is before or on',
  FILTERLINE__CRITERIA__GREATER_THAN_LABEL: 'Is greater than',
  FILTERLINE__CRITERIA__GREATER_THAN_DATE_LABEL: 'Is after',
  FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO_LABEL: 'Is greater than or equal to',
  FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO_DATE_LABEL: 'Is after or on',
  FILTERLINE__CRITERIA__IN_LABEL: 'Is in',
  FILTERLINE__CRITERIA__NOT_IN_LABEL: 'Is not in',
  FILTERLINE__CRITERIA__IN_RESULTS_LABEL: 'Is in results',
  FILTERLINE__CRITERIA__NOT_IN_RESULTS_LABEL: 'Is not in results',
  FILTERLINE__CRITERIA__IN_RESULTS_DATE_LABEL: 'Is in results',
  FILTERLINE__CRITERIA__NOT_IN_RESULTS_DATE_LABEL: 'Is not in results',
  FILTERLINE__CRITERIA__BETWEEN_LABEL: 'Is between',
  FILTERLINE__CRITERIA__NOT_BETWEEN_LABEL: 'Is not between',
  FILTERLINE__CRITERIA__DATE__TODAY_LABEL: 'Is today',
  FILTERLINE__CRITERIA__CONTAINS_LABEL: 'Contains',
  FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_LABEL: 'Does not contain',
  FILTERLINE__CRITERIA__CONTAINS_ONE_OF_LABEL: 'Contains one of',
  FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF_LABEL: 'Does not contain one of',
  FILTERLINE__CRITERIA__BEGINS_WITH_LABEL: 'Begins with',
  FILTERLINE__CRITERIA__ENDS_WITH_LABEL: 'Ends with',
  FILTERLINE__CRITERIA__IS_EMPTY_LABEL: 'Is empty',
  FILTERLINE__CRITERIA__IS_NOT_EMPTY_LABEL: 'Is not empty',
  FILTERLINE__CRITERIA__IS_EMPTY_DATE_LABEL: 'Is empty',
  FILTERLINE__CRITERIA__IS_NOT_EMPTY_DATE_LABEL: 'Is not empty',
  FILTERLINE__DATE_VALUE__INTERVAL_WEEKS_LABEL: 'Week',
  FILTERLINE__DATE_VALUE__INTERVAL_DAYS_LABEL: 'Day',
  FILTERLINE__DATE_VALUE__INTERVAL_QUARTERS_LABEL: 'Quarter',
  FILTERLINE__DATE_VALUE__INTERVAL_MONTHS_LABEL: 'Month',
  FILTERLINE__DATE_VALUE__INTERVAL_YEARS_LABEL: 'Year',
  FILTERLINE__DATE_VALUE__INTERVAL_WEEK_DAYS_LABEL: 'Weekday',
  FILTERLINE__DATE_VALUE__INTERVAL_HOURS_LABEL: 'Hour',
  FILTERLINE__DATE_VALUE__INTERVAL_MINUTES_LABEL: 'Minute',
  FILTERLINE__VALUE__TRUE: 'True',
  FILTERLINE__VALUE__FALSE: 'False',
  FILTERLINE__FILTER_TYPE__RELATION: 'relation',
  FILTERLINE__RELATION_CRITERIA__EXACTLY_LABEL: 'exactly',
  FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE: '=',
  FILTERLINE__RELATION_CRITERIA__AT_LEAST_LABEL: 'at least',
  FILTERLINE__RELATION_CRITERIA__AT_LEAST_VALUE: '>=',
  FILTERLINE__RELATION_CRITERIA__MORE_THAN_LABEL: 'more than',
  FILTERLINE__RELATION_CRITERIA__MORE_THAN_VALUE: '>',
  FILTERLINE__RELATION_CRITERIA__AT_MOST_LABEL: 'at most',
  FILTERLINE__RELATION_CRITERIA__AT_MOST_VALUE: '<=',
  FILTERLINE__RELATION_CRITERIA__LESS_THAN_LABEL: 'less than',
  FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE: '<',
  FILTERLINE__RELATION_CRITERIA__NO_LABEL: 'no',

  // Constant for IsRequired field property
  FIELD__IS_REQUIRED: true,
  FIELD__IS_NOT_REQUIRED: 'false',

  // Relationtypes
  RELATIONTYPE__LEFT: 'LEFT',
  RELATIONTYPE__RIGHT: 'RIGHT',
  RELATIONTYPE__INNER: 'INNER',
  RELATIONTYPE__CROSS: 'CROSS',
  RELATIONTYPE__FULL: 'FULL',
  RELATIONTYPE__LEFT_WITHOUT: 'LEFT WITHOUT',
  RELATIONTYPE__RIGHT_WITHOUT: 'RIGHT WITHOUT',

  // Relationtypes texts
  RELATIONTYPE__JOIN_TEXT__CROSS_JOIN: 'cross join',
  RELATIONTYPE__JOIN_TEXT__WITH_WITHOUT: 'with/without',
  RELATIONTYPE__JOIN_TEXT__WITH_ALL: 'with all',
  RELATIONTYPE__JOIN_TEXT__WITH: 'with',
  RELATIONTYPE__JOIN_TEXT__WITHOUT: 'without',

  // Relationtypes full texts in uppercase
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN: 'CROSS JOIN',
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_MATCHING: 'WITH MATCHING',
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_WITHOUT_MATCHING: 'WITH/WITHOUT MATCHING',
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_ALL: 'WITH ALL',
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITHOUT_MATCHING: 'WITHOUT MATCHING',
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_FROM: 'EACH FROM',
  RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_TO: 'WITH EACH FROM',

  PREVIEW__STATUS__CREATED: -1,
  PREVIEW__STATUS__QUEUED: 0,
  PREVIEW__STATUS__QUEUED_LABEL: 'Queued',
  PREVIEW__STATUS__PROCESSING: 1,
  PREVIEW__STATUS__PROCESSING_LABEL: 'Processing',
  PREVIEW__STATUS__COMPLETE: 2,
  PREVIEW__STATUS__COMPLETE_LABEL: 'Complete',
  PREVIEW__STATUS__ERROR: 3,
  PREVIEW__STATUS__ERROR_LABEL: 'Error',

  // NewAutoCreatedTargetDE
  NEW_AUTO_CREATED_TARGET_DE__DATA_RETENTION_POLICY__ON: 'ON',
  NEW_AUTO_CREATED_TARGET_DE__DATA_RETENTION_POLICY__OFF: 'OFF',

  // Data Retention Policy
  DATA_RETENTION_POLICY__PERIOD__DAYS: 'Days',
  DATA_RETENTION_POLICY__PERIOD__WEEKS: 'Weeks',
  DATA_RETENTION_POLICY__PERIOD__MONTHS: 'Months',
  DATA_RETENTION_POLICY__PERIOD__YEARS: 'Years',

  DATA_RETENTION_POLICY__STATUS__ON: 'retention-on',
  DATA_RETENTION_POLICY__STATUS__OFF: 'retention-off',

  DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS: 'individual-records',
  DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE: 'all-records-de',
  DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS: 'all-records',

  DATA_RETENTION_POLICY__PERIOD__AFTER: 'period-after',
  DATA_RETENTION_POLICY__PERIOD__ON: 'period-on',

  // Field default MaxLength
  FIELD_TYPE__TEXT__MAX_LENGTH__DEFAULT_VALUE: 4000,

  // Field Info Labels
  FIELD_INFO_LABEL__PRIMARY_KEY: 'Primary Key',
  FIELD_INFO_LABEL__NULLABLE: 'Nullable',
  FIELD_INFO_LABEL__LENGTH: 'Length',
  FIELD_INFO_LABEL__LENGTH_SCALE: 'Length/Scale',
  FIELD_INFO_LABEL__NAME: 'Name',
  FIELD_INFO_LABEL__TYPE: 'Type',
  FIELD_INFO_LABEL__DEFAULT_DATE_VALUE: 'Default Date Value',
  FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE: 'Default Number Value',
  FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE: 'Default Text Value',
  FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE: 'Default Boolean Value',
  FIELD_INFO_LABEL__ENCRYPTED_FIELD: 'Encrypted Field',

  // Field storage type
  FIELD_STORAGE_TYPE__ENCRYPTED: 'Encrypted',
  FIELD_STORAGE_TYPE__PLAIN: 'Plain',

  // Field Info Input Ids
  FIELD_INFO_INPUT_ID__FIELD_NAME: 'f-field-name',
  FIELD_INFO_INPUT_ID__PRIMARY_KEY: 'f-primary-key',
  FIELD_INFO_INPUT_ID__ENCRYPTED: 'f-encrypted',
  FIELD_INFO_INPUT_ID__MAX_LENGTH: 'f-max-length',
  FIELD_INFO_INPUT_ID__NULLABLE: 'f-nullable',
  FIELD_INFO_INPUT_ID__SCALE: 'f-scale',
  FIELD_INFO_INPUT_ID__DEFAULT_DATE_VALUE: 'f-default-date',
  FIELD_INFO_INPUT_ID__DEFAULT_NUMBER_VALUE: 'f-default-number',
  FIELD_INFO_INPUT_ID__DEFAULT_TEXT_VALUE: 'f-default-text',
  FIELD_INFO_INPUT_ID__DEFAULT_BOOLEAN_VALUE: 'f-default-boolean',

  // Relation
  SUBSCRIBER_FIELD__SUBSCRIBER_KEY: 'Subscriber Key',
  SUBSCRIBER_FIELD__SUBSCRIBER_ID: 'Subscriber Id',
  SUBSCRIBER_FIELD__EMAILADDRESS: 'Email Address',

  // Locales
  LOCALE__EN_US: 'en-US',

  // Formulas
  FORMULA__VALUE__NONE: 'none',
  FORMULA__VALUE__COUNT: 'count',
  FORMULA__VALUE__AVERAGE: 'avg',
  FORMULA__VALUE__SUM: 'sum',
  FORMULA__VALUE__MINIMUM: 'min',
  FORMULA__VALUE__MAXIMUM: 'max',
  FORMULA__VALUE__COUNT_DISTINCT: 'count-distinct',
  FORMULA__LABEL__NONE: 'None',
  FORMULA__LABEL__COUNT: 'Count',
  FORMULA__LABEL__AVERAGE: 'Average',
  FORMULA__LABEL__SUM: 'Sum',
  FORMULA__LABEL__MINIMUM: 'Minimum',
  FORMULA__LABEL__MAXIMUM: 'Maximum',
  FORMULA__VALUE__INPUT__ID_VALUE: 'formula-value',
  FORMULA__NUMERIC__ARRAY: ['sum', 'avg', 'min', 'max'],

  // Data Action
  DATA_ACTION__APPEND: 'append',
  DATA_ACTION__UPDATE: 'update',
  DATA_ACTION__OVERWRITE: 'overwrite',
  DATA_ACTION__INPUT__ID_VALUE: 'data-action-value',
  DATA_ACTION__LABEL__APPEND: 'Append',
  DATA_ACTION__LABEL__UPDATE: 'Update',
  DATA_ACTION__LABEL__OVERWRITE: 'Overwrite',

  // DataExtension Filter Types
  DATAEXTENSION__FILTER_MODE__AVAILABLE: '1',
  DATAEXTENSION__FILTER_MODE__TARGET: '2',

  // Error Messages
  ERROR_SFMC_PERMISSION_FAILED: 'API Permission Failed',
  ERROR_INSUFFICIENT_PERMISSIONS_DETAILS: `
  Your SFMC user does not have sufficient permissions to perform this action.
   Please make sure your SFMC user has all the required permissions listed <a href="` +
    'https://support.deselect.com/hc/en-us/articles/360003070837-Set-up-the-minimum-SFMC-User-Permission-Settings' +
    `" target="_blank">here</a>. <br/>
    If you are not an admin yourself, please ask your account admin to assign the listed permissions.
  `,
  ERROR__FIELD_LENGTH: 'Mapped Fields Have Different Length.',
  HTML_ERROR__FIELD_REQUIRED: 'This field is <b>required</b> and it is not mapped. This will cause an error.',
  ERROR__DATA_EXTENSION_NOT_FOUND: 'DataExtension cannot be found with SFMC',
  ERROR__NOT_VALID_TASK_ID: 'Unknown error from SFMC API: Make sure you provided a valid TaskID.',
  ERROR__ALIAS_NOT_UNIQUE: 'There is already another selected Data Extension with this alias. ' +
  'Please provide another alias.',

  // Warning Messages
  WARNING__FIELD_REQUIRED: 'This field is required and mapped with a nullable field.',
  WARNING__FIELD_LENGTH: 'This field is mapped with a field of a different size.',
  WARNING__FIELD_INVALID__STARTS_WITH_UNDERSCORE: 'Field name cannot start with an underscore.',
  WARNING__USE_OF_REQUIRED_FIELD_WITH_CROSS_JOIN: 'Only one primary key mapped for Cross Join.',

  // Swal Titles
  SWAL_TITLE__FIELD_REQUIRED: 'This field is required.',
  SWAL_TITLE__FIELD_NOT_MAPPED: '<div class="error-title">This field is not mapped.</div>',
  SWAL_TITLE__SELECTION_DOES_NOT_USE_PREVIOUS_TARGET_DE: 'Selection does not use previous Target Data Extension',
  SWAL_TITLE__TARGET_DATA_EXTENSION_NO_LONGER_EXISTS: 'Target Data Extension for this selection no longer exists',
  SWAL_TITLE__NO_MATCHING_FIELD_FOUND: 'No matching field found',
  SWAL_TITLE__REQUIRED_FIELDS_HAVE_NOT_BEEN_MAPPED: 'All required fields have not been mapped',
  SWAL_TITLE__NO_SELECTION_FOUND: 'No selection found',

  SWAL__TYPE__ERROR: 'error',
  SWAL__TYPE__WARNING: 'warning',

  // Title for disabled schedule button
  SCHEDULE_BUTTON_DISABLED__TITLE__ON_HOVER: 'Scheduling will be enabled after completing the necessary steps',

  // Repeat modes
  REPEAT_MODE__HOURLY: 'hourly',
  REPEAT_MODE__DAILY: 'daily',
  REPEAT_MODE__WEEKLY: 'weekly',
  REPEAT_MODE__MONTHLY: 'monthly',

  // Warning messages

  WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY:
    'This Selection is archived and therefore read-only. You cannot run/preview it or save changes.',
  WARNING_TEXT__USER_ONLY_ACCESS: (
    <span>
      The maximum number of allowed users has been reached, you will only be able to use the app in read-only mode.
      Please contact
      {' '}
      <a href="https://deselect.com/contact" target="_blank" rel="noreferrer">DESelect</a>
      {' '}
      to purchase additional licenses.
    </span>),
  // Prio Deduplication
  PRIO_DEDUP__MODE__BASIC__LABEL: 'Basic',
  PRIO_DEDUP__MODE__BASIC__VALUE: 'basic',
  PRIO_DEDUP__MODE__ADVANCED__LABEL: 'Advanced',
  PRIO_DEDUP__MODE__ADVANCED__VALUE: 'advanced',
  PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__LABEL: 'Define Sort Values',
  PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE: 'defineSortValues',
  PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__LABEL: 'Sort All Values',
  PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE: 'sortAllValues',
  PRIO_DEDUP__SORT_ORDER_LOWEST__LABEL: 'Lowest',
  PRIO_DEDUP__SORT_ORDER__ASC: 'asc',
  PRIO_DEDUP__SORT_ORDER_HIGHEST__LABEL: 'Highest',
  PRIO_DEDUP__SORT_ORDER__DESC: 'desc',
  PRIO_DEDUP__TEXT_INPUT__ID: 'prio-text',
  PRIO_DEDUP__NUMBER_INPUT__ID: 'prio-number',
  PRIO_DEDUP__DECIMAL_INPUT__ID: 'prio-decimal',
  PRIO_DEDUP__BOOLEAN_INPUT__ID: 'prio-boolean',
  PRIO_DEDUP__DATE_INPUT__ID: 'prio-date',
  PRIO_DEDUP__USE_PRIO_DEDUPLICATION__FALSE: 'false',
  PRIO_DEDUP__OPTION_TYPE__CRITERIA_FIELD_OBJECT_ID: 'criteriaFieldObjectID',
  PRIO_DEDUP__OPTION_TYPE__SORT_ORDER: 'sortOrder',
  PRIO_DEDUP__OPTION__PLEASE_SELECT: 'Please select',

  // Custom Values
  CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE: 'fixedValue',
  CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE: 'dynamicValue',
  CUSTOM_VALUES__FIELD_TYPE__FORMULA: 'formula',
  CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE: 'aggregationValue',
  CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM: 'transform',
  CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP: 'timestamp',
  CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE: 'dateDiff',
  CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER: 'rowNumber',
  CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA: 'freeFormula',
  CUSTOM_VALUES__FORMULA_FORMAT__DATETIME: 'datetime',
  CUSTOM_VALUES__FORMULA_FORMAT__DATE: 'date',
  CUSTOM_VALUES__FORMULA_FORMAT__NOW: 'now',
  CUSTOM_VALUES__FORMULA_FORMAT__TODAY: 'today',
  CUSTOM_VALUES__FORMULA_FORMAT__FIELD: 'field',
  CUSTOM_VALUES__FIELD_ID__FIELD1: 'field1',
  CUSTOM_VALUES__FIELD_ID__FIELD2: 'field2',
  CUSTOM_VALUES__FORMULA_FORMAT__GROUP_FORMAT: 'groupFormat',
  CUSTOM_VALUES__FIELD_TYPE_TITLE__DYNAMIC_VALUE: 'dynamic value',
  CUSTOM_VALUES__FORMULA_TYPE_TITLE__ROW_NUMBER: 'row number',
  CUSTOM_VALUES__SORT_ORDER_LABEL__HIGHEST: 'highest',
  CUSTOM_VALUES__SORT_ORDER_LABEL__LOWEST: 'lowest',

  CUSTOM_VALUES__FIELD_TYPE_WARNING__DYNAMIC_VALUE: 'Dynamic Custom Value',

  CUSTOM_VALUES__TEXT_FIXED_VALUE: `Enter a fixed value, like a fixed text, number, date, boolean.
    All records will have the same fixed value.`,
  CUSTOM_VALUES__TEXT_DYNAMIC_VALUE: `Select a value based on logic.
    Different records can have different values based on the logic you define.`,
  CUSTOM_VALUES__TEXT_TRANSFORM_DATE: 'Transform a date field in a data extension / data view.',
  CUSTOM_VALUES__TEXT_DATE_DIFFERENCE: 'Calculate the amount of time between 2 dates.',
  CUSTOM_VALUES__TEXT_TIMESTAMP: 'Add a timestamp of the date / date time the selection runs.',
  CUSTOM_VALUES__TEXT_ROW_NUMBER: `Create a new row number by selecting the field that should
    determine the order of the row number.`,
  CUSTOM_VALUES__TEXT_AGGREGATION: `Apply aggregate functions like Count, Sum, and more on values of a
  data extension/data view.`,
  CUSTOM_VALUES__TEXT_FREE_FORMULA: 'Freely combine as many SQL functions as you need.',

  CUSTOM_VALUES__HEADER__FIXED_VALUE: 'Fixed Value',
  CUSTOM_VALUES__HEADER__DYNAMIC_VALUE: 'Dynamic Value',
  CUSTOM_VALUES__HEADER__TRANSFORM_DATE: 'Transform Date',
  CUSTOM_VALUES__HEADER__DATE_DIFFERENCE: 'Date Difference',
  CUSTOM_VALUES__HEADER__TIMESTAMP: 'Timestamp',
  CUSTOM_VALUES__HEADER__ROW_NUMBER: 'Row Number',
  CUSTOM_VALUES__HEADER__AGGREGATION: 'Aggregation',
  CUSTOM_VALUES__HEADER__APPLY_FORMULA: 'Apply formula to a field',
  CUSTOM_VALUES__HEADER__FREE_FORMULA: 'Apply any function',
  CUSTOM_VALUES__HEADER__FORMULA_TYPE: 'Type of Formula',

  // Folder Content Types
  FOLDER__CONTENT_TYPE__DATAEXTENSION: 'dataextension',
  FOLDER__CONTENT_TYPE__SHARED_DATA: 'shared_data',
  FOLDER__CONTENT_TYPE__SHARED_DATAEXTENSION: 'shared_dataextension',

  // Default SFMC folders Customer Keys
  FOLDER__CUSTOMER_KEY__DATA_EXTENSION: 'dataextension_default',
  FOLDER__CUSTOMER_KEY__SALESFORCE_DATA_EXTENSION: 'salesforcedataextension_default',
  FOLDER__CUSTOMER_KEY__SHARED_SALESFORCE_DATA_EXTENSION: 'shared_salesforcedataextension_defau',
  FOLDER__CUSTOMER_KEY__SHARED_DATA_EXTENSION: 'shared_dataextension_default',

  // Input Types
  INPUT_TYPE__ENTER: 'Enter',

  // Relation Modal
  RELATION_MODAL__FROM: 'from',
  RELATION_MODAL__TO: 'to',
  // eslint-disable-next-line max-len
  RELATION_MODAL__JOIN_WARNING__TEXT: 'Please beware you\'re joining a text field with a number/decimal field, which could in some cases cause conversion errors while running the selection.',

  // Test data
  TEST_DATA_PICKLIST: '5eea00807fb6742640c5e29',

  // Separators
  SEPARATOR_DEFAULT: 'm3%WTM="·=)$V?"·=("ñññ',

  // Nubbin positions for tooltips
  NUBBIN_POSITION__TOP_LEFT: 'topLeft',
  NUBBIN_POSITION__TOP_RIGHT: 'topRight',
  NUBBIN_POSITION__BOTTOM_RIGHT: 'bottomRight',
  NUBBIN_POSITION__BOTTOM_LEFT: 'bottomLeft',

  // Tooltip positions - New Design
  SLDS_TOOLTIP_POSITION__TOP: 'top',
  SLDS_TOOLTIP_POSITION__LEFT: 'left',
  SLDS_TOOLTIP_POSITION__RIGHT: 'right',
  SLDS_TOOLTIP_POSITION__BOTTOM: 'bottom',
  SLDS_TOOLTIP_POSITION__TOP_LEFT: 'top left',
  SLDS_TOOLTIP_POSITION__TOP_RIGHT: 'top right',
  SLDS_TOOLTIP_POSITION__RIGHT_TOP: 'right top',
  SLDS_TOOLTIP_POSITION__RIGHT_BOTTOM: 'right bottom',
  SLDS_TOOLTIP_POSITION__BOTTOM_LEFT: 'bottom left',
  SLDS_TOOLTIP_POSITION__BOTTOM_RIGHT: 'bottom right',
  SLDS_TOOLTIP_POSITION__LEFT_TOP: 'left top',
  SLDS_TOOLTIP_POSITION__LEFT_BOTTOM: 'left bottom',

  // Tooltip types
  TOOLTIP_TYPE__RELATION_MODAL: 'relationModal',
  TOOLTIP_TYPE__UNAVAILABLE_FEATURE: 'unavailableFeature',
  TOOLTIP_TYPE__ASSIGN_USER: 'assignUser',
  TOOLTIP_TYPE__UTC: 'utc_timezones',
  TOOLTIP_TYPE__DELETE_SELECTION: 'deleteSelection',
  TOOLTIP_TYPE__RECOMMENDED_FILTER_MODE: 'recommendedFilterMode',
  TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_NOW: 'relativeDateFilterStartNow',
  TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_TODAY: 'relativeDateFilterStartToday',
  TOOLTIP_TYPE__TEMPLATE_SELECTION: 'templateSelection',
  TOOLTIP_TYPE__DISABLED_AUTO_REFRESH: 'disabledAutoRefresh',
  TOOLTIP_TYPE__CONVERT_FROM_TIMEZONE: 'convertFromTimezone',
  TOOLTIP_TYPE__PREDEFINED_DATA_EXTENSIONS: 'predefinedDataExtension',
  TOOLTIP_TYPE__PREDEFINED_FILTER_SET_SOURCE: 'predefinedFilterSetSource',
  TOOLTIP_TYPE__S2S_PACKAGE_NOT_INSTALLED: 's2sPackageNotInstalled',
  TOOLTIP_TYPE__UPDATE_S2S_PACKAGE: 'updateS2SPackage',
  TOOLTIP_TYPE__TEMPORARY_DATA_EXTENSION_RETENTION_POLICY: 'temporaryDataExtensionRetentionPolicy',
  TOOLTIP_TYPE__NEW_DATA_EXTENSION_RETENTION_POLICY: 'newDataExtensionRetentionPolicy',
  TOOLTIP_TYPE__GLOBAL_CUSTOM_VALUE__INFO: 'globalCustomValueInfo',
  TOOLTIP_TYPE__CHANGE_LOCATION_TO_SHARED_FOLDER: 'changeLocationToSharedFolder',

  // Filter types (determined by the location of the filter)
  FILTER_TYPE__PRIO_DEDUP: 'filterTypePrioDedup',
  FILTER_TYPE__CUSTOM_VALUES: 'filterTypeCustomValues',

  // Sort & Limit
  SORT_LIMIT__SELECT_TYPE__NUMBER: 'number',
  SORT_LIMIT__SELECT_TYPE__PERCENTAGE: 'percentage',
  SORT_LIMIT__SELECT_TYPE__ALL_RECORDS: 'allRecords',
  SORT_LIMIT__ORDER_BY_TYPE__FIELD: 'field',
  SORT_LIMIT__ORDER_BY_TYPE__RANDOM: 'random',
  SORT_LIMIT__ORDER_BY_SORT__ASCENDING: 'asc',
  SORT_LIMIT__ORDER_BY_SORT__DESCENDING: 'desc',

  // ScheduleSelectionModal
  SCHEDULE_SELECTION__REPEAT_MODE__HOURLY: 'hourly',
  SCHEDULE_SELECTION__REPEAT_MODE__DAILY: 'daily',
  SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY: 'weekly',
  SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY: 'monthly',
  SCHEDULE_SELECTION__MODE__ONCE: 'once',
  SCHEDULE_SELECTION__MODE__REPEAT: 'repeat',
  SCHEDULE_SELECTION__DATE_VALUE__WEEKS: 'weeks',
  SCHEDULE_SELECTION__DATE_VALUE__MONTHS: 'months',
  SCHEDULE_SELECTION__DATE_VALUE__DATE: 'date',
  SCHEDULE_SELECTION__DATE_VALUE__YEAR: 'year',
  SCHEDULE_SELECTION__DATE_VALUE__MINUTE: 'minute',

  // Automap Target Definition
  AUTOMAP__OVERWRITE: 'overwrite',
  AUTOMAP__DO_NOT_OVERWRITE: 'doNotOverwrite',

  FREE_FORMULA__TEXTAREA_INPUT__ID: 'textarea',

  // DESelect Data Extension Type
  DESELECT__DATA_EXTENSION__TYPE__TARGET: 'target',
  // Aggregation modes
  AGGREGATION__MODE__BASIC: 'basic',
  AGGREGATION__MODE__ADVANCED: 'advanced',

  // Data Extensions Relation Types
  RELATION__TYPE__ONE_TO_MANY: '1-n',
  RELATION__TYPE__MANY_TO_MANY: 'n-n',
  RELATION__TYPE__MANY_TO_ONE: 'n-1',
  RELATION__TYPE__ONE_TO_ONE: '1-1',

  // DE copy modes
  COPY_TO__CURRENT_BU: 'current-BU',
  COPY_TO__OTHER_BU: 'other-BU',

  // DE copy modes
  COPY_MATCHING_ON__CUSTOMER_KEY: 'copy-matching-on-customer-key',
  COPY_MATCHING_ON__NAME: 'copy-matching-on-name',

  // Named Constants
  FIELD_OR_DATA_EXTENSION__FIELD: 'field',
  FIELD_OR_DATA_EXTENSION__DATA_EXTENSION: 'dataextension',

  // Trigger selection mode
  TRIGGER_SELECTION_MODE__LABEL__SCHEDULED: 'Scheduled',
  TRIGGER_SELECTION_MODE__VALUE__SCHEDULED: 'scheduled',
  TRIGGER_SELECTION_MODE__VALUE__AUTO: 'auto',
  TRIGGER_SELECTION_MODE__LABEL__MANUAL: 'Manual Run',
  TRIGGER_SELECTION_MODE__VALUE__MANUAL: 'manual',

  // Source of selection
  SOURCE_SELECTION__LABEL__SELECTION: 'Selection',
  SOURCE_SELECTION__VALUE__SELECTION: 'Selection',
  SOURCE_SELECTION__LABEL__WATERFALL_SELECTION: 'Waterfall',
  SOURCE_SELECTION__VALUE__WATERFALL_SELECTION: 'Waterfall Selection',

  // Update Selection modal
  UPDATE_SELECTION__ACTION_TYPE__REMOVE: 'Removed',
  UPDATE_SELECTION__ACTION_TYPE__UPDATE: 'Updated',
  UPDATE_SELECTION__OBJECT_TYPE__FILTER: 'Filter',
  UPDATE_SELECTION__OBJECT_TYPE__RELATION_FILTER: 'Related Records Filter',
  UPDATE_SELECTION__OBJECT_TYPE__FILTER_IN_SUBQUERY: 'Filter in (Not) In Results filters',
  UPDATE_SELECTION__OBJECT_TYPE__FILTER_IN_RELATION_FILTER: 'Filter in Related Records filters',
  UPDATE_SELECTION__OBJECT_TYPE__CUSTOM_VALUE: 'Custom Value',
  UPDATE_SELECTION__OBJECT_TYPE__FILTER_IN_CUSTOM_VALUE: 'Filter in Custom Value',

  // error message
  // eslint-disable-next-line max-len
  ERROR__DATA_EXTENSION_ALREADY_EXISTS: 'There already exists a Data Extension with this name. Please choose another name for Target Data Extension.',
  ERROR__DOES_NOT_HAVE_PERMISSION_TO_COPY: ' does not have access to ClientID: ',
  ERROR__VALIDATION_QUERY__BUILDING: 'Validation was not successful.',

  // button look
  BUTTON__TYPE__NEUTRAL: 'neutral',
  BUTTON__TYPE__BRAND: 'brand',
  BUTTON__TYPE__BRAND_DARK: 'brand set-dark-color',

  // droppable container id
  DROPPABLE__CONTAINER_ID__1: 'droppable-id-1',
  DROPPABLE__CONTAINER_ID__2: 'droppable-id-2',

  // selection folder settings - open or closed
  SELECTION_FOLDER_SETTINGS__OPEN__VALUE: 'open',
  SELECTION_FOLDER_SETTINGS__CLOSED__VALUE: 'closed',

  SELECTION_FOLDER_SETTINGS__OPEN__LABEL: 'Open all',
  SELECTION_FOLDER_SETTINGS__CLOSED__LABEL: 'Close all',

  // SFMC servers use the Central Standard Time (CST) Timezone
  TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE: 'Central Standard Time',
  TIME_ZONE__CENTRAL_STANDARD_TIME__OBJECT: TimezoneList.find(timezone => timezone.value ===
    'Central Standard Time'),

  // size of the loading spinner
  SPINNER__SIZE__XX_SMALL: 'xx-small',
  SPINNER__SIZE__X_SMALL: 'x-small',
  SPINNER__SIZE__SMALL: 'small',
  SPINNER__SIZE__MEDIUM: 'medium',
  SPINNER__SIZE__LARGE: 'large',

  TABLE__CELL_LEFT_SIZE__X_SMALL: 'slds-m-left_x-small',
  TABLE__CELL_LEFT_SIZE__SMALL: 'slds-m-left_small',

  ALERT__TYPE__WARNING: 'warning',
  ALERT__TYPE__SUCCESS: 'success',
  ALERT__TYPE__ERROR: 'error',

  // Text component type
  TEXT__TYPE__LOADING_SELECTION: 'loadingSelection',
  TEXT__TYPE__LOADING__WATERFAL_SELECTION: 'loadingWaterfallSelection',

  // backToWaterFall types
  BACKTOWATERFALL__TYPE_CREATE: 'create',
  BACKTOWATERFALL__TYPE_OPEN: 'open',

  QUERYACTIVITY__TASKTYPE__RUN_WATERFALL_SELECTION: 'run-waterfall-selection',

  // Selection filter properties to monitor if changed
  STATE__NON_SELECTION_PROPERTIES: ['dataExtensions', 'targetDataExtensions', 'loadingForDataExtensions',
    'loadingForTargetDataExtensions', 'selectionNavigator', 'showSaveToast', 'copySuccess',
    'isDataExtensionRequestDone', 'predefinedRelations', 'predefinedRelationsMap', 'runStatus',
    'currentSelectionId', 'pickListFieldObjectIDs', 'pickLists', 'dataSets', 'filterSets', 'previewStatus',
    'previewError', 'disablePreviewBTN', 'runPreviewQueryActivityStarted', 'switchedToOtherTab'],

  ACTIVE_USERS_LIST_MODE__MINIMAL: 'minimalMode',
  ACTIVE_USERS_LIST_MODE__NORMAL: 'normalMode',
  ACTIVE_USERS_WEBSOCKET_ACTIONS___JOIN_ROOM: 'ACTIVE USERS WEBSOCKET ACTIONS Join-Room',
  ACTIVE_USERS_WEBSOCKET_ACTIONS___LEAVE_ROOM: 'ACTIVE USERS WEBSOCKET ACTIONS Leave-Room',
  ACTIVE_USERS_WEBSOCKET_ACTIONS___GET_ALL_USERS: 'ACTIVE USERS WEBSOCKET ACTIONS GET-ALL-USERS',
  ACTIVE_USERS_WEBSOCKET_ACTIONS___NEW_USER_JOINED: 'ACTIVE USERS WEBSOCKET ACTIONS NEW-USER-JOINED',
  ACTIVE_USERS_WEBSOCKET_ACTIONS___USER_HAS_LEFT: 'ACTIVE USERS WEBSOCKET ACTIONS USER-HAS-LEFT',
  ACTIVE_USERS_COLORS: ['#FF9900', '#04844b', '#0070d2', '#FFCC00', '#F1C5AE', '#ECDDD0', '#CED2C2',
    '#BFD1DF', '#A3D6D4', '#F1E9CB', '#C2D5A7', '#B0ABCA', '#E2A9BE', '#E1C6AC', '#E6A99F',
    '#E5CAAF', '#76ADA8', '#92B1B6', '#35455D', '#CFD7C0', '#A1B984', '#789561'],

  // Selection modes
  SELECTION_MODE__BASIC: 'basicMode',
  SELECTION_MODE__ADVANCED: 'advancedMode',

  TOOLBAR__EDIT_DATA_EXTENSION: 'Edit Data Extension',
  TOOLBAR__DATA_ACTION: 'Data Action',
  TOOLBAR__DEDUPLICATION: 'Deduplication',
  TOOLBAR__SORT_AND_LIMIT: 'Sort & Limit',
  TOOLBAR__SOURCE_LIMITING: 'Source Limiting',
  TOOLBAR__DELETE_MAPPINGS: 'Delete Mappings',
  TOOLBAR__AUTOMAP: 'Automap',

  CUSTOM_DATA_SET_CHANGE__WARNING_TEXT: 'Please note that the ‘Custom Data Set’ was edited in Advanced Mode. ' +
    'If you select another Data Set, then this will also update the Data Set in Advanced Mode. ' +
    'Are you sure you want to continue?',

  S2S_NOT_INSTALLED: 'Please install DESelect Automations package to use this feature.',
  UPDATE_S2S_PACKAGE: 'Please update the S2S installed package to use this feature. Learn more' +
    '<a href="https://support.deselect.com/hc/en-us/articles/4405815497489-Step-2-Setup-DESelect-Automations"' +
    'target="_blank"> here </a> or contact' +
    '<a href="https://support.deselect.com/hc/en-us/requests/new" target="_blank"> support </a> for assistance.',

  // Basic mode selection source types
  BASIC_MODE_SOURCE_TYPE__DATA_SETS: 'Data Set',
  BASIC_MODE_SOURCE_TYPE__DATA_EXTENSIONS: 'Data Extension',

  // Essentials limits
  ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT: 10,
  ESSENTIALS_EDITION_MAX_BUS_COUNT: 2,
  ESSENTIALS_EDITION_MAX_USERS_COUNT: 3,
  ESSENTIALS_EDITION_MAX_SELECTIONS_LIMIT_LABEL: 'maxActiveSelections',
  ESSENTIALS_EDITION_SHOW_LIMITS_BOUNDARY: 0.7,
  ESSENTIALS_EDITION__UPDATE_YOUR_PLAN_ID: 'tDWRzcMEIx1vipk8PqWy_a3bqRM',

  // Pendo
  PENDO_RESOURCE_CENTER_ID: 'Yg1t8dAVBG42IUyvIhCUE4Bqtk8',

  // Editions
  ORGANISATION__EDITION__ESSENTIALS: 'essentials',
  ORGANISATION__EDITION__ENABLE: 'enable',
  ORGANISATION__EDITION__PLUS: 'plus',
  ORGANISATION__EDITION__ADVANCED: 'advanced',

  ORGANISATION__EDITION__ESSENTIALS_BU_LIMIT: 2,

  // Demo data
  DEMO_DATA__DES: ['DESelect_DEMO_Accounts', 'DESelect_DEMO_Contacts',
    'DESelect_DEMO_Contracts', 'DESelect_DEMO_Customers',
    'DESelect_DEMO_Emails_To_Exclude', 'DESelect_DEMO_Opportunities',
    'DESelect_DEMO_OrderLine_Items', 'DESelect_DEMO_Orders', 'DESelect_DEMO_Products'],

  // DEEDEE url
  PREVIEW_DEEDEE_URL: 'https://segment-static.deselect.io/deedee.gif',

  // Source limiting
  SELECTION_SOURCE_LIMIT__TYPE__NUMBER: '#',
  SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE: '%',
  DEFAULT_SOURCE_LIMIT: {
    active: false,
    value: 100,
    type: '%',
  },

  // DESelect Products
  DESELECT__SEGMENTATION: 'segmentation',
  DESELECT__SEARCH_STUDIO: 'searchStudio',

  // DEEDEE AI
  SCOPE_DES_LIMIT: 20,

  DESELECT_SEARCH_STAGING_APP_NAME: 'DESelect%20Search%20Staging%20%26%20Automated%20Tests',
  /**
   * We don't have release env for serach, so we use the same link as staging
   */
  DESELECT_SEARCH_RELEASE_APP_NAME: 'DESelect%20Search%20Staging%20%26%20Automated%20Tests',
  DESELECT_SEARCH_PRODUCTION_APP_NAME: 'DESelect%20Search',

  DESELECT_ENGAGE_STAGING_APP_NAME: 'DESelect%20Engage%20Staging',
  /**
   * We don't have release env for engage, so we use the same link as staging
   */
  DESELECT_ENGAGE_RELEASE_APP_NAME: 'DESelect%20Engage%20Staging',
  DESELECT_ENGAGE_PRODUCTION_APP_NAME: 'DESelect%20Engage',

  DESELECT_SEARCH_APP_EXCHANGE_URL: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000Fz2a4UAB',
  DESELECT_ENGAGE_APP_EXCHANGE_URL: 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000JeGMiUAN',
};

export default Constants;
