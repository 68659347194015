// pendoUtils.js
import Constants from '../../constants/constants';

/**
 * Checks if Pendo is initialized and ready to use.
 * @returns {boolean} True if Pendo is initialized, false otherwise.
 */
function isPendoInitialized() {
  return window.pendo && typeof window.pendo.initialize === 'function';
}

/**
 * Waits for Pendo to be initialized before executing a callback.
 * @param {Function} callback - The function to execute once Pendo is initialized.
 * @param {number} [maxAttempts=50] - The maximum number of attempts to check for Pendo initialization.
 * @returns {void} - Calls the callback function once Pendo is initialized.
 */
function waitForPendo(callback, maxAttempts = 50) {
  let attempts = 0;
  const checkPendo = () => {
    if (isPendoInitialized()) {
      callback();
    } else if (attempts < maxAttempts) {
      attempts += 1;
      setTimeout(checkPendo, 100);
    } else {
      console.warn('Pendo initialization timed out');
    }
  };

  checkPendo();
}

/**
 * Initializes Pendo notifications and sets up listeners for guide updates.
 * This function filters active guides, updates the notification count,
 * and displays or hides the notification indicator as needed.
 * @returns {void} - Initializes Pendo notifications.
 */
export function initializePendoNotifications() {
  function updateNotificationCount() {
    if (isPendoInitialized()) {
      const activeGuides = window.pendo.getActiveGuides();

      const relevantNotifications = activeGuides.filter(
        guide => guide.isAnnouncement && guide.resourceCenterId === Constants.PENDO_RESOURCE_CENTER_ID,
      );
      const notificationCount = relevantNotifications.length;

      if (notificationCount > 0) {
        window.pendo.dom('.des-pendo-notification-count').text(notificationCount - 1);
        window.pendo.dom('.des-pendo-notification-count').css('display', 'inline');
      } else {
        window.pendo.dom('.des-pendo-notification-count').css('display', 'none');
      }
    }
  }

  /**
   * Sets up Pendo event listeners and performs initial notification count update.
   * This function waits for Pendo to be initialized before setting up listeners.
   * @returns {void} - Sets up Pendo event listeners.
   */
  function setupPendoListeners() {
    updateNotificationCount();
    window.pendo.events.on('guidesLoaded', updateNotificationCount);
  }

  waitForPendo(setupPendoListeners);
}

/**
 * Shows a specific Pendo guide by its ID.
 * @param {string} guideId - The ID of the Pendo guide to show.
 * @returns {void} - Shows the specified Pendo guide.
 */
export function showPendoGuide(guideId) {
  if (window.pendo) {
    window.pendo.showGuideById(guideId);
  }
}

/**
 * Hides all currently visible Pendo guides.
 * @returns {void} - Hides all currently visible Pendo guides.
 */
export function hidePendoGuides() {
  if (window.pendo) {
    window.pendo.hideGuides();
  }
}
