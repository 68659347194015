import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Button from '../../shared/Button/Button';
import Constants from '../../../constants/constants';

const FreeLimitsBanner = ({
  activeSelections,
  activeBUs,
  activeUsers,
  showLimits,
}) => {
  const showLimitsBanner = () => {
    // We show the banner if the user or Selection number is close to hitting the limit
    const distanceFromLimitActiveSelections = activeSelections / Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT;

    const distanceFromLimitActiveUsers = activeUsers / Constants.ESSENTIALS_EDITION_MAX_USERS_COUNT;

    return (
      showLimits &&
      (
        distanceFromLimitActiveSelections > Constants.ESSENTIALS_EDITION_SHOW_LIMITS_BOUNDARY ||
        distanceFromLimitActiveUsers > Constants.ESSENTIALS_EDITION_SHOW_LIMITS_BOUNDARY
      )
    );
  };

  const handleUpgrade = () => {
    // eslint-disable-next-line no-undef
    pendo.showGuideById(Constants.ESSENTIALS_EDITION__UPDATE_YOUR_PLAN_ID);
  };

  const renderLimitChipColor = (value, max) => {
    const redLimit = Math.round(max * 0.8); // Over 80% of the limit, we show it red

    let classValue = 'banner-limits-chip slds-badge';

    if (value >= redLimit) {
      classValue += ' slds-theme_error';
    } else {
      classValue += ' slds-theme_inverse';
    }

    return (
      <span
        className={classValue}
      >
        {value.toString() + '/' + max}
      </span>
    );
  };

  return showLimitsBanner() && (
    <div className="banner_header_limits" id="banner-limits">
        <div className="banner_limits_wrapper">
            <div className="banner-limits-title">
              You are reaching your limit
            </div>
            <div className="banner-limits-values">
              <div className="banner-limits-limit">
                <div>
                  {renderLimitChipColor(activeSelections, Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT)}
                </div>
                <div>
                  Active Selections
                </div>
              </div>
              <div className="banner-limits-limit">
                <div>
                  {renderLimitChipColor(activeBUs, Constants.ESSENTIALS_EDITION_MAX_BUS_COUNT)}
                </div>
                <div>
                  Active BUs
                </div>
              </div>
              <div className="banner-limits-limit">
                <div>
                  {renderLimitChipColor(activeUsers, Constants.ESSENTIALS_EDITION_MAX_USERS_COUNT)}
                </div>
                <div>
                  Active Users
                </div>
              </div>
            </div>
            <div className="banner-limits-values">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.deselect.com/hc/en-us/articles/15440190700561-DESelect-Segment-Free-Limitations"
              >
                Learn More
              </a>
            </div>
            <div className="banner-limits-buttons">
              <Button
                id="banner"
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                onClick={() => handleUpgrade()}
              >
                <span>Upgrade today</span>
              </Button>
            </div>
        </div>
    </div>
  );
};

FreeLimitsBanner.propTypes = {
  // Number of active Selections created
  activeSelections: PropTypes.number.isRequired,
  // Flag to show Segment Free limits or not
  showLimits: PropTypes.bool.isRequired,
};

export default FreeLimitsBanner;
